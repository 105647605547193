/**
 * Icon Component
 * Simple component to have consistent Icon Styles and functionality
 */

import * as React from "react";

import "./Icon.scss";

interface IconProps {
  src: string;
  width: number;
  height: number;
  className?: string;
}

export default class Icon extends React.Component<IconProps> {
  style = {};

  constructor(props :IconProps) {
    super(props);
    const width = this.props.width ? (this.props.width + "px") : "100%";
    const height = this.props.height ? (this.props.height + "px") : "100%";
    this.style = {
      width: width,
      height: height
    };

  }

  render() {
    return (
      <div className={`icon ${ this.props.className || "" }`} style={ this.style }>
        <img src={ this.props.src } />
      </div>
    );
  }
}
