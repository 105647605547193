/**
 * SideBar Content
 * Component displaying the sample content for the fourth POI.
 */

import * as React from "react";
import { BreakpointContext } from "~src/App";
import { BREAKPOINTS } from "~src/logic/enums/enums";

// Components
import SideBarHeadline from "~Components/SideBarHeadline/SideBarHeadline";
import SideBarTopLine from "~Components/SideBarTopLine/SideBarTopLine";
import SideBarText from "~Components/SideBarText/SideBarText";
import SideBarImage from "~Components/SideBarImage/SideBarImage";
import SideBarPoi, { SideBarPoiProperties } from "~src/contentComponents/common/SideBarPoi";

// Stylesheets
import "./SideBarPoiSix.scss";

// Images
import HeaderBackground from "~Assets/images/NIHL/POI_6/NIHL_Heroshot_06.png";
import POIIcon from "~Assets/images/icons/POI_small06.svg";
import ImageOne from "~Assets/images/NIHL/POI_6/POI_6_01.png";
import ImageTwo from "~Assets/images/NIHL/POI_6/POI_6_02.png";

export default class SideBarPoiSix extends SideBarPoi {
  constructor(props: SideBarPoiProperties) {
    super(props);
  }

  render() {
    return (
      <div className={`sideBarPoiSix ${ this.props.className || "" }`} style={{ backgroundImage: `url(${HeaderBackground})` }}>
        <div className="poiContentWrap">
          <div className="poiContent">
            <div className="poiContent__inner">
              <SideBarTopLine>
                Das NETZlabor Intelligentes Heimladen
              </SideBarTopLine>
              <SideBarHeadline
                icon={POIIcon}
                className='smallMargin'
              >
                Fazit: Uneingeschränkte Mobilität trotz netzdienlicher Steuerung von Ladevorgängen
              </SideBarHeadline>
              <BreakpointContext.Consumer>
                {value =>
                  <>
                    {value !== BREAKPOINTS.LG && (
                      <SideBarImage
                        src={HeaderBackground}
                      />
                    )}
                  </>
                }
              </BreakpointContext.Consumer>
              <SideBarText
                color="#000"
              >
                Lademanagement als Lösungsansatz zur Netzoptimierung ist in seiner technischen Wirksamkeit unumstritten. Es ermöglicht eine deutliche Steigerung der Aufnahmekapazität des Niederspannungsnetzes für Elektrofahrzeuge durch eine gezielte Reduktion von Lastspitzen während der Hauptladezeiten. Im NETZlabor konnten die E-Mobilitätslastspitzen um ca. 30 - 50 % verringert werden. Die Kund*innen werden dabei in Ihrer Mobilität nicht eingeschränkt und die E-Fahrzeuge sind am nächsten Morgen immer voll geladen. Dass die Umsetzung einer netzdienlichen Steuerung über den Smart Meter funktioniert und für eine standardisierte Anwendung weiterentwickelt werden konnte, zeigt die Praxis im NETZlabor Intelligentes Heimladen. Für die Zukunft ist es wichtig, diese Lösung nun umzusetzen, sodass Lademanagement flächendeckend dort eingesetzt werden kann, wo es netztechnisch benötigt wird.
              </SideBarText>
              <SideBarImage
                src={ImageOne}
                setPopoutImage={this.props.setPopoutImage}
              />
              <SideBarImage
                src={ImageTwo}
                setPopoutImage={this.props.setPopoutImage}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}
