/**
 * Sidebar Headline Component
 * Simple component that adds a styled headline to the sidebar.
 */

import * as React from "react";

// Components
import Icon from "../../components/Icon/Icon";

// Stylesheets
import "./SideBarHeadline.scss";


interface SideBarHeadlineProps {
  className?: string;
  color?: string;
  icon?: string;
  children: React.ReactNode;
}

export default class SideBarHeadline extends React.Component<SideBarHeadlineProps> {
  style = {
    color: this.props.color ? this.props.color : undefined
  };

  render() {
    return (
      <div
        className={`sidebarHeadline ${ this.props.className || "" }`}
      >
        <h3 style={this.style}>
          { this.props.children }
        </h3>
        {this.props.icon && (
          <Icon
            src={this.props.icon}
            width={84}
            height={72}
          />
        )}
      </div>
    );
  }
}
