/**
 * Sidebar Accordion Component
 * Accordion which can be opened and closed by clicking the header.
 */

import * as React from "react";
import { CSSTransition } from "react-transition-group";

// Components
import Icon from "../../components/Icon/Icon";

// Stylesheets
import "./SideBarAccordion.scss";

// Images
import IconDown from "../../assets/images/icons/back_inactive.svg";
import IconUp from "../../assets/images/icons/forward_inactive.svg";


interface SideBarAccordionProps {
  className?: string;
  headline: string;
  src?: string;
  children?: React.ReactNode;
}

interface StateProps {
  accordionOpen: boolean;
}

export default class SideBarAccordion extends React.Component<SideBarAccordionProps, StateProps> {

  constructor(props: SideBarAccordionProps) {
    super(props);
    this.state = {
      accordionOpen: false
    };
  }

  render() {
    return (
      <div
        className={`sideBarAccordion ${ this.props.className || "" }`}
      >
        <div className="sideBarAccordion__header" onClick={() =>this.setState({accordionOpen: !this.state.accordionOpen})}>
          <h3>{this.props.headline}</h3>
          {this.state.accordionOpen ?
            <Icon
              src={IconUp}
              width={20}
              height={20}
            />
            :
            <Icon
              src={IconDown}
              width={20}
              height={20}
            />
          }
        </div>
        <CSSTransition
          in={this.state.accordionOpen}
          timeout={500}
          unmountOnExit={true}
          classNames='slide'
        >
          <div className="sideBarAccordion__content">
            {this.props.src &&
              <div className="sideBarAccordion__image">
                <img src={this.props.src} />
              </div>
            }
            { this.props.children }
          </div>
        </CSSTransition>
      </div>
    );
  }
}
