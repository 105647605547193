/**
 * SideBar Content
 * Content which displays the sample Content for one POI.
 */

import * as React from "react";
import { BreakpointContext } from "~src/App";
import { BREAKPOINTS } from "~src/logic/enums/enums";

// Components
import SideBarHeadline from "~Components/SideBarHeadline/SideBarHeadline";
import SideBarTopLine from "~Components/SideBarTopLine/SideBarTopLine";
import SideBarText from "~Components/SideBarText/SideBarText";
import SideBarImage from "~Components/SideBarImage/SideBarImage";
import SideBarTabs from "~Components/SideBarTabs/SideBarTabs";
import SideBarPoi, { SideBarPoiProperties } from "~src/contentComponents/common/SideBarPoi";

// Stylesheets
import "./SideBarPoiFive.scss";

// Images
import HeaderBackground from "~Assets/images/Carre/POI_5/Carre_Heroshot_05.png";
import POIIcon from "~Assets/images/icons/POI_small05.svg";
import ImageOne from "~Assets/images/Carre/POI_5/POI_05_01.jpg";
import LademanagementNiedrig from "~Assets/images/Carre/POI_5/POI_05_02.jpg";
import LademanagementHoch from "~Assets/images/Carre/POI_5/POI_05_03.jpg";

export default class SideBarPoiFive extends SideBarPoi {
  constructor(props: SideBarPoiProperties) {
    super(props);
  }

  render() {
    return (
      <div className={`sideBarPoiFive ${ this.props.className || "" }`} style={{ backgroundImage: `url(${HeaderBackground})` }}>
        <div className="poiContentWrap">
          <div className="poiContent">
            <div className="poiContent__inner">
              <SideBarTopLine>
                Das NETZlabor E-Mobility-Carré
              </SideBarTopLine>
              <SideBarHeadline
                icon={POIIcon}
                className='smallMargin'
              >
                Ladeinfrastruktur mit intelligentem Lademanagement
              </SideBarHeadline>
              <BreakpointContext.Consumer>
                {value =>
                  <>
                    {value !== BREAKPOINTS.LG && (
                      <SideBarImage
                        src={HeaderBackground}
                      />
                    )}
                  </>
                }
              </BreakpointContext.Consumer>
              <SideBarText
                color="#000"
              >
                Die in der Tiefgarage verbauten Komponenten sind das Zentrum des technischen Aufbaus des NETZlabors: die zentrale Ladestation umfasst 58 Ladepunkte. Zusätzlich werden ein zentrales Lademanagement und zwei Batteriespeicher mit je 18 kW Leistung bei jeweils 19 kWh Kapazität installiert, um bei Lastspitzen zusätzliche Leistung bereitzustellen und so den Leistungsbedarf des Netzanschlusses aus dem Stromnetz zu reduzieren. Um eine Überlastung des bestehenden Anschlusses zu verhindern und Tests mit hohem Leistungsbedarf durchführen zu können, wird vorsorglich ein separater Netzanschluss verlegt. Jeder der 58 Ladepunkte wird mit einer Ladeleistung von bis zu 11 kW versorgt. Auch die Messungen der Ladezeiten sowie der Stromstärken und Spannungsniveaus für die einzelnen Ladepunkte finden hier statt. Jeder Schaltschrank kann über einen verbauten elektrischen Schalter acht Ladepunkte gleichzeitig versorgen. Über insgesamt sechs Ladeterminals können die E-Pionier*innen den Ladevorgang mittels eines RFID-Chips starten. In den Schaltschränken der Ladestation ist das intelligente Lademanagement integriert.
              </SideBarText>
              <SideBarImage
                src={ImageOne}
              />
              <SideBarHeadline
              >
                Lademanagement zur Reduktion der Netzanschlussleistung
              </SideBarHeadline>
              <SideBarTabs
                src1={LademanagementNiedrig}
                src2={LademanagementHoch}
                leftText="Niedriger Leistungsbedarf"
                rightText="Hoher Leistungsbedarf"
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}
