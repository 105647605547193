import React from "react";

interface StepContentStyle {
    width: string;
    transform: string;
    stepCount: number;
}

export interface StepContainerProps {
    className?: string;
    closeContainer: React.ReactEventHandler;
}

export default class BaseStepContainer extends React.Component<StepContainerProps, StepContentStyle> {
  constructor(props: StepContainerProps) {
    super(props);
    this.state = {
      width: "100%",
      transform: "translate(0)",
      stepCount: 5
    };
    this.stepInner = React.createRef();
    this.setStepState = this.setStepState.bind(this);
  }

  componentDidMount() {
    this.setState({width: `${this.getWidth()}px`});
    window.addEventListener("resize", this.setStepState);
  }

  componentWillUnmount(): void {
    window.removeEventListener("resize", this.setStepState);
  }

  componentDidUpdate(
    prevProps: Readonly<StepContainerProps>, 
    prevState: Readonly<StepContentStyle>, 
    snapshot?: any
  ): void {
    if(prevState.stepCount !== this.state.stepCount) {
      this.setState({width: `${this.getWidth()}px`});
    }
  }

  getWidth(): number {
    this.setState({stepCount: document.getElementsByClassName("step").length});
    if(this.stepInner.current !== null) {
      let width = this.stepInner.current?.offsetWidth;
      width = width * this.state.stepCount;
      return width;
    }
    return 0;
  }

  setStepState(): void {
    this.setState({stepCount: document.getElementsByClassName("step").length});
  }

  setStep(index: number): void {
    this.setState({transform: `translate(-${((1 / this.state.stepCount * 100) * index)}%)`});
    this.currentStep = index;
  }

  getNavigation(): React.ReactElement {
    const navigationTriggers: Array<React.ReactElement> = [];
    for (let i = 0; i < this.state.stepCount; i++) {
      navigationTriggers.push(
        <div key={i} className={`navigation__trigger ${this.currentStep === i ? "active" : ""}`} onClick={() => this.setStep(i)}>
          <div className="navigation__circle"></div>
        </div>
      );

    }

    return(
      <div className="navigation">
        {navigationTriggers.map((trigger, index) => (
          <React.Fragment key={index}>
            {trigger}
          </React.Fragment>
        ))}
      </div>
    );
  }

  protected stepInner: React.RefObject<HTMLDivElement>;
  protected currentStep = 0;
}
