import { Quaternion, Vector2, Vector3 } from "@babylonjs/core/Maths/math.vector";

/**
 * Creates a Vector2 from an array of numbers.
 */
export function toVector2(v: number[]): Vector2 {
  return new Vector2(
    v[0], v[1]
  );
}

/**
 * Creates a Vector3 from an array of numbers.
 */
export function toVector3(v: number[]): Vector3 {
  return new Vector3(
    v[0], v[1], v[2]
  );
}

/**
 * Converts a vector from the default Blender coordinate system
 * into the coordinate system of BabylonJS.
 *
 * @param v Input vector that should be converted.
 *
 * @returns The converted vector in the the CS of BabylonJS.
 */
export function toBabylonCS(v: Vector3): Vector3 {
  return new Vector3(-v.x, v.z, -v.y);
}

/**
 * Ensures that the given value stays between the lower and upper limit.
 *
 * @returns Updated value guarantueed to be contained in [lowerLimit, upperLimit].
 */
export function clamp(value: number, lowerLimit: number, upperLimit: number): number {
  return Math.max(lowerLimit, Math.min(value, upperLimit));
}

/**
 * Returns the rotation from (0,0,1) to the given direction as a quaternion.
 * The given direction will be normalized.
 * Rotation around the x-axis will be stripped from the result to avoid tilting.
 * Inspiration: https://stackoverflow.com/a/1171995
 */
export function rotationToDirection(direction: Vector3) {
  const v1 = new Vector3(0,0,1);
  const v2 = direction.normalizeToNew();
  const cross = v1.cross(v2);
  const dot = Vector3.Dot(v1, v2);

  // Special case when v1 and v2 are parallel and go into the same direction.
  if (
    cross.lengthSquared() == 0.0 && // v1 and v2 are parallel if this is true.
    dot < 0.0 // v1 and v2 point into the same direction for negative values.
  ) {
    return new Quaternion(1,0,0,0).normalize();
  }

  const result = new Quaternion(
    cross.x, cross.y, cross.z,
    Math.sqrt(v1.lengthSquared() * v2.lengthSquared()) + dot
  ).normalize();
  // Avoid tilting by setting the x component to 0.
  result.x = 0.0;
  return result.normalize();
}
