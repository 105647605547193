/**
 * SideBar Content
 * Content which displays the sample Content for one POI.
 */

import * as React from "react";
import { BreakpointContext } from "~src/App";
import { BREAKPOINTS } from "~src/logic/enums/enums";

// Components
import SideBarHeadline from "~Components/SideBarHeadline/SideBarHeadline";
import SideBarTopLine from "~Components/SideBarTopLine/SideBarTopLine";
import SideBarText from "~Components/SideBarText/SideBarText";
import SideBarInfoBox from "~Components/SideBarInfoBox/SideBarInfoBox";
import SideBarTabs from "~Components/SideBarTabs/SideBarTabs";
import SideBarPoi, { SideBarPoiProperties } from "~src/contentComponents/common/SideBarPoi";
import SideBarImage from "~Components/SideBarImage/SideBarImage";

// Stylesheets
import "./SideBarPoiThree.scss";

// Images
import HeaderBackground from "~Assets/images/Chaussee/poi_3/Chaussee_Heroshot_03.jpg";
import POIIcon from "~Assets/images/icons/POI_small03.svg";
import ElectricitySummer from "~Assets/images/Chaussee/poi_3/POI_03_Sommer.png";
import ElectricityWinter from "~Assets/images/Chaussee/poi_3/POI_03_Winter.png";
import SummerActive from "~Assets/images/icons/toggle sommer_active.svg";
import SummerInactive from "~Assets/images/icons/toggle sommer_inactive.svg";
import WinterActive from "~Assets/images/icons/toggle winter_active.svg";
import WinterInactive from "~Assets/images/icons/toggle winter_inactive.svg";

export default class SideBarPoiThree extends SideBarPoi {
  constructor(props: SideBarPoiProperties) {
    super(props);
  }

  render() {
    return (
      <div className={`sideBarPoiThree ${ this.props.className || "" }`} style={{ backgroundImage: `url(${HeaderBackground})` }}>
        <div className="poiContentWrap">
          <div className="poiContent">
            <div className="poiContent__inner">
              <SideBarTopLine>
                Das NETZlabor E-Mobility-Chaussee
              </SideBarTopLine>
              <SideBarHeadline
                icon={POIIcon}
                className='smallMargin'
              >
                Das freie Laden
              </SideBarHeadline>
              <BreakpointContext.Consumer>
                {value =>
                  <>
                    {value !== BREAKPOINTS.LG && (
                      <SideBarImage
                        src={HeaderBackground}
                      />
                    )}
                  </>
                }
              </BreakpointContext.Consumer>
              <SideBarText
                color="#000"
              >
                Um herauszufinden welche Auswirkungen E-Fahrzeuge auf das Stromnetz haben, wenn im Extremfall viele gleichzeitig geladen werden, ist es wichtig, das natürliche Ladeverhalten (freies Laden) der E-Pionier*innen genau zu analysieren. In der ersten Testphase sollen Erkenntnisse über das alltägliche Nutzungsverhalten mit E-Fahrzeugen gewonnen werden. Um ein realistisches Bild zu erhalten, werden dabei keine Einschränkungen wie z.B. vorgegebene Ladezeiträume vorgenommen.
                <br /><br />
                Die hierbei höchste natürlich aufgetretene Gleichzeitigkeit liegt bei 75 % – was sechs von insgesamt acht Testfahrzeugen entspricht – und damit höher als erwartet ausfällt. Dahingegen laden zu Spitzenzeiten (19 - 23 Uhr) durchschnittlich nur 1,4 E-Fahrzeuge. Dies entspricht einer durchschnittlichen Gleichzeitigkeit von 17,5 %.
                <br /><br />
                Die erste Testphase liefert die Grundlage für die Erprobung von drei technischen Lösungsansätzen zur Verbesserung des Netzzustands: Dem Strangregler, dem zentralen Batteriespeicher und dem Lademanagement.
              </SideBarText>
              <SideBarInfoBox>
                Die Römerstraße wird von zwei parallel verlaufenden Kabelsträngen, ausgehend von der Umspannstation, versorgt. Um die Auswirkungen aller E-Fahrzeuge auf beiden Straßenseiten zu erfassen wird die Summe der Ströme (I) beider Kabelstränge betrachtet.
              </SideBarInfoBox>
              <SideBarTabs
                src1={ElectricitySummer}
                src2={ElectricityWinter}
                icon1Active={SummerActive}
                icon1Inactive={SummerInactive}
                icon2Active={WinterActive}
                icon2Inactive={WinterInactive}
                setPopoutImage={this.props.setPopoutImage}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}
