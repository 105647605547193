/**
 * SideBar Content
 * Component displaying the sample content for the fourth POI.
 */

import * as React from "react";
import { BreakpointContext } from "~src/App";
import { BREAKPOINTS } from "~src/logic/enums/enums";

// Components
import SideBarHeadline from "~Components/SideBarHeadline/SideBarHeadline";
import SideBarTopLine from "~Components/SideBarTopLine/SideBarTopLine";
import SideBarText from "~Components/SideBarText/SideBarText";
import SideBarImage from "~Components/SideBarImage/SideBarImage";
import SideBarPoi, { SideBarPoiProperties } from "~src/contentComponents/common/SideBarPoi";

// Stylesheets
import "./SideBarPoiFour.scss";

// Images
import HeaderBackground from "~Assets/images/NIHL/POI_4/NIHL_Heroshot_03.png";
import POIIcon from "~Assets/images/icons/POI_small04.svg";
import ImageOne from "~Assets/images/NIHL/POI_4/POI_3_01.gif";

export default class SideBarPoiFour extends SideBarPoi {
  constructor(props: SideBarPoiProperties) {
    super(props);
  }

  render() {
    return (
      <div className={`sideBarPoiFour ${ this.props.className || "" }`} style={{ backgroundImage: `url(${HeaderBackground})` }}>
        <div className="poiContentWrap">
          <div className="poiContent">
            <div className="poiContent__inner">
              <SideBarTopLine>
                Das NETZlabor Intelligentes Heimladen
              </SideBarTopLine>
              <SideBarHeadline
                icon={POIIcon}
                className='smallMargin'
              >
                Vom Testszenario zum Standard: Vier Entwicklungsstufen des intelligenten Lademanagements
              </SideBarHeadline>
              <BreakpointContext.Consumer>
                {value =>
                  <>
                    {value !== BREAKPOINTS.LG && (
                      <SideBarImage
                        src={HeaderBackground}
                      />
                    )}
                  </>
                }
              </BreakpointContext.Consumer>
              <SideBarText
                color="#000"
              >
                In den NETZlaboren E-Mobility-Allee, -Carré und -Chaussee hat die Netze BW die tatsächliche Wirksamkeit unterschiedlichster Lademanagementsysteme getestet. Die größte Herausforderung für die Zukunft besteht jedoch in der Entwicklung einer standardisierbaren Lösung für den flächendeckenden Einsatz von Lademanagement. Im Kern des NETZlabors Intelligentes Heimladen steckt der Ansatz, eine solche Ansteuerung privater Ladepunkte über das intelligente Messsystem mit Steuerbox zu realisieren. Das funktioniert über die Infrastruktur der digitalen Stromzähler, sogenannter Smart Meter, die die konventionellen (analogen) Stromzähler nach und nach ersetzen. Dadurch wird die Technik standardisierbar und skalierbar. Die vier Entwicklungsstufen des Projekts bauen wie folgt aufeinander auf:
                <br /><br />
                <b>Stufe 1:</b><br />
                Smart Meter Gateway und Steuerbox werden von einem Hersteller eingesetzt. Die Kommunikation erfolgt über ein herstellerspezifisches Protokoll, sodass nur einheitliche Geräte steuerfähig sind. Eine Fahrplansteuerung erfolgt &quot;einstufig&quot;. Das bedeutet entweder eine Freigabe oder Beschränkung auf einen definierten Wert von z.B. 50 % der maximalen Ladeleistung.
                <br /><br />
                <b>Stufe 2:</b><br />
                Smart Meter Gateway und Steuerbox können über ein standardisiertes Kommunikationsprotokoll (IEC-Norm) jetzt auch von unterschiedlichen Herstellern zusammen eingesetzt werden. Die Steuerbefehle sind weiterhin “einstufig”.
                <br /><br />
                <b>Stufe 3:</b><br />
                Es ist keine separate Messung der Wallbox mehr notwendig, um Zustandsdaten zu erfassen. Diese werden jetzt über das intelligente Messsystem gemessen und versandt. Steuerung und Messung erfolgen nun über eine Infrastruktur, die Steuerbefehle sind jedoch weiterhin “einstufig”.
                <br /><br />
                <b>Stufe 4:</b><br />
                Neben der integrierten Messung für Zustandsdaten der Wallbox (Stufe 3) verfügen das intelligente Messsystem und die Steuerbox jetzt auch über eine standardisierte Kommunikationsschnittstelle  zum Ladepunkt (nach VDE-Anwendungsregel). Damit lassen sich mehrstufige oder sogar stufenlose Steuerbefehle umsetzen. Leistungsdaten der Wallbox sind ebenfalls jederzeit nach Bedarf abrufbar.

              </SideBarText>
              <SideBarImage
                src={ImageOne}
                setPopoutImage={this.props.setPopoutImage}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}
