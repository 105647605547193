/**
 * SideBar Content
 * Content which displays the sample Content for one POI.
 */

import * as React from "react";
import { BreakpointContext } from "~src/App";
import { BREAKPOINTS } from "~src/logic/enums/enums";

// Components
import SideBarHeadline from "~Components/SideBarHeadline/SideBarHeadline";
import SideBarTopLine from "~Components/SideBarTopLine/SideBarTopLine";
import SideBarText from "~Components/SideBarText/SideBarText";
import SideBarImage from "~Components/SideBarImage/SideBarImage";
import SideBarPoi, { SideBarPoiProperties } from "~src/contentComponents/common/SideBarPoi";

// Stylesheets
import "./SideBarPoiEight.scss";

// Images
import HeaderBackground from "~Assets/images/Carre/POI_8/Carre_Heroshot_08.png";
import POIIcon from "~Assets/images/icons/POI_small08.svg";
import ImageOne from "~Assets/images/Carre/POI_8/POI_8_01.png";

export default class SideBarPoiEight extends SideBarPoi {
  constructor(props: SideBarPoiProperties) {
    super(props);
  }

  render() {
    return (
      <div className={`sideBarPoiEight ${ this.props.className || "" }`} style={{ backgroundImage: `url(${HeaderBackground})` }}>
        <div className="poiContentWrap">
          <div className="poiContent">
            <div className="poiContent__inner">
              <SideBarTopLine>
                Das NETZlabor E-Mobility-Carré
              </SideBarTopLine>
              <SideBarHeadline
                icon={POIIcon}
                className='smallMargin'
              >
                Der separate Netzanschluss
              </SideBarHeadline>
              <BreakpointContext.Consumer>
                {value =>
                  <>
                    {value !== BREAKPOINTS.LG && (
                      <SideBarImage
                        src={HeaderBackground}
                      />
                    )}
                  </>
                }
              </BreakpointContext.Consumer>
              <SideBarText
                color="#000"
              >
                Die Versorgung über einen separaten Netzanschluss mit 124 kW wird gewählt, um ausreichend Flexibilität für die geplanten Testszenarien zu haben. Diese Dimensionierung ist durch den Einsatz eines intelligenten Lademanagements möglich. Ohne dieses System müsste die Netzanschlussleistung auf 638 kW ausgelegt werden. Der separate Netzanschluss mit einer Kapazität von 124 kW wird - selbst ohne Leistungsoptimierung durch Lademanagement - nur zu 79 % ausgelastet. Mit dem auf eine Leistungsgrenze von 40 kW eingestellten Lademanagement beträgt die Auslastung des Netzanschlusses 32 %. Kurzum: der separate Netzanschluss ist für das Ladeverhalten der E-Pionier*innen im E-Mobility-Carré überdimensioniert.
              </SideBarText>
              <SideBarImage
                src={ImageOne}
                setPopoutImage={this.props.setPopoutImage}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}
