/**
 * Header Component
 * Fixed Header on top of screen
 * When the Overlay is shown only the logo is visible
 */

import * as React from "react";
import { Link } from "react-router-dom";

// Components
import Icon from "../Icon/Icon";

// Stylesheets
import "./Header.scss";

// Images
import Logo from "~Assets/images/logo/NetzeBW_Logo_BlauOrange_sRGB 1.svg";
import POIMenu from "~Assets/images/icons/menu_inactive.svg";
import POIMenuPressed from "~Assets/images/icons/menu_active.svg";
import HelpIcon from "~Assets/images/icons/help.svg";

type HeaderState = {
  POIListPressed: boolean;
};

interface HeaderProps {
  className?: string;
  overlayOpen: boolean;
  openPOIList?: React.ReactEventHandler;
  openIntro?: React.ReactEventHandler;
}

export default class Header extends React.Component<HeaderProps, HeaderState> {

  constructor(props: HeaderProps) {
    super(props);

    this.state = {
      POIListPressed: false,
    };
  }

  private openPOIList(start: boolean, evt: React.SyntheticEvent): void {
    if(start) {
      if(this.props.openPOIList) this.props.openPOIList(evt);
      this.setState({POIListPressed: true});
    } else {
      this.setState({POIListPressed: false});
    }
  }

  render() {
    return (
      <div className={`header ${ this.props.className || "" }`}>
        <div className="logo" >
          <Link to="/">
            <img src={ Logo } />
          </Link>
        </div>
        { !this.props.overlayOpen && (
          <>
            <div className="buttons">
              <div className="helpTrigger" onClick={this.props.openIntro}>
                <Icon
                  className="icon"
                  src={ HelpIcon }
                  width={ 44 }
                  height={ 44 }
                />
              </div>
              <div className="menuTrigger"
                onMouseDown={(evt) => this.openPOIList(true, evt)}
                onTouchStart={(evt) => this.openPOIList(true, evt)}
                onKeyDown={(evt) => this.openPOIList(true, evt)}
                onMouseUp={(evt) => this.openPOIList(false, evt)}
                onTouchEnd={(evt) => this.openPOIList(false, evt)}
                onKeyUp={(evt) => this.openPOIList(false, evt)}
                onMouseLeave={(evt) => this.openPOIList(false, evt)}
              >
                {!this.state.POIListPressed ?
                  <Icon
                    className="icon"
                    src={ POIMenu }
                    width={ 44 }
                    height={ 44 }
                  />
                  :
                  <Icon
                    className="icon"
                    src={ POIMenuPressed }
                    width={ 44 }
                    height={ 44 }
                  />
                }
              </div>
            </div>
          </>
        )}
      </div>
    );
  }
}
