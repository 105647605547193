import * as React from "react";

// Stylesheets
import "./NavigationButton.scss";

import StartStopButton from "../StartStopButton/StartStopButton";


type PressRelease = {
    onStart: React.ReactEventHandler;
    onStop: React.ReactEventHandler;
};

type NavigationButtonProps = {
    onForward: PressRelease;
    onBackward: PressRelease;
    disable: boolean;
};

/**
 * Button component providing an interface emulating the arrow keys of a keyboard.
 * The up and down arrow key are each simulated with the StartStopButton component.
 */
export default class NavigationButton extends React.Component<NavigationButtonProps> {

  constructor(props: NavigationButtonProps) {
    super(props);
  }

  render(): JSX.Element {
    return (
      <div className={`navigationButton ${this.props.disable ? "disabled" : ""}`}>
        <StartStopButton
          className="forwardButton"
          onStart={this.props.onForward.onStart}
          onStop={this.props.onForward.onStop}
        />
        <div className="seperator"></div>
        <StartStopButton
          className="backwardButton"
          onStart={this.props.onBackward.onStart}
          onStop={this.props.onBackward.onStop}
        />
      </div>
    );
  }
}
