/**
 * SideBar Content
 * Content which displays the sample Content for one POI.
 */

import * as React from "react";
import { BreakpointContext } from "~src/App";
import { BREAKPOINTS } from "~src/logic/enums/enums";

// Components
import SideBarHeadline from "~Components/SideBarHeadline/SideBarHeadline";
import SideBarTopLine from "~Components/SideBarTopLine/SideBarTopLine";
import SideBarText from "~Components/SideBarText/SideBarText";
import SideBarTabs from "~Components/SideBarTabs/SideBarTabs";
import SideBarPoi, { SideBarPoiProperties } from "~src/contentComponents/common/SideBarPoi";
import SideBarImage from "~Components/SideBarImage/SideBarImage";

// Stylesheets
import "./SideBarPoiSix.scss";

// Images
import HeaderBackground from "~Assets/images/Carre/POI_6/Carre_Heroshot_06.png";
import POIIcon from "~Assets/images/icons/POI_small06.svg";
import Static from "~Assets/images/Carre/POI_6/POI_06_01.jpg";
import Dynamic from "~Assets/images/Carre/POI_6/POI_06_02.jpg";

export default class SideBarPoiSix extends SideBarPoi {
  constructor(props: SideBarPoiProperties) {
    super(props);
  }

  render() {
    return (
      <div className={`sideBarPoiSix ${ this.props.className || "" }`} style={{ backgroundImage: `url(${HeaderBackground})` }}>
        <div className="poiContentWrap">
          <div className="poiContent">
            <div className="poiContent__inner">
              <SideBarTopLine>
              Das NETZlabor E-Mobility-Carré
              </SideBarTopLine>
              <SideBarHeadline
                icon={POIIcon}
                className='smallMargin'
              >
                Der Batteriespeicher
              </SideBarHeadline>
              <BreakpointContext.Consumer>
                {value =>
                  <>
                    {value !== BREAKPOINTS.LG && (
                      <SideBarImage
                        src={HeaderBackground}
                      />
                    )}
                  </>
                }
              </BreakpointContext.Consumer>
              <SideBarText
                color="#000"
              >
                Batteriespeicher können temporär Leistungsspitzen am Hausanschluss, verursacht durch ladende E-Fahrzeuge, kompensieren. Durch eine Mess- und Steuereinheit, welche die Leistung am Netzanschlusspunkt überwacht, wird der Batteriespeicher gesteuert. Zur Reduktion der Leistungsspitzen am Netzanschlusspunkt werden zwei Betriebsmodi untersucht: statischer und dynamischer Betrieb. Statischer (oder fahrplanbasierter) Betrieb bedeutet, dass die Be- und Entladung des Speichers zu vorher fest definierten Zeiträumen erfolgt. Beim dynamischen (stromgeführten) Betrieb stellt der Batteriespeicher bei Überschreitung des oberen definierten Grenzwertes zusätzlich Leistung bereit, um einem Anstieg der Lastkurve entgegenzuwirken. Wird die untere Grenze unterschritten, lädt sich der Batteriespeicher bei Bedarf nach. Eine wichtige Erkenntnis aus dem Versuchsaufbau ist, dass der Einsatz eines Batteriespeichers nicht die gewünschte Wirkung erzielt: An manchen Tagen kommt der Batteriespeicher nicht zum Einsatz, weil entweder keine Leistungsspitzen über 35 kW entstehen, oder auch weil durch einen zu großen Leistungsbedarf der E-Fahrzeuge innerhalb kürzester Zeit viel Energie aus dem Batteriespeicher entnommen wird. Dies hat zur Folge, dass der Speicher nicht über die gesamte Dauer der Leistungsspitze aktiv unterstützen kann und es somit zu einem starken Anstieg der Bezugsleistung am Netzanschlusspunkt kommt.
              </SideBarText>
              <SideBarTabs
                src1={Static}
                src2={Dynamic}
                leftText="Statisch"
                rightText="Dynamisch"
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}
