/**
 * LandingPageFooter Component
 * Fixed LandingPageFooter on top of screen
 * When the Overlay is shown only the logo is visible
 */

import * as React from "react";

// Components
import Icon from "../../Icon/Icon";

// Stylesheets
import "./LandingPageFooter.scss";

// Images
import Logo from "../../../assets/images/landing_page/marke_logo_netz-white.png";
import InstagramIcon from "../../../assets/images/landing_page/Instagram.svg";
import TwitterIcon from "../../../assets/images/landing_page/Twitter.svg";
import YoutubeIcon from "../../../assets/images/landing_page/Youtube.svg";
import LinkedInIcon from "../../../assets/images/landing_page/Linkedin.svg";


interface LandingPageFooterProps {
  className?: string;
}

export default class LandingPageFooter extends React.Component<LandingPageFooterProps> {

  constructor(props: LandingPageFooterProps) {
    super(props);
  }

  render() {
    return (
      <div className={`landingPageFooter ${ this.props.className || "" }`}>
        <div className="content">
          <div className="top">
            <div className="col col-1">
              <Icon className="logo" src={Logo} height={43} width={180} />
              <p>Netze BW GmbH<br />
                Schelmenwasenstraße 15<br />
                70567 Stuttgart
              </p>
            </div>
            <div className="col col-2">
              <p>Bleiben Sie auf dem Laufenden</p>
              <div className="socialMedia">
                <a href="https://www.instagram.com/netze_bw/?hl=de" target="_blank" rel="noreferrer">
                  <Icon src={InstagramIcon} height={38} width={38} />
                </a>
                <a href="https://twitter.com/netzebw?lang=de" target="_blank" rel="noreferrer">
                  <Icon src={TwitterIcon} height={38} width={38} />
                </a>
                <a href="https://www.youtube.com/channel/UC1IZ9Ybcn0qAhg2HOIYqWMQ/featured" target="_blank" rel="noreferrer">
                  <Icon src={YoutubeIcon} height={38} width={38} />
                </a>
                <a href="https://www.linkedin.com/company/netze-bw-gmbh/" target="_blank" rel="noreferrer">
                  <Icon src={LinkedInIcon} height={38} width={38} />
                </a>
              </div>
            </div>
          </div>
          <div className="bottom">
            <div className="borderWrap">
              <div className="linkWrap">
                <a href="https://www.netze-bw.de/datenschutz" target="_blank" rel="noreferrer">Datenschutz</a>
                <a href="https://www.netze-bw.de/impressum" target="_blank" rel="noreferrer">Impressum</a>
              </div>
              <p>Ein Unternehmen der EnBW</p>
            </div>
            <p>Ⓒ Netze BW GmbH 2022. Alle Rechte vorbehalten</p>
          </div>
        </div>
      </div>
    );
  }
}
