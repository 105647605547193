/**
 * SideBar Content
 * Content which displays the sample Content for one POI.
 */

import * as React from "react";
import { BreakpointContext } from "~src/App";
import { BREAKPOINTS } from "~src/logic/enums/enums";

// Components
import SideBarHeadline from "~Components/SideBarHeadline/SideBarHeadline";
import SideBarTopLine from "~Components/SideBarTopLine/SideBarTopLine";
import SideBarText from "~Components/SideBarText/SideBarText";
import SideBarImage from "~Components/SideBarImage/SideBarImage";
import SideBarPoi, { SideBarPoiProperties } from "~src/contentComponents/common/SideBarPoi";

// Stylesheets
import "./SideBarPoiNine.scss";

// Images
import HeaderBackground from "~Assets/images/Carre/POI_9/Carre_Heroshot_09.png";
import POIIcon from "~Assets/images/icons/POI_small09.svg";
import ImageOne from "~Assets/images/Carre/POI_9/POI_9_01.png";

export default class SideBarPoiNine extends SideBarPoi {
  constructor(props: SideBarPoiProperties) {
    super(props);
  }

  render() {
    return (
      <div className={`sideBarPoiNine ${ this.props.className || "" }`} style={{ backgroundImage: `url(${HeaderBackground})` }}>
        <div className="poiContentWrap">
          <div className="poiContent">
            <div className="poiContent__inner">
              <SideBarTopLine>
                Das NETZlabor E-Mobility-Carré
              </SideBarTopLine>
              <SideBarHeadline
                icon={POIIcon}
                className='smallMargin'
              >
                Die Umspannstation
              </SideBarHeadline>
              <BreakpointContext.Consumer>
                {value =>
                  <>
                    {value !== BREAKPOINTS.LG && (
                      <SideBarImage
                        src={HeaderBackground}
                      />
                    )}
                  </>
                }
              </BreakpointContext.Consumer>
              <SideBarText
                color="#000"
              >
                Der Anschluss des E-Mobility-Carré wird über die Umspannstation mit Strom versorgt. Eine Besonderheit des NETZlabors ist der bereits erwähnte separate Netzanschluss. Dieser versorgt einzig und allein die Ladestation über eigene Niederspannungsabgänge mit Energie. In den meisten Fällen würde jedoch der Strom über den bestehenden Netzanschluss bezogen, der auch die Wohnungen in den Gebäuden versorgt. Durch die Messtechnik, die an jedem Niederspannungsabgang angeschlossen ist, leitet die Netze BW wertvolle Erkenntnisse über den Zustand des lokalen Stromnetzes ab.
                <br /><br />
                Die Versorgung über einen separaten Netzanschluss außerhalb des Forschungsrahmens ist jedoch kein Standardvorgehen seitens eines Verteilnetzbetreibers und bedarf in jedem Fall einer Einzelprüfung.
              </SideBarText>
              <SideBarImage
                src={ImageOne}
                setPopoutImage={this.props.setPopoutImage}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}
