import "@babylonjs/loaders/glTF";
import "@babylonjs/core/Materials/Textures/Loaders/envTextureLoader";

import { CreateBox, CreateCapsule, Engine, HDRCubeTexture, KeyboardEventTypes, KeyboardInfo, Mesh, Scene, StandardMaterial, Texture, Vector2, Vector3 } from "@babylonjs/core";
import { Color3, CubeTexture } from "@babylonjs/core";
import { SceneLoader } from "@babylonjs/core/Loading/sceneLoader";

import { CityConfiguration } from "~src/logic/io/json/city_configuration";
import { Track } from "~src/rendering/track";
import { MultiCamera, CameraType } from "~src/rendering/multiCamera";

import environmentMap from "~Assets/environment/sky.env";

import skyCubeTexturePX from "~Assets/environment/sky_px.png";
import skyCubeTextureNX from "~Assets/environment/sky_nx.png";
import skyCubeTexturePY from "~Assets/environment/sky_py.png";
import skyCubeTextureNY from "~Assets/environment/sky_ny.png";
import skyCubeTexturePZ from "~Assets/environment/sky_pz.png";
import skyCubeTextureNZ from "~Assets/environment/sky_nz.png";

import UserInterface from "~src/rendering/userInterface";
import { rotationToDirection, toBabylonCS, toVector3 } from "~src/rendering/utility";

import PoiOverlay from "./poiOverlay";
import { BREAKPOINTS } from "~src/logic/enums/enums";

enum SupportedKeyboardKeys {
  ArrowDown = "ArrowDown",
  ArrowUp = "ArrowUp",
}

enum WalkState {
  /**
   * The player does not move at all.
   */
  Stopped = 0,
  /**
   * The system decided that the player should be moved along the track.
   * Animations, e.g of the camera, should be done in this phase.
   */
  Planned = 1,
  /**
   * The player is moving along the track,
   * animations should not be active.
   */
  Running = 2,
}

type CameraTypeChangeCallback = { (type: CameraType): void };

interface RenderState {
  scene: Scene;
  camera: MultiCamera;
  userInterface: UserInterface;
  poiOverlay: PoiOverlay;
  player: Mesh;
  track: Track;
  pressedKeys: Set<SupportedKeyboardKeys>;
}

export interface MapConfig {
  miniMapUrl: string;
  bottomLeft: Vector2;
  bottomRight: Vector2;
  topRight: Vector2;
  angle: number;
  scale: number;
}

export interface PoiEnterEvent { (poiIndex: number): void }
export interface PoiLeaveEvent { (): void }
export interface PoiClickedEvent { (poiIndex: number): void }

const PLAYER_HEIGHT = 1.75;
const MINIMAP_WIDTH_PERCENTAGE = 0.15;
const POI_DISTANCE_EPSILON = 0.0001;

export default class CityScene {

  public static SupportedKeyboardKeys = SupportedKeyboardKeys;

  constructor(configuration: CityConfiguration) {
    this.configuration = configuration;
  }

  public async create(
    engine: Engine,
    cameraTypeCallback: CameraTypeChangeCallback,
    worldModel: string,
    miniMapConfig: MapConfig,
  ) {
    this.cameraTypeCallback = cameraTypeCallback;
    // This creates a basic Babylon Scene object (non-mesh)
    const scene = new Scene(engine);

    this.miniMapOffset = miniMapConfig.bottomLeft;
    this.miniMapSize = new Vector2(
      Math.abs(miniMapConfig.bottomRight.x - miniMapConfig.bottomLeft.x),
      Math.abs(miniMapConfig.bottomLeft.y - miniMapConfig.topRight.y),
    );
    this.miniMapScale = miniMapConfig.scale;
    this.miniMapRotation = miniMapConfig.angle;

    // load the environment file
    scene.environmentTexture = new CubeTexture(environmentMap, scene);

    const skybox = CreateBox("skyBox", { size: 10000.0 }, scene);
    const skyboxMaterial = new StandardMaterial("skyBox", scene);
    skyboxMaterial.backFaceCulling = false;
    skyboxMaterial.reflectionTexture = new CubeTexture(
      "", scene, undefined,  undefined, [
        skyCubeTexturePX, skyCubeTexturePY, skyCubeTexturePZ,
        skyCubeTextureNX, skyCubeTextureNY, skyCubeTextureNZ,
      ]
    );
    skyboxMaterial.reflectionTexture.coordinatesMode = Texture.SKYBOX_MODE;
    skyboxMaterial.diffuseColor = new Color3(0, 0, 0);
    skyboxMaterial.specularColor = new Color3(0, 0, 0);
    skybox.material = skyboxMaterial;

    const loadedWorld = await SceneLoader.ImportMeshAsync(
      "",
      "",
      worldModel,
      scene,
      undefined,
      ".glb"
    );

    // Disable pickability of the entire world to improve performance.
    for (const mesh of loadedWorld.meshes) {
      mesh.isPickable = false;
    }

    const player = CreateCapsule("player", {
      height: PLAYER_HEIGHT
    });
    player.isPickable = false;
    player.visibility = 0;

    scene.onKeyboardObservable.add(this.onKeyboard.bind(this));

    const poiOverlay = new PoiOverlay(engine, this.configuration);
    await poiOverlay.create((poiIndex: number) => {
      this.clickedPoiIndex = poiIndex;
    });

    const poiPositions: Vector3[] = [];

    for (let i = 0; i < this.configuration.points_of_interest.length; i++) {
      const poi = this.configuration.points_of_interest[i];
      poiPositions.push(toBabylonCS(toVector3(
        poi.look_at_position
      )));
    }

    this.renderState = {
      scene: scene,
      poiOverlay: poiOverlay,
      camera: new MultiCamera(this.configuration, scene, player),
      userInterface: new UserInterface(
        engine,
        miniMapConfig.miniMapUrl,
        MINIMAP_WIDTH_PERCENTAGE,
        poiPositions,
      ),
      player: player,
      track: new Track(this.configuration.paths, player, this.configuration.points_of_interest[0]),
      pressedKeys: new Set<SupportedKeyboardKeys>(),
    };
  }

  public setCameraType(cameraType: CameraType) {
    if (!this.renderState) return;
    if (
      this.currentCameraType !== undefined &&
      cameraType === this.currentCameraType
    ) return;
    this.currentCameraType = cameraType;
    this.nextCameraType = cameraType;
    if (this.cameraTypeCallback)
      this.cameraTypeCallback(cameraType);
  }

  public setPoiTarget(index: number): void {
    if (!this.renderState) return;

    const newTarget = Math.max(0, Math.min(this.configuration.points_of_interest.length - 1, index));

    if (newTarget !== this.currentTrackTarget) {
      this.currentTrackTarget = newTarget;
      this.startAnimationToPoi(this.renderState.track);

      for (const callback of this.subscribedPoiLeaveEventCallbacks) {
        callback();
      }
    }
  }

  /**
   * Start walking to the current track target.
   * If the controlled node is already there only the camera direction will be animated to look at
   * the point of interest.
   */
  private startAnimationToPoi(track: Track) {
    if (!this.renderState) return;
    // Prepare for movement along the track.
    const distanceToTarget =
      track.distance(this.configuration.points_of_interest[this.currentTrackTarget]);
    if (distanceToTarget >= POI_DISTANCE_EPSILON) {
      this.autoWalkState = WalkState.Planned;
    } else if(this.renderState.camera.getCurrentCameraType() === CameraType.STREET) {
      // Orientation should only be done on street level,
      // it will skip the flyin animation otherwise.
      this.orientToPoi(this.currentTrackTarget);
    }
  }

  public render(width: number, height: number, breakpoint: BREAKPOINTS) {
    if (!this.renderState) return;
    const state = this.renderState;
    const camera = state.camera;

    // Progress to the next or the previous point of interest when the respective keys/buttons
    // are pressed.
    if (this.pressedUnhandledKeys.has(SupportedKeyboardKeys.ArrowUp)) {
      this.setPoiTarget(this.currentTrackTarget + 1);
      this.pressedUnhandledKeys.delete(SupportedKeyboardKeys.ArrowUp);
    } else if (this.pressedUnhandledKeys.has(SupportedKeyboardKeys.ArrowDown)) {
      this.setPoiTarget(this.currentTrackTarget - 1);
      this.pressedUnhandledKeys.delete(SupportedKeyboardKeys.ArrowDown);
    }

    // Update the track without moving to trigger the calculation of the shortest path in case the
    // track target has changed.
    // Doing this here will also give us access to the result of the walk function which contains
    // a point on the track that we can point the street camera to.
    const walkResult = state.track.walk(0, this.configuration.points_of_interest[
      this.currentTrackTarget >= 0 ? this.currentTrackTarget : this.initialTrackTarget
    ]);

    const trackDirection = walkResult.lookAtPoint.subtract(state.player.position).normalize();

    // If the camera will be changed to the street view and walking along the path is imminent
    // or the street camera was never active before the direction of the camera on the street is
    // set to the paths direction.
    // In all other cases the direction of the camera must not be overwritten.
    // If for example the user stays at the current point of interest but changes the camera type
    // the view of the street camera should stay the same.
    if (
      this.nextCameraType === CameraType.STREET &&
      (this.autoWalkState === WalkState.Planned || !this.wasOnceStreetCamera)
    ) {
      // Note down that the cameras rotation for the street type was set.
      // As long as this boolean is true animation of the rotation is not wanted.
      this.overwrittenCameraRotationForStreet = true;
      camera.setCameraRotation(
        CameraType.STREET,
        rotationToDirection(trackDirection)
      );
    }
    if (this.nextCameraType !== undefined) {


      if (this.nextCameraType === CameraType.STREET && !this.wasOnceStreetCamera) {

        // It is the first time that the camera is transitioning into the street camera and
        // a poi was targeted.
        // In this case the player will be moved directly to the targetted poi so that the path
        // will not be animated to save precious time.
        if (
          this.currentTrackTarget >= 0 &&
          this.currentTrackTarget < this.configuration.points_of_interest.length
        ) {
          const poi = this.configuration.points_of_interest[this.currentTrackTarget];
          const poiPosition = this.configuration.paths[poi.path_index][poi.location_index];
          state.player.position = toBabylonCS(toVector3(poiPosition));
          const lookAtPosition = toBabylonCS(toVector3(poi.look_at_position));
          camera.setCameraRotation(
            CameraType.STREET,
            rotationToDirection(
              lookAtPosition.subtract(state.player.position).normalize()
            )
          );
        }

        // Note down that the street camera was activated at least once.
        this.wasOnceStreetCamera = true;
      }

      camera.activate(this.nextCameraType);
      this.nextCameraType = undefined;
    }

    if (this.runningCameraRotation && !camera.inAnimation()) {
      this.autoWalkState = WalkState.Running;
      this.runningCameraRotation = false;
    }

    if (this.currentTrackTarget >= 0 && !camera.inAnimation()) {
      switch (this.autoWalkState) {
      case WalkState.Stopped:
        // Currently it is only possible to stop at the points of interest so it is the easiest
        // to always emit this event here.
        // emitPoiEnterEvent() will make sure to only emit the event once for any one poi index.
        this.emitPoiEnterEvent();
        break;
      case WalkState.Planned:
        if (!this.overwrittenCameraRotationForStreet) {
          // Animate the camera into the tracks direction if the rotation was not overwritten.
          // See the variable for more information on this topic.
          camera.setTargetRotation(rotationToDirection(trackDirection));
          this.runningCameraRotation = true;
        } else {
          // If the rotation is not being animated we can start walking.
          this.autoWalkState = WalkState.Running;
          this.overwrittenCameraRotationForStreet = false;
        }
        break;
      case WalkState.Running:
        {
          const walkResult = state.track.walk(
            0.2 * state.scene.getAnimationRatio(),
            this.configuration.points_of_interest[this.currentTrackTarget]
          );

          // If destination is reached auto walk is disengaged.
          if (walkResult.destinationReached) {
            this.autoWalkState = WalkState.Stopped;
            this.orientToPoi(this.currentTrackTarget);
          } else {
            camera.setCameraRotation(
              CameraType.STREET,
              rotationToDirection(
                walkResult.lookAtPoint
                  .subtract(this.renderState.player.position)
                  .normalize()
              )
            );
          }
        }
        break;
      }
    }

    camera.update(width, height);

    // The user clicked on one of the pois in the 3d scene.
    // This is intentionally done at the end and should not be moved further up.
    // Otherwise the camera would first look at the previous poi and then to the clicked poi.
    if (this.clickedPoiIndex >= 0) {
      // The event should only trigger an animation if the camera is on the street and nothing
      // moves.
      if (
        this.autoWalkState === WalkState.Stopped &&
        state.camera.getCurrentCameraType() == CameraType.STREET
      ) {
        this.currentTrackTarget = this.clickedPoiIndex;
        this.startAnimationToPoi(this.renderState.track);
      } else {
        this.emitPoiClickedEvent(this.clickedPoiIndex);
      }
      this.clickedPoiIndex = -1;
    }

    // We have to set the active camera for the scene when rendering multiple scenes.
    // Not doing so results in an error that there is no active camera for this scene.
    state.scene.setActiveCameraById(state.camera.id);
    state.scene.render();

    state.poiOverlay.render(breakpoint, state.camera, this.currentTrackTarget, state.player.position);

    state.userInterface.render(
      width, height,
      new Vector2(
        this.renderState.player.position.x, this.renderState.player.position.z
      ),
      this.miniMapOffset,
      this.miniMapSize,
      this.miniMapScale,
      this.miniMapRotation,
      breakpoint,
    );
  }


  public subscribePoiEnterEvent(callback: PoiEnterEvent): void {
    this.subscribedPoiEnterEventCallbacks.push(callback);
  }

  public subscribePoiLeaveEvent(callback: PoiLeaveEvent): void {
    this.subscribedPoiLeaveEventCallbacks.push(callback);
  }

  public subscribePoiClickedEvent(callback: PoiClickedEvent): void {
    this.subscribedPoiClickedEventCallbacks.push(callback);
  }

  public emitPoiEnterEvent(): void {
    // Only emit the event if it was not already emitted.
    if (this.lastEmittedPoiEnterIndex == this.currentTrackTarget) return;
    this.lastEmittedPoiEnterIndex = this.currentTrackTarget;

    for (const callback of this.subscribedPoiEnterEventCallbacks) {
      callback(this.currentTrackTarget);
    }
  }

  public emitPoiClickedEvent(poiIndex: number): void {
    for (const callback of this.subscribedPoiClickedEventCallbacks) {
      callback(poiIndex);
    }
  }

  /**
   * Marks the given key as pressed.
   */
  public pressKey(key: SupportedKeyboardKeys): void {
    if (!this.renderState) return;
    if (!this.renderState.pressedKeys.has(key)) {
      this.pressedUnhandledKeys.add(key);
    }
    this.renderState.pressedKeys.add(key);
  }

  /**
   * Marks the given key as released.
   */
  public releaseKey(key: SupportedKeyboardKeys): void {
    if (!this.renderState) return;
    this.pressedUnhandledKeys.delete(key);
    this.renderState.pressedKeys.delete(key);
  }

  /**
   * Starts an animation of the camera to look at the lookAtPosition of the given poi.
   *
   * @param poiIndex Index of the poi that the camera should look at.
   */
  private orientToPoi(poiIndex: number) {
    if (!this.renderState) return;

    // Allow the poi enter event to be emitted again.
    this.lastEmittedPoiEnterIndex = -1;
    this.renderState.camera.setTargetRotation(rotationToDirection(
      toBabylonCS(toVector3(this.configuration.points_of_interest[poiIndex].look_at_position))
        .subtract(this.renderState.player.position)
        .normalize()
    ));
  }

  /**
   * Handler for a key event in the context of the city scene.
   *
   * @param info Metadata of a key event.
   */
  private onKeyboard(info: KeyboardInfo): void {
    // Convert the key to our enum.
    if (!Object.keys(SupportedKeyboardKeys).includes(info.event.key)) return;
    const key = info.event.key as SupportedKeyboardKeys;

    switch (info.type) {
    case KeyboardEventTypes.KEYDOWN:
      this.pressKey(key);
      break;
    case KeyboardEventTypes.KEYUP:
      this.releaseKey(key);
      break;
    default:
      break;
    }
  }

  private renderState?: RenderState;
  private configuration: CityConfiguration;

  private initialTrackTarget = 1;
  private currentTrackTarget = -1;
  private lastEmittedPoiEnterIndex = -1;

  private subscribedPoiEnterEventCallbacks: PoiEnterEvent[] = [];
  private subscribedPoiLeaveEventCallbacks: PoiLeaveEvent[] = [];
  private subscribedPoiClickedEventCallbacks: PoiClickedEvent[] = [];
  private autoWalkState: WalkState = WalkState.Stopped;
  private nextCameraType?: CameraType;
  private currentCameraType?: CameraType;

  /**
   * This boolean variable indicates that the rotation of the camera was overwritten.
   * Overwriting the rotation means that it should not be used as an animation target.
   * Overwrite the rotation by calling MultiCamera.setRotationOfCameraType().
   */
  private overwrittenCameraRotationForStreet = false;

  /**
   * This boolean variable indicates that the camera is being rotated at the moment.
   * Note that this means a rotation only and not a transition where the camera is also rotating.
   */
  private runningCameraRotation = false;

  /**
   * This boolean variable indicates that the camera type was at least once changed to
   * CameraType.Street.
   */
  private wasOnceStreetCamera = false;

  private clickedPoiIndex = -1;
  private miniMapOffset: Vector2 = new Vector2(0);
  private miniMapSize: Vector2 = new Vector2(1);
  private miniMapScale = 1.0;
  private miniMapRotation = 0.0;

  /**
   * Function that is called whenever the city changes the camera type.
   */
  private cameraTypeCallback?: CameraTypeChangeCallback;

  /**
   * Set of all keys that have been pressed and where nothing reacted to it yet.
   * Anything that uses one of the pressed keys should remove it afterwards.
   * See `render()` for an example.
   */
  private pressedUnhandledKeys = new Set<SupportedKeyboardKeys>();
}
