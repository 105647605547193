/**
 * SideBar Content
 * Content which displays the sample Content for one POI.
 */

import * as React from "react";
import { BreakpointContext } from "~src/App";
import { BREAKPOINTS } from "~src/logic/enums/enums";

// Components
import SideBarHeadline from "~Components/SideBarHeadline/SideBarHeadline";
import SideBarTopLine from "~Components/SideBarTopLine/SideBarTopLine";
import SideBarText from "~Components/SideBarText/SideBarText";
import SideBarImage from "~Components/SideBarImage/SideBarImage";
import SideBarPoi, { SideBarPoiProperties } from "~src/contentComponents/common/SideBarPoi";

// Stylesheets
import "./SideBarPoiSix.scss";

// Images
import HeaderBackground from "~Assets/images/Chaussee/poi_6/Chaussee_Heroshot_06.jpg";
import Lademanagement from "~Assets/images/Chaussee/poi_6/POI_06_01.gif";
import POIIcon from "~Assets/images/icons/POI_small06.svg";

export default class SideBarPoiSix extends SideBarPoi {
  constructor(props: SideBarPoiProperties) {
    super(props);
  }

  render() {
    return (
      <div className={`sideBarPoiSix ${ this.props.className || "" }`} style={{ backgroundImage: `url(${HeaderBackground})` }}>
        <div className="poiContentWrap">
          <div className="poiContent">
            <div className="poiContent__inner">
              <SideBarTopLine>
                Das NETZlabor E-Mobility-Chaussee
              </SideBarTopLine>
              <SideBarHeadline
                icon={POIIcon}
                className='smallMargin'
              >
                Lösungsansatz Lademanagement: Stabile Steuerung bei garantierter Verfügbarkeit
              </SideBarHeadline>
              <BreakpointContext.Consumer>
                {value =>
                  <>
                    {value !== BREAKPOINTS.LG && (
                      <SideBarImage
                        src={HeaderBackground}
                      />
                    )}
                  </>
                }
              </BreakpointContext.Consumer>
              <SideBarText
                color="#000"
              >
                Beim Lademanagement werden Ladevorgänge entweder mittels vorgegebenen Fahrplänen (statisch) oder Echtzeit-Messwerten (dynamisch) aus dem Stromnetz gesteuert. Anders als bei den statischen Varianten, die täglich im vorgegebenen Rhythmus und unabhängig von der Netzsituation Steuerungs- und Freigabebefehle ausführen, greift das dynamische Lademanagement nur ein, wenn es die Netzsituation erfordert. Hierbei wird die Ladeleistung der Wallboxen dynamisch (stufenweise) an die Netzzustandsdaten angepasst und wird entsprechend erhöht oder reduziert. Dieses System ermöglicht dadurch eine gezielte Steuerung der Netzauslastung und greift nur dann in die Ladeleistung der Kund*innen ein, wenn die Netzsituation es erfordert.
                <br /><br />
                Statische Betriebsvarianten erzielen im Praxistest eine im Schnitt um ca. 30 % reduzierte Auslastung des Stromnetzes. Das dynamische Lademanagement verhindert zuverlässig das Auftreten von punktuellen Lastspitzen und stellt den Kund*innen dennoch zwischen 85 und 95 % der Zeit die volle Ladeleistung von 22 kW zur Verfügung. Trotz dieser geringen Einschränkung ermöglicht die netzdienliche Steuerung von Ladevorgängen durch dynamisches Lademanagement die Integration von beinahe doppelt so vielen E-Fahrzeugen in das bestehende Stromnetz als ohne Einsatz eines technischen Lösungsansatzes.
              </SideBarText>
              <SideBarImage
                src={Lademanagement}
                setPopoutImage={this.props.setPopoutImage}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}
