/**
 * SideBar Content
 * Content which displays the sample Content for one POI.
 */

import * as React from "react";
import { BreakpointContext } from "~src/App";
import { BREAKPOINTS } from "~src/logic/enums/enums";

// Components
import SideBarHeadline from "~Components/SideBarHeadline/SideBarHeadline";
import SideBarTopLine from "~Components/SideBarTopLine/SideBarTopLine";
import SideBarText from "~Components/SideBarText/SideBarText";
import SideBarImage from "~Components/SideBarImage/SideBarImage";
import SideBarPoi, { SideBarPoiProperties } from "~src/contentComponents/common/SideBarPoi";

// Stylesheets
import "./SideBarPoiSeven.scss";

// Images
import HeaderBackground from "~Assets/images/Chaussee/poi_7/Chaussee_Heroshot_07.jpg";
import POIIcon from "~Assets/images/icons/POI_small07.svg";
import CableDistributor from "~Assets/images/Chaussee/poi_7/POI_07_01.jpg";

export default class SideBarPoiSeven extends SideBarPoi {
  constructor(props: SideBarPoiProperties) {
    super(props);
  }

  render() {
    return (
      <div className={`sideBarPoiSeven ${ this.props.className || "" }`} style={{ backgroundImage: `url(${HeaderBackground})` }}>
        <div className="poiContentWrap">
          <div className="poiContent">
            <div className="poiContent__inner">
              <SideBarTopLine>
                Das NETZlabor E-Mobility-Chaussee
              </SideBarTopLine>
              <SideBarHeadline
                icon={POIIcon}
                className='smallMargin'
              >
                Der Kabelverteilerschrank
              </SideBarHeadline>
              <BreakpointContext.Consumer>
                {value =>
                  <>
                    {value !== BREAKPOINTS.LG && (
                      <SideBarImage
                        src={HeaderBackground}
                      />
                    )}
                  </>
                }
              </BreakpointContext.Consumer>
              <SideBarText
                color="#000"
              >
                Ein Kabelverteilerschrank ist ein Knotenpunkt im Niederspannungsnetz. Durch seine exponierte Lage am Ende der Römerstraße bietet es sich an, hier Spannungswerte aus dem Stromnetz zu erfassen. Der zu erwartende Spannungsfall ist an diesem stationsfernsten Messpunkt im NETZlabor am größten. Die gesammelten Messwerte bilden die Datengrundlage für viele Testphasen des Feldversuchs.
              </SideBarText>
              <SideBarImage
                src={CableDistributor}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}
