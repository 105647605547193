import React from "react";
import { CSSTransition } from "react-transition-group";
import Scrollbars from "react-custom-scrollbars-2";

import BackgroundOverlay from "~Components/BackgroundOverlay/BackgroundOverlay";
import Header from "~Components/Header/Header";
import ImagePopout from "~Components/ImagePopout/ImagePopout";
import NavigationButton from "~Components/NavigationButton/NavigationButton";
import { RenderState, Scene3D } from "~Components/Scene3D/Scene3D";
import Sidebar from "~Components/Sidebar/Sidebar";
import ToggleButton from "~Components/ToggleButton/ToggleButton";
import SideBarPoi from "~src/contentComponents/common/SideBarPoi";
import { BREAKPOINTS, POI } from "~src/logic/enums/enums";
import { CameraType } from "~src/rendering/multiCamera";
import { CityConfiguration } from "~src/logic/io/json/city_configuration";
import CityScene, { MapConfig } from "~src/scenes/cityScene";
import BaseIntro from "~src/contentComponents/common/BaseIntro";
import BaseStepContainer from "~src/contentComponents/common/BaseStepContainer";
import IconPersonActive from "~Assets/images/icons/person view_active.svg";
import IconPersonInactive from "~Assets/images/icons/person view_inactive.svg";
import IconMapActive from "~Assets/images/icons/bird view_active.svg";
import IconMapInactive from "~Assets/images/icons/bird view_inactive.svg";
import { BreakpointContext } from "~src/App";

export interface Properties {
  breakpoint: BREAKPOINTS;
}

type StateProps = {
  overlay: boolean;
  showIntro: boolean;
  showStepContainer: boolean;
  sidebarOpen: boolean;
  sidebarContent: POI;
  rendering: RenderState;
  popoutImage: string;
  showPopoutImage: boolean;
}

type PoiListRenderFunction = { (): JSX.Element };

export default class BaseRoute extends React.Component<Properties, StateProps> {
  constructor(
    props: Properties,
    renderPoiList: PoiListRenderFunction,
    pointsOfInterest: (typeof SideBarPoi)[],
    name: string,
    cityModelUrl: string,
    cityConfiguration: CityConfiguration,
    miniMapConfig: MapConfig,
    backgroundOverlay: string,
    intro: (typeof BaseIntro),
    stepContainer: (typeof BaseStepContainer),
  ) {
    super(props);

    this.pointsOfInterest = pointsOfInterest;
    this.renderPoiList = renderPoiList.bind(this);
    this.name = name;
    this.cityModelUrl = cityModelUrl;
    this.cityConfiguration = cityConfiguration;
    this.miniMapConfig = miniMapConfig;
    this.backgroundOverlay = backgroundOverlay;
    this.intro = intro;
    this.stepContainer = stepContainer;

    this.state = {
      overlay: true,
      showIntro: true,
      showStepContainer: false,
      sidebarOpen: false,
      sidebarContent: POI.POIOne,
      rendering: {
        view: CameraType.BIRD,
        targetedPoiIndex: -1
      },
      popoutImage: "",
      showPopoutImage: false
    };

    this.scene = React.createRef();
    this.scrollbar = React.createRef();

    this.changeScreenState = this.changeScreenState.bind(this);
    this.hideIntro = this.hideIntro.bind(this);
    this.hideStepContainer = this.hideStepContainer.bind(this);
    this.setView = this.setView.bind(this);
    this.goToPoi = this.goToPoi.bind(this);
  }

  componentDidUpdate(prevProps: Properties, prevState: StateProps) {
    if(this.state.sidebarContent !== prevState.sidebarContent) {
      this.scrollbar.current?.scrollTop(0);
    }
  }

  protected hideIntro(): void {
    this.setState({showIntro: false});
  }

  protected hideStepContainer(): void {
    this.setState({showStepContainer: false, overlay: false});
  }

  protected changeScreenState(state: string): void {
    if(state === "tutorial") {
      this.setState({showStepContainer: true});
    }
  }

  /**
   * Sets the camera to the given type.
   */
  protected setView(type: CameraType): void {
    this.setState({
      rendering: {
        ...this.state.rendering,
        view: type,
      }
    });
  }

  /**
   * Event triggered function passing the given POI index to the rendering logic.
   */
  protected goToPoi(index: number): void {
    if (index >= 0 && index < this.pointsOfInterest.length) {
      this.setState({
        sidebarOpen: false,
        rendering: {
          view: CameraType.STREET,
          targetedPoiIndex: index
        },
        sidebarContent: index + 1
      });
    }
  }

  /**
   * Retrieves the current sidebar content that depends on its state.
   *
   * @returns Component with the current sidebar content.
   */
  protected renderSidebarContent(): React.ReactElement {
    const poiIndex = this.state.sidebarContent - 1;

    if (poiIndex in this.pointsOfInterest) {
      const setPopoutImage = (_event: React.MouseEvent, image: string) => {
        this.setState({showPopoutImage: true, popoutImage: image});
      };

      return React.createElement(
        this.pointsOfInterest[poiIndex],
        { setPopoutImage: setPopoutImage},
      );
    }
    return this.renderPoiList();
  }

  public render() {
    return (
      <div className={this.name}>
        <Header
          openPOIList={() => this.setState({sidebarOpen: true, sidebarContent: POI.POILIST})}
          openIntro={() => this.setState({overlay: true, showStepContainer: true})}
          overlayOpen={this.state.overlay}
        />
        <BreakpointContext.Provider value={this.props.breakpoint}>
          <Scene3D
            breakpoint={this.props.breakpoint}
            rendering={this.state.rendering}
            ref={this.scene}
            onPoiEnter={(index) => {
              if (index < 0 || index >= this.pointsOfInterest.length) return;
              this.setState({ sidebarOpen: true, sidebarContent: index + 1});
            }}
            onPoiLeave={() => this.setState({sidebarOpen: false})}
            onPoiClicked={this.goToPoi}
            cityModelUrl={this.cityModelUrl}
            cityConfiguration={this.cityConfiguration}
            miniMapConfig={this.miniMapConfig}
            setCameraView={(type: CameraType) => this.setView(type)}
          />
        </BreakpointContext.Provider>
        <CSSTransition
          in={this.state.overlay}
          timeout={500}
          classNames='fade'
          unmountOnExit={true}
          onExited={() => this.changeScreenState("tutorial")}
        >
          <BackgroundOverlay
            backgroundImage={this.backgroundOverlay}
          >
            <CSSTransition
              in={this.state.showIntro}
              timeout={500}
              classNames='fade'
              unmountOnExit={true}
              onExited={() => this.changeScreenState("tutorial")}
            >
              {React.createElement(this.intro, {onToggle: this.hideIntro})}
            </CSSTransition>
            <CSSTransition
              in={this.state.showStepContainer}
              timeout={500}
              classNames='fade'
              unmountOnExit={true}
            >
              {React.createElement(this.stepContainer, {closeContainer: this.hideStepContainer})}
            </CSSTransition>
          </BackgroundOverlay>
        </CSSTransition>
        <Sidebar
          sidebarOpen={this.state.sidebarOpen}
          closeSidebar={() => this.setState({sidebarOpen: false})}
        >
          <Scrollbars
            ref={this.scrollbar}
            style={{ height: "100vh" }}
            autoHeight
            autoHeightMin="100%"
            autoHeightMax="100%"
          >
            { this.renderSidebarContent() }
          </Scrollbars>
        </Sidebar>
        {!this.state.overlay && (
          <>
            <NavigationButton
              disable={this.state.rendering.view === CameraType.BIRD}
              onForward={{
                onStart: () =>
                  this.scene.current?.city?.pressKey(CityScene.SupportedKeyboardKeys.ArrowUp),
                onStop: () =>
                  this.scene.current?.city?.releaseKey(CityScene.SupportedKeyboardKeys.ArrowUp)
              }}
              onBackward={{
                onStart: () =>
                  this.scene.current?.city?.pressKey(CityScene.SupportedKeyboardKeys.ArrowDown),
                onStop: () =>
                  this.scene.current?.city?.releaseKey(CityScene.SupportedKeyboardKeys.ArrowDown)
              }}
            />
            <ToggleButton
              className="toggleView"
              iconOnActive={IconPersonActive}
              iconOnInactive={IconPersonInactive}
              iconOffActive={IconMapActive}
              iconOffInactive={IconMapInactive}
              onOnState={() => this.setView(CameraType.STREET)}
              onOffState={() => this.setView(CameraType.BIRD)}
              activeState={this.state.rendering.view === CameraType.STREET}
            />
          </>
        )}
        <CSSTransition
          in={this.state.showPopoutImage}
          timeout={500}
          classNames='fade'
          unmountOnExit={true}
          onExited={() => this.setState({popoutImage: ""})}
        >
          <ImagePopout
            image={this.state.popoutImage}
            closePopout={() => this.setState({showPopoutImage: false})}
          />
        </CSSTransition>
      </div>
    );
  }

  private pointsOfInterest: (typeof SideBarPoi)[];
  private name: string;
  private cityModelUrl: string;
  private cityConfiguration: CityConfiguration;
  private miniMapConfig: MapConfig;
  private backgroundOverlay: string;
  private intro: (typeof BaseIntro);
  private stepContainer: (typeof BaseStepContainer);

  protected scene: React.RefObject<Scene3D>;
  protected scrollbar: React.RefObject<Scrollbars>;
  protected renderPoiList: PoiListRenderFunction;
}
