/**
 * SideBar Content
 * Content which displays the sample Content for one POI.
 */

import * as React from "react";
import { BreakpointContext } from "~src/App";
import { BREAKPOINTS } from "~src/logic/enums/enums";

// Components
import SideBarHeadline from "~Components/SideBarHeadline/SideBarHeadline";
import SideBarTopLine from "~Components/SideBarTopLine/SideBarTopLine";
import SideBarText from "~Components/SideBarText/SideBarText";
import SideBarImage from "~Components/SideBarImage/SideBarImage";
import SideBarPoi, { SideBarPoiProperties } from "~src/contentComponents/common/SideBarPoi";

// Stylesheets
import "./SideBarPoiThree.scss";

// Images
import HeaderBackground from "~Assets/images/Carre/POI_3/Carre_Heroshot_03.png";
import POIIcon from "~Assets/images/icons/POI_small03.svg";
import ImageOne from "~Assets/images/Carre/POI_3/POI_3_01.jpg";

export default class SideBarPoiThree extends SideBarPoi {
  constructor(props: SideBarPoiProperties) {
    super(props);
  }

  render() {
    return (
      <div className={`sideBarPoiThree ${ this.props.className || "" }`} style={{ backgroundImage: `url(${HeaderBackground})` }}>
        <div className="poiContentWrap">
          <div className="poiContent">
            <div className="poiContent__inner">
              <SideBarTopLine>
                Das NETZlabor E-Mobility-Carré
              </SideBarTopLine>
              <SideBarHeadline
                icon={POIIcon}
                className='smallMargin'
              >
                Erhöhter Leistungsbedarf durch Elektrifizierung: Natürliches Ladeverhalten und dessen Auswirkungen auf das Stromnetz
              </SideBarHeadline>
              <BreakpointContext.Consumer>
                {value =>
                  <>
                    {value !== BREAKPOINTS.LG && (
                      <SideBarImage
                        src={HeaderBackground}
                      />
                    )}
                  </>
                }
              </BreakpointContext.Consumer>
              <SideBarText
                color="#000"
              >
                Zusätzlich zu den üblichen Haushaltsverbrauchern wie Backofen, Herd oder Waschmaschine wirkt sich das Laden von E-Fahrzeugen stärker auf das Stromnetz aus. Bedeutet in der Praxis: Zu Zeiten, in denen alle oder die meisten Projektteilnehmer*innen ihre E-Fahrzeuge zur gleichen Zeit laden möchten, kann sich der Leistungsbedarf schnell aufsummieren und es können Belastungsspitzen am Hausanschluss und im Stromnetz entstehen. Um eine Grundlage für die anstehende Testphase und die Entwicklung von technischen Lösungsansätzen zur Verbesserung des Netzzustandes zu schaffen, wird daher zu Beginn des Projekts erst einmal das natürliche Lade- und Nutzungsverhalten der E-Pionier*innen ohne Einsatz von technischen Komponenten wie Lademanagementsystemen oder Batteriespeicher betrachtet. Hierbei ist im NETZlabor zu beobachten, dass abends zwischen 18 und 22 Uhr die meisten Ladevorgänge mit einer durchschnittlichen Ladedauer von 2,5 Stunden stattfinden. Die maximale Gleichzeitigkeit liegt bei 13 ladenden E-Autos bei insgesamt 58 Ladepunkten (= 22,4 %). In über 42 % der Zeit lädt kein E-Fahrzeug, die befürchtete maximale Gleichzeitigkeit bleibt aus.
              </SideBarText>
              <SideBarImage
                src={ImageOne}
                setPopoutImage={this.props.setPopoutImage}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}
