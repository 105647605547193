/**
 * SideBar Content
 * Content which displays the sample Content for one POI.
 */

import * as React from "react";
import { BreakpointContext } from "~src/App";
import { BREAKPOINTS } from "~src/logic/enums/enums";

// Components
import SideBarHeadline from "~Components/SideBarHeadline/SideBarHeadline";
import SideBarTopLine from "~Components/SideBarTopLine/SideBarTopLine";
import SideBarText from "~Components/SideBarText/SideBarText";
import SideBarImage from "~Components/SideBarImage/SideBarImage";
import SideBarPoi, { SideBarPoiProperties } from "~src/contentComponents/common/SideBarPoi";

// Stylesheets
import "./SideBarPoiOne.scss";
import "~src/logic/scss/Animations.scss";

// Images
import HeaderBackground from "~Assets/images/Carre/POI_1/Carre_Heroshot_01.png";
import POIIcon from "~Assets/images/icons/POI_small01.svg";
import ImageOne from "~Assets/images/Carre/POI_1/POI_1_01.png";

export default class SideBarPoiOne extends SideBarPoi {
  constructor(props: SideBarPoiProperties) {
    super(props);
  }

  render() {
    return (
      <div className={`sideBarPoiOne ${ this.props.className || "" }`} style={{ backgroundImage: `url(${HeaderBackground})` }}>
        <div className="poiContentWrap">
          <div className="poiContent">
            <div className="poiContent__inner">
              <SideBarTopLine>
                Das NETZlabor E-Mobility-Carré
              </SideBarTopLine>
              <SideBarHeadline
                icon={POIIcon}
                className='smallMargin'
              >
                16 Monate unter Strom
              </SideBarHeadline>
              <BreakpointContext.Consumer>
                {value =>
                  <>
                    {value !== BREAKPOINTS.LG && (
                      <SideBarImage
                        src={HeaderBackground}
                      />
                    )}
                  </>
                }
              </BreakpointContext.Consumer>
              <SideBarText
                color="#000"
              >
                Bei der Installation der Ladeinfrastruktur und den für den Aufbau benötigten technischen Komponenten ist die praxisnahe Planung sehr wichtig: der vollständige Aufbau der Ladeinfrastruktur erfolgt nach einem Prinzip, das so in jedem herkömmlichen Mehrfamilienhaus durchführbar ist. Um Daten und Ergebnisse zu speziell eingesetzten technischen Lösungen und dem Ladeverhalten zu gewinnen, wird für das NETZlabor zusätzliche Messtechnik installiert. Insgesamt werden bei den Installationsarbeiten im E-Mobility-Carré 11 km Kabel verlegt, so dass für alle technischen Einheiten und Ladepunkte jederzeit ausreichend Strom und Datensignale fließen können.
                <br /><br />
                Die Netztopologie am Standort ist ebenfalls ideal beschaffen: Das Stromnetz ist ausreichend dimensioniert und verfügt über genügend Restkapazitäten, um die E-Fahrzeuge des Feldversuchs optimal mit Strom versorgen zu können. Zudem bietet die nahegelegene Umspannstation mit Transformator die Möglichkeit, einen separaten Netzanschluss zu legen. Am Transformator, welcher die Spannung von Mittelspannung (20.000 V) auf Niederspannung (400 V) umwandelt, ist ausreichend Leistungsreserve vorhanden, so dass die Betriebsmittel hier nicht verstärkt werden müssen.
              </SideBarText>
              <SideBarImage
                src={ImageOne}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}
