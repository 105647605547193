import { Color3, Engine, IPointerEvent, Mesh, MeshBuilder, PickingInfo, Quaternion, Scene, Texture, TransformNode, Vector3 } from "@babylonjs/core";
import { CityConfiguration } from "~src/logic/io/json/city_configuration";

import poiTextureOne from  "~Assets/images/icons/POI_big_01 _noshadow.svg";
import poiTextureTwo from  "~Assets/images/icons/POI_big_02 _noshadow.svg";
import poiTextureThree from  "~Assets/images/icons/POI_big_03 _noshadow.svg";
import poiTextureFour from  "~Assets/images/icons/POI_big_04 _noshadow.svg";
import poiTextureFive from  "~Assets/images/icons/POI_big_05 _noshadow.svg";
import poiTextureSix from  "~Assets/images/icons/POI_big_06 _noshadow.svg";
import poiTextureSeven from  "~Assets/images/icons/POI_big_07 _noshadow.svg";
import poiTextureEight from  "~Assets/images/icons/POI_big_08 _noshadow.svg";
import poiTextureNine from  "~Assets/images/icons/POI_big_09 _noshadow.svg";
import poiTextureTen from  "~Assets/images/icons/POI_big_10_noshadow.svg";
import { PoiShaderMaterial } from "~src/rendering/poiShaderMaterial";
import { rotationToDirection, toBabylonCS, toVector3 } from "~src/rendering/utility";
import { CameraType, MultiCamera } from "~src/rendering/multiCamera";
import { BREAKPOINTS } from "~src/logic/enums/enums";

const POI_IMAGE_WIDTH = 0.5;
const POI_IMAGE_HIGHLIGHT_COLOR = new Color3(1.0, 0.6, 0.0);

async function loadImage(path: string): Promise<HTMLImageElement> {
  return new Promise((resolve, reject) => {
    const img = new Image();
    img.src = path;
    img.onload = () => {
      resolve(img);
    };
    img.onerror = e => {
      reject(e);
    };
  });
}

export interface PoiClickedEvent { (poiIndex: number): void }
export type MeshClickCallback = { (): void };
type MeshName = string;

export default class PoiOverlay {
  constructor(engine: Engine, configuration: CityConfiguration) {
    this.scene = new Scene(engine);
    this.scene.autoClear = false;
    this.configuration = configuration;
  }

  public async create(poiClickedEvent: PoiClickedEvent) {
    const poiTextures = [
      new Texture(poiTextureOne, this.scene),
      new Texture(poiTextureTwo, this.scene),
      new Texture(poiTextureThree, this.scene),
      new Texture(poiTextureFour, this.scene),
      new Texture(poiTextureFive, this.scene),
      new Texture(poiTextureSix, this.scene),
      new Texture(poiTextureSeven, this.scene),
      new Texture(poiTextureEight, this.scene),
      new Texture(poiTextureNine, this.scene),
      new Texture(poiTextureTen, this.scene),
    ];

    // Enable alpha for all poi textures.
    for (const texture of poiTextures) {
      texture.hasAlpha = true;
    }

    const poiImage = await loadImage(poiTextureOne);
    this.poiMeshes = [];
    const poiIndices: number[] = [];
    let count = 0;
    const poiWidth = POI_IMAGE_WIDTH;
    for (const poi of this.configuration.points_of_interest) {
      const poiMaterial = new PoiShaderMaterial("poi", this.scene);
      poiMaterial.setTexture("texture1", poiTextures[count]);
      poiMaterial.setColor3("highlightColor", POI_IMAGE_HIGHLIGHT_COLOR);
      poiIndices.push(poi.location_index);

      const name = `POI #${poi.id}: ${poi.name}`;
      const poiMesh = MeshBuilder.CreatePlane(
        name,
        {
          width: poiWidth,
          height: poiWidth * (poiImage.height / poiImage.width),
          sideOrientation: Mesh.DOUBLESIDE
        },
        this.scene,
      );
      poiMesh.material = poiMaterial;
      poiMesh.position = toBabylonCS(toVector3(poi.image_position));
      poiMesh.enablePointerMoveEvents = true;

      this.poiMeshes.push(poiMesh);
      this.poiBirdOffsetNodes.push(new TransformNode(name+"OffsetNode", this.scene));

      // Copy the count variable, otherwise the pointer down callback will reference current
      // value of it.
      const poiIndex = count;
      this.registerMeshForPointerDownEvent(poiMesh, () => {
        poiClickedEvent(poiIndex);
      });

      count ++;
    }

    // Listen for pick events on meshes.
    this.scene.onPointerDown = (_: IPointerEvent, pickResult: PickingInfo) => {
      this.hoveredMesh = undefined;
      if (pickResult.hit && pickResult.pickedMesh) {
        const name = pickResult.pickedMesh.name;
        const callback = this.meshPointerDownEventCallbacks.get(name);
        if (callback) callback();
      }
    };

    const rootDiv = document.getElementById("root");
    this.scene.onPointerMove = (_: IPointerEvent, pickResult: PickingInfo) => {
      if (pickResult.pickedMesh) {
        if (rootDiv) rootDiv.style.cursor = "pointer";
        this.hoveredMesh = pickResult.pickedMesh.name;
      } else {
        if (rootDiv) rootDiv.style.cursor = "default";
        this.hoveredMesh = undefined;
      }
    };
  }

  public render(
    breakpoint: BREAKPOINTS, camera: MultiCamera, currentPoi: number, playerPosition: Vector3
  ) {
    const cameraType = camera.getCurrentCameraType();

    const deviceSizeScaling = (() => {
      switch(breakpoint) {
      case BREAKPOINTS.LG:
        return 1.0;
      case BREAKPOINTS.MD:
        return 1.5;
      case BREAKPOINTS.SM:
        return 2.0;
      default:
        return 1.0;
      }
    })();

    for (const mesh of this.poiMeshes) {
      const material = mesh.material as PoiShaderMaterial;
      material.setInt("isPicked", 0);
    }

    const numPois = this.configuration.points_of_interest.length;
    const distancesToCamera: number[] = [];
    let maxCameraDistance = 0.0;
    const cameraPosition = camera.getCurrentCameraPosition();
    for (let poiIndex = 0; poiIndex < numPois; poiIndex++) {
      const poiMesh = this.poiMeshes[poiIndex];
      const distance = poiMesh.position.subtract(cameraPosition).length();

      maxCameraDistance = Math.max(distance, maxCameraDistance);
      distancesToCamera.push(distance);
    }

    for (let poiIndex = 0; poiIndex < numPois; poiIndex++) {
      const poi = this.configuration.points_of_interest[poiIndex];
      const poiMesh = this.poiMeshes[poiIndex];

      const distanceToCamera = distancesToCamera[poiIndex];
      const distanceToPlayer = poiMesh.position.subtract(playerPosition).length();

      // TODO this hack can be avoided by drawing the street pois in the same scene as the rest of
      // the world so that normal occlusion occurs.
      poiMesh.visibility = (
        (Math.abs(currentPoi - poiIndex) < 2 && cameraType === CameraType.STREET) ||
        poiIndex < 2 && currentPoi < 0 ||
        distanceToPlayer < 10.0 ||
        cameraType !== CameraType.STREET
      ) ? 1 : 0;

      const lookAtPosition = toBabylonCS(toVector3(poi.image_position));
      if (cameraType === CameraType.STREET) {
        const scale = 1.0;
        poiMesh.parent = null;
        poiMesh.scaling = new Vector3(scale,scale,scale);
        poiMesh.position = lookAtPosition;
        poiMesh.rotationQuaternion = rotationToDirection(
          poiMesh.position.subtract(playerPosition).normalize()
        );
      } else {
        // Use this node to position the poi images above the look at position.
        const offsetNode = this.poiBirdOffsetNodes[poiIndex];
        offsetNode.position = lookAtPosition;
        offsetNode.rotationQuaternion = camera.getCurrentCameraRotation();

        const scale =
          deviceSizeScaling * this.configuration.global_point_of_interest_image_scale *
          poi.image_scale * (distanceToCamera/maxCameraDistance);
        poiMesh.scaling = new Vector3(scale,scale,scale);
        poiMesh.parent = offsetNode;
        poiMesh.position = new Vector3(0, POI_IMAGE_WIDTH * scale/2.0 , 0);
        poiMesh.rotationQuaternion = new Quaternion();
      }
    }

    if (this.hoveredMesh) {
      const mesh = this.scene.getMeshByName(this.hoveredMesh);
      if (mesh) {
        const material = mesh.material as PoiShaderMaterial;
        material.setInt("isPicked", 1);
      }
    }

    this.scene.render();
  }

  /**
     * List of callbacks that will be called everytime a mesh,
     * identified by its name,
     * was clicked on.
     */
  private meshPointerDownEventCallbacks = new Map<MeshName, MeshClickCallback>();

  /**
     * Subscribe a callback function for a mesh that should be called when the mesh was clicked on.
     * The name of the mesh should be unique, only one callback will be stored for each unique name.
     *
     * @param mesh Mesh the callback should be registered for.
     * @param callback Function that is called when the given mesh was clicked on.
     */
  private registerMeshForPointerDownEvent(mesh: Mesh, callback: MeshClickCallback): void {
    if (this.meshPointerDownEventCallbacks.has(mesh.name)) {
      console.error(
        `There is already a pointer down callback registered for the mesh "${mesh.name}".`
      );
      return;
    }
    this.meshPointerDownEventCallbacks.set(mesh.name, callback);
  }

  private scene: Scene;
  private configuration: CityConfiguration;
  private poiMeshes: Mesh[] = [];
  private poiBirdOffsetNodes: TransformNode[] = [];
  private hoveredMesh: string | undefined = undefined;
}
