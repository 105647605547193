import { Scene, ShaderMaterial, Texture, TextureSampler } from "@babylonjs/core";

import fragmentShader from "~Shaders/poiFragment.fx";
import vertexShader from "~Shaders/poiVertex.fx";

export class PoiShaderMaterial extends ShaderMaterial {
  constructor(name: string, scene: Scene) {
    super(name, scene, {
      vertexSource: vertexShader,
      fragmentSource: fragmentShader,
      attributes: ["position", "uv"],
      uniforms: ["worldViewProjection", "isPicked", "highlightColor"],
      samplers: ["texture1"],
      needAlphaBlending: true,
      needAlphaTesting: true,
    });
    this.alpha = 0.5;
  }
}
