// Stylesheets
import "./Nihl.scss";
import "~src/logic/scss/Animations.scss";

import * as React from "react";

// Components
import Intro from "~NihlContent/Intro/Intro";
import StepContainer from "~NihlContent/StepContainer/StepContainer";
import cityModel from "~Assets/glb/Dossenheim - Netzlabor Intelligentes Heimladen.glb";
import cityConfig from "~Assets/configs/Nihl.json";
import miniMap from "~Assets/images/minimaps/Nihl_mini map.svg";
import miniMapConfig from "~Assets/configs/nihl_dossenheim_mapconfig.json";

//images
import backgroundOverlay from "~Assets/images/5 Standorte.jpg";

//Content Components
import POIList from "~NihlContent/PoiList/PoiList";
import SideBarPoiOne from "~NihlContent/SideBarPoiOne/SideBarPoiOne";
import SideBarPoiTwo from "~NihlContent/SideBarPoiTwo/SideBarPoiTwo";
import SideBarPoiThree from "~NihlContent/SideBarPoiThree/SideBarPoiThree";
import SideBarPoiFour from "~NihlContent/SideBarPoiFour/SideBarPoiFour";
import SideBarPoiFive from "~NihlContent/SideBarPoiFive/SideBarPoiFive";
import SideBarPoiSix from "~NihlContent/SideBarPoiSix/SideBarPoiSix";
import { toVector2 } from "~src/rendering/utility";
import BaseRoute, { Properties } from "~src/routes/BaseRoute";

export default class Nihl extends BaseRoute {
  constructor(props: Properties) {
    const renderPoiList = (): JSX.Element =>
      <POIList onPoiSelect={this.goToPoi.bind(this)}/>;

    super(props,
      renderPoiList,
      [
        SideBarPoiOne,
        SideBarPoiTwo,
        SideBarPoiThree,
        SideBarPoiFour,
        SideBarPoiFive,
        SideBarPoiSix,
      ],
      "pageNihl",
      cityModel,
      cityConfig,
      {
        miniMapUrl: miniMap,
        bottomLeft: toVector2(miniMapConfig.bottomLeft),
        bottomRight: toVector2(miniMapConfig.bottomRight),
        topRight: toVector2(miniMapConfig.topRight),
        angle: miniMapConfig.angle,
        scale: miniMapConfig.scale,
      },
      backgroundOverlay,
      Intro,
      StepContainer,
    );
  }
}
