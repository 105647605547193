/**
 * Sidebar Top Line Component
 * Simple component that adds a styled top line to the sidebar.
 */

import * as React from "react";

// Stylesheets
import "./SideBarTopLine.scss";


interface SideBarTopLineProps {
  className?: string;
  color?: string;
  children: React.ReactNode;
}

export default class SideBarTopLine extends React.Component<SideBarTopLineProps> {
  style = {
    color: this.props.color ? this.props.color : undefined
  };

  render() {
    return (
      <div
        className={`sidebarTopLine ${ this.props.className || "" }`}
      >
        <p style={this.style}><b>{this.props.children}</b></p>
      </div>
    );
  }
}
