import { TransformNode } from "@babylonjs/core";
import { Vector3 } from "@babylonjs/core/Maths/math.vector";

import createGraph from "ngraph.graph";
import { Graph } from "ngraph.graph";
import { aStar, PathFinder } from "ngraph.path";

import { toBabylonCS, toVector3 } from "~src/rendering/utility";
import { PointOfInterest } from "~src/logic/io/json/city_configuration";

// Array of points where each point is an array of three numbers representing x,y and z.
export type Path = number[][];

// Converted path where each point is a BabylonJS.Vector3.
type VectorPath = Vector3[];

// Index of a path.
type PathIndex = number;

// Index of a point for a specific path where the index is local to its path.
type LocalPointIndex = number;

// Global index of a point used to access points in the global array of all points from all paths.
type GlobalPointIndex = number;

// A point can be uniquely identified from all points from all paths by the index of the path it
// belongs to and the local index of the point in that path.
type LocalPointIdentifier = {
  pathIndex: PathIndex;
  pointIndex: LocalPointIndex;
};

// Mappings used to store the relations between a point in a path and its global counterpart.
type LocalToGlobalIndexMapping = Map<PathIndex, Map<LocalPointIndex, GlobalPointIndex>>;
type GlobalToLocalIndexMapping = Map<GlobalPointIndex, LocalPointIdentifier>;

type WalkResult = {
  destinationReached: boolean;
  lookAtPoint: Vector3;
}

/**
 * Helper class that takes multiple paths, creates a graph from them and allows
 * navigation along a shortest route from any index on the path to any other index on the path.
 */
class MappedPath {
  constructor(paths: Path[]) {
    this.paths = [];
    this.allPoints = [];

    // Store each path with its points already converted and in the target coordinate system.
    for (const path of paths) {
      const vectorPath: VectorPath = [];
      for (const point of path) {
        const convertedPoint = toBabylonCS(toVector3(point));
        this.allPoints.push(convertedPoint);
        vectorPath.push(convertedPoint);
      }
      this.paths.push(vectorPath);
    }

    const graph: Graph = createGraph<number, number>({
      // There must not be multiple edges connecting the same nodes.
      multigraph: false
    });

    this.globalToLocalIndexMap = new Map();
    this.localToGlobalIndexMap = new Map();
    const startAndEndPoints = new Set<GlobalPointIndex>();

    // Index used to identify a point globally over all paths.
    let globalPointIndex: GlobalPointIndex = 0;
    for (let pathIndex: PathIndex = 0; pathIndex < this.paths.length; pathIndex++) {
      const path = this.paths[pathIndex];
      const mapping = new Map<LocalPointIndex, GlobalPointIndex>();

      for (let pointIndex: LocalPointIndex = 0; pointIndex < path.length; pointIndex++) {
        const calculatedIndex: GlobalPointIndex = globalPointIndex++;
        mapping.set(pointIndex, calculatedIndex);
        this.globalToLocalIndexMap.set(calculatedIndex, {
          pathIndex: pathIndex,
          pointIndex: pointIndex,
        });

        if (pointIndex < path.length - 1) {
          graph.addLink(calculatedIndex, calculatedIndex+1);
        }

        // The index is either at the beginning or at the end of the path
        if (pointIndex === 0 || pointIndex === path.length - 1) {
          startAndEndPoints.add(calculatedIndex);
        }
      }
      this.localToGlobalIndexMap.set(pathIndex, mapping);
    }

    const epsilon = 0.001;
    const epsilonSquared = epsilon * epsilon;
    for (const outerGlobalIndex of startAndEndPoints) {
      const outerPoint = this.getPointFromGlobalIndex(outerGlobalIndex);
      console.assert(outerPoint !== undefined, "A point could not be indexed properly when building the graph.");
      if (outerPoint === undefined) continue;

      for (const innerGlobalIndex of startAndEndPoints) {
        // It does not make sense to connect a node to itself.
        if (outerGlobalIndex === innerGlobalIndex) continue;
        const innerPoint = this.getPointFromGlobalIndex(innerGlobalIndex);
        if (innerPoint === undefined) continue;
        // Using the squared length saves us the use of the (expensive) root function.
        if (outerPoint.subtract(innerPoint).lengthSquared() < epsilonSquared) {
          graph.addLink(outerGlobalIndex, innerGlobalIndex);
        }
      }
    }

    this.shortestPathFinder = aStar(
      graph,
      { oriented: false, quitFast: false }
    );
  }

  /**
   * Converts a global point index into its local identifier made up by a path and a point index.
   *
   * @returns The local point identifier if the index can be mapped, undefined otherwise.
   */
  public toLocal(globalIndex: GlobalPointIndex): LocalPointIdentifier | undefined {
    return this.globalToLocalIndexMap.get(globalIndex);
  }

  /**
   * Converts a path and a point index into their global point index.
   *
   * @returns The global point index if the identifier can be mapped, undefined otherwise.
   */
  public toGlobal(localIdentifier: LocalPointIdentifier): GlobalPointIndex | undefined {
    const result = this.localToGlobalIndexMap.get(localIdentifier.pathIndex)?.get(localIdentifier.pointIndex);
    if (result === undefined || result < 0 || result >= this.allPoints.length) return undefined;
    return result;
  }

  /**
   * Gets the total number of all points in all paths.
   */
  public numPoints(): number {
    return this.allPoints.length;
  }

  /**
   * Finds the path and the point that is closest to the given position.
   *
   * @returns The index of the closest path as well as the local and global index of the closest
   *          point. The point is guarantueed to be a member of the resulting path.
   */
  public closestPath(position: Vector3): [PathIndex, LocalPointIndex, GlobalPointIndex] {
    // This function is a linear search over all points in all paths.
    // The computational cost of this operation should be okay for now,
    // but if anything has to be optimized this is the first place where one should look.
    let minDistanceSquared = this.allPoints[0].subtract(position).lengthSquared();
    let closestPathIndex: PathIndex = 0;
    let closestPointIndex: LocalPointIndex = 0;

    for (let pathIndex = 0; pathIndex < this.paths.length; pathIndex++) {
      const path = this.paths[pathIndex];
      for (let pointIndex = 0; pointIndex < path.length; pointIndex++) {
        const distanceSquared = path[pointIndex].subtract(position).lengthSquared();
        if (distanceSquared < minDistanceSquared) {
          closestPathIndex = pathIndex;
          closestPointIndex = pointIndex;
          minDistanceSquared = distanceSquared;
        }
      }
    }
    return [
      closestPathIndex,
      closestPointIndex,
      this.toGlobal({ pathIndex: closestPathIndex, pointIndex: closestPointIndex}) ?? - 1
    ];
  }

  /**
   * Computes the shortest path between the two given indices.
   *
   * @param from Global point index at the start of the path.
   * @param to Global point index at the end of the path.
   * @returns A list of global point indices representing the shortest path.
   */
  public shortestPath(
    from: GlobalPointIndex,
    to: GlobalPointIndex
  ): GlobalPointIndex[] {
    const path: GlobalPointIndex[] = [];
    // It seems that the library returns the path in the wrong order.
    const foundpath = this.shortestPathFinder.find(to, from);

    if (foundpath.length <= 0) {
      console.error("Failed to calculate a shortest path from %d to %d", from, to);
      return [];
    }

    for (const node of foundpath) {
      path.push(+node.id);
    }

    return path;
  }

  /**
   * Gets the coordinates of a point by its global index.
   *
   * @param globalIndex Global point index.
   * @returns The point referenced by its index or undefined if the index is invalid.
   */
  private getPointFromGlobalIndex(
    globalIndex: GlobalPointIndex
  ): Vector3 | undefined {
    const localIdentifier = this.toLocal(globalIndex);
    if (!localIdentifier) return undefined;
    if (!(localIdentifier.pathIndex in this.paths)) return undefined;
    if (!(localIdentifier.pointIndex in this.paths[localIdentifier.pathIndex])) return undefined;
    return this.paths[localIdentifier.pathIndex][localIdentifier.pointIndex];
  }

  private paths: VectorPath[];

  // Mapping from a local point index in a path to the global index in allPoints.
  private localToGlobalIndexMap: LocalToGlobalIndexMapping;

  // Mapping from a global point index in allPoints to a path and local point index.
  private globalToLocalIndexMap: GlobalToLocalIndexMapping;

  // Object used to find the closest path in the graph created from the given paths.
  private shortestPathFinder: PathFinder<any>;

  // All points from all paths.
  // The index maps should be used to reference these points.
  public allPoints: Vector3[];
}

/**
 * The Track interpolates the position of a scene node along a given path.
 */
export class Track {

  /**
   * c'tor
   *
   * @param paths List of paths that make up the road network.
   * @param controlledNode Scene node that will be interpolated along the path.
   */
  constructor(paths: Path[], controlledNode: TransformNode, placeAtPoi: PointOfInterest) {
    this.controlledNode = controlledNode;

    // Creating the mapped path will also create the graph used to calculate the shortest path.
    // See MappedPath for more details.
    this.mappedPath = new MappedPath(paths);

    if (this.mappedPath.numPoints() < 2) {
      throw new Error("The path is too short, it must contain at least two points to allow interpolation.");
    }

    // Place the controlled node at the first point of interest.
    const initialPointIndex: GlobalPointIndex | undefined = this.mappedPath.toGlobal({
      pathIndex: placeAtPoi.path_index,
      pointIndex: placeAtPoi.location_index
    });
    this.controlledNode.position = this.mappedPath.allPoints[
      initialPointIndex ? initialPointIndex : 0
    ];
  }

  /**
   * Moves the controlled node in the direction of the targeted location.
   * The location is an index to a point of the path.
   * Once the mesh reaches its location this function will not move it further.
   * A second location in front of the node will be determined
   * The so-called lookAtPoint, defined in Track.WalkResult, is positioned at the given distance
   * on the path.
   *
   * @param distance
   *        The distance that the controlled node should be moved along the track.
   *        This function always uses the absolute value of this parameter,
   *        the sign does not matter.
   * @param targetPoiIdentifier
   *        Once the controlled node has reached this position the animation stops automatically.
   * @param lookAheadDistance
   *        The distance of the lookAhead point from the position on this track.
   * @returns A Track.WalkResult containing a boolean and a location.
   *          The boolean is True if the node has reached its destination and False otherwise.
   *          The location is the lookAtPoint.
   */
  public walk(
    distance: number,
    targetPoiIdentifier: PointOfInterest,
    lookAheadDistance = 6.0,
  ): WalkResult {
    const targetPointIndex: GlobalPointIndex | undefined = this.mappedPath.toGlobal({
      pathIndex: targetPoiIdentifier.path_index,
      pointIndex: targetPoiIdentifier.location_index
    });

    if (targetPointIndex === undefined) {
      throw new RangeError(
        "The target point does not lie on the track. "+
        `Point index was ${targetPointIndex} but the track has only ${this.mappedPath.numPoints()} points.`
      );
    }

    // Get the closest path that we are currently on.
    const [closestPathIndex, closestIndexInPath, closestGlobalPointIndex] =
      this.mappedPath.closestPath(this.controlledNode.position);

    if (this.currentTarget !== targetPointIndex) {
      this.currentTarget = targetPointIndex;

      console.log(`Setting the current target to ${this.currentTarget}.`);

      this.currentPath = (() => {
        const shortestPath = this.mappedPath.shortestPath(closestGlobalPointIndex, this.currentTarget);
        if (shortestPath.length <= 0) return [];

        /**
         * Helper function that prepends a local point index to the shortest path.
         */
        const prependLocalIndex = (localIndex: LocalPointIndex) => {
          const index: GlobalPointIndex | undefined = this.mappedPath.toGlobal({ pathIndex: closestPathIndex, pointIndex: localIndex});
          if (index === undefined) return;
          shortestPath.unshift(index);
        };

        // Because the shortest path was computed starting from the closest point on the closest
        // path to the current position of the controlled node it can happen that the path starts
        // "after" our current position.
        // To handle this special case we just have to add an additional point to the beginning of
        // our path.
        // Let's assume that:
        // - `o` point that is already part of the shortest path
        // - `x` point is not part of the shortest path
        // - `P` position of the controlled node before any interpolation is done
        //
        // Some examples:
        //
        // x--P--o---o---o
        // or
        // o--o--o--P--x---x
        //
        // In all cases the easy fix is to make sure that both adjacent points to the current
        // position `P` are added to the path.
        // For the first example this means:
        //
        // x--P--o---o---o
        // would be changed into
        // o--P--o---o---o
        // and thus solving the issue.
        const localIndexSecondPointInPath = (() => {
          const localIdentifier = this.mappedPath.toLocal(shortestPath[1]);
          if (localIdentifier === undefined) return undefined;
          if (localIdentifier.pathIndex !== closestPathIndex) return undefined;
          return localIdentifier.pointIndex;
        })();

        if (localIndexSecondPointInPath === closestIndexInPath + 1) {
          prependLocalIndex(closestIndexInPath - 1);
        } else if (localIndexSecondPointInPath === closestIndexInPath - 1) {
          prependLocalIndex(closestIndexInPath + 1);
        }

        return shortestPath;
      })();
    }

    // Get the index of the next point of the shortest path from our current position.
    const nextIndexInPath = (() => {
      const closestStartIndexInPath = 0;
      for (let indexInPath = 0; indexInPath < this.currentPath.length - 1; indexInPath++) {
        const prev = this.mappedPath.allPoints[this.currentPath[indexInPath]];
        const next = this.mappedPath.allPoints[this.currentPath[indexInPath + 1]];
        const pos = this.controlledNode.position;

        // A-----P-----------------B
        // P (pos) is the point where we want to determine if we are on the path segment that is
        // created by the points A (prev) and B (next).
        // If the distance from A to P and from B to P in total is the same as the distance between
        // A and B then P is in the segment AB.
        const difference = Math.abs(prev.subtract(next).length() - (
          pos.subtract(prev).length() + pos.subtract(next).length()
        ));
        if (difference <= 0.0001) {
          return indexInPath + 1;
        }
      }

      return Math.min(closestStartIndexInPath + 1, this.currentPath.length - 1);
    })();

    const getPositionFromDistance = (startingIndex: number, distance: number) => {
      let remainingDistance = Math.abs(distance);
      const currentPosition = this.controlledNode.position.clone();
      let currentNextIndexInPath = startingIndex;

      // Starting from the current position we jump to the next point along the shortest path
      // as long as the remaining distance is enough to make it.
      // Once we have depleted this and the distance to the next point is bigger than the remaining
      // distance the last jump is into the direction of the next point by the remaining distance.
      while (
        currentNextIndexInPath < this.currentPath.length
      ) {
        const next: Vector3 | undefined = this.mappedPath.allPoints[this.currentPath[currentNextIndexInPath]];
        if (!next) {
          return {
            next: startingIndex,
            position: new Vector3(0),
          };
        }
        const directionToNext = next.subtract(currentPosition);
        const distanceToNext = directionToNext.length();
        if (remainingDistance - distanceToNext <= 0.0) break;
        currentPosition.set(next.x, next.y, next.z);
        remainingDistance -= distanceToNext;
        currentNextIndexInPath++;
      }

      if (remainingDistance > 0.0 && currentNextIndexInPath < this.currentPath.length) {
        const next = this.mappedPath.allPoints[this.currentPath[currentNextIndexInPath]];
        const directionToNext = next.subtract(currentPosition);
        const distanceToNext = directionToNext.length();
        currentPosition.addInPlace(directionToNext.scale(remainingDistance/distanceToNext));
      }

      return {
        next: currentNextIndexInPath,
        position: currentPosition.clone(),
      };
    };

    const result = getPositionFromDistance(nextIndexInPath, distance);
    this.controlledNode.position = result.position;

    return {
      destinationReached: this.distance(targetPoiIdentifier) <= 0.0000001,
      lookAtPoint: getPositionFromDistance(result.next, lookAheadDistance).position,
    };
  }

  public distance(poiIdentifier: PointOfInterest): number {
    const pointIndex: GlobalPointIndex | undefined = this.mappedPath.toGlobal({
      pathIndex: poiIdentifier.path_index,
      pointIndex: poiIdentifier.location_index
    });
    if (pointIndex === undefined) return 0.0;
    return this.mappedPath.allPoints[pointIndex]
      .subtract(this.controlledNode.position).length();
  }

  // The node that will be interpolated along the path.
  private controlledNode: TransformNode;

  // Initially the track starts at the first point.
  private currentTarget: GlobalPointIndex | undefined = undefined;

  // Therefore the initial path only contains the index of the first point.
  private currentPath: GlobalPointIndex[] = [];

  private mappedPath: MappedPath;
}
