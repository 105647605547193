/**
 * Sidebar Top Line Component
 * Simple component to add a top line to sidebar with styling
 */

import * as React from "react";

// Stylesheets
import "./SideBarText.scss";


interface SideBarTextProps {
  className?: string;
  color?: string;
  children: React.ReactNode;
}

export default class SideBarText extends React.Component<SideBarTextProps> {
  style = {
    color: this.props.color ? this.props.color: undefined
  };

  render() {
    return (
      <div
        className={`sidebarText ${ this.props.className || "" }`}
      >
        <p style={this.style}>{this.props.children}</p>
      </div>
    );
  }
}
