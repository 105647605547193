/**
 * Sidebar Accordion Component
 * Accordion which can be opened and closed by clicking the header.
 */

import * as React from "react";

// Components
import Icon from "../../components/Icon/Icon";
import SideBarImage from "../SideBarImage/SideBarImage";

// Stylesheets
import "./SideBarTabs.scss";

interface SideBarTabsProps {
  className?: string;
  src1: string;
  src2: string;
  icon1Active?: string;
  icon1Inactive?: string;
  icon2Active?: string;
  icon2Inactive?: string;
  leftText?: string;
  rightText?: string;
  setPopoutImage?: (evt: React.MouseEvent<HTMLImageElement>, image: string) => void
}

interface StateProps {
  tabState: boolean;
}

export default class SideBarTabs extends React.Component<SideBarTabsProps, StateProps> {

  constructor(props: SideBarTabsProps) {
    super(props);
    this.state = {
      tabState: true
    };
  }

  render() {
    return (
      <div
        className={`sideBarTabs ${ this.props.className || "" }`}
      >
        {this.state.tabState ?
          <SideBarImage
            src={this.props.src1}
            setPopoutImage={this.props.setPopoutImage}
          />
          :
          <SideBarImage
            src={this.props.src2}
            setPopoutImage={this.props.setPopoutImage}
          />
        }
        <div className="sideBarTabs__switch">
          <div className={`sideBarTabs__switch__element left ${ this.state.tabState ? "active" : "inactive" }`} onClick={() =>this.setState({tabState: true})}>
            {this.state.tabState ?
              <>
                {this.props.icon1Active &&
                  <Icon
                    width={88}
                    height={24}
                    src={this.props.icon1Active}
                  />
                }
              </>
              :
              <>
                {this.props.icon1Inactive &&
                  <Icon
                    width={88}
                    height={24}
                    src={this.props.icon1Inactive}
                  />
                }
              </>
            }
            {this.props.leftText &&
              <div>{this.props.leftText}</div>
            }
          </div>
          <div className={`sideBarTabs__switch__element right ${ this.state.tabState ? "inactive" : "active" }`} onClick={() =>this.setState({tabState: false})}>
            {this.state.tabState ?
              <>
                {this.props.icon2Inactive &&
                  <Icon
                    width={88}
                    height={24}
                    src={this.props.icon2Inactive}
                  />
                }
              </>
              :
              <>
                {this.props.icon2Active &&
                  <Icon
                    width={88}
                    height={24}
                    src={this.props.icon2Active}
                  />
                }
              </>
            }
            {this.props.rightText &&
              <div>{this.props.rightText}</div>
            }
          </div>
        </div>
      </div>
    );
  }
}
