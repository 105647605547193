/**
 * Sidebar Component
 * Sidebar Container which handles sidebar functionality
 */

import * as React from "react";
import * as PropTypes from "prop-types";

// Components
import Icon from "../Icon/Icon";

// Stylesheets
import "./Sidebar.scss";

// Images
import CloseMenu from "../../assets/images/icons/close.svg";
import ShadowBackground from "~src/assets/images/header shadow.png";




interface SidebarProps {
  className?: string;
  sidebarOpen: boolean;
  closeSidebar: React.ReactEventHandler;
  children: React.ReactNode
}

export default class Sidebar extends React.Component<SidebarProps> {

  constructor(props: SidebarProps) {
    super(props);
  }

  render() {
    return (
      <div className={`sidebar ${ this.props.sidebarOpen ? "open" : "" } ${ this.props.className || "" }`}>
        <div className="sidebar__header" style={{ backgroundImage: `url(${ShadowBackground})` }} >
          <div className="sidebar__close" onClick={this.props.closeSidebar}>
            <Icon
              className="icons"
              src={ CloseMenu }
              width={ 44 }
              height={ 44 }
            />
          </div>
        </div>
        <div className="sidebar__content" >
          {this.props.children}
        </div>
      </div>
    );
  }
}
