/**
 * Sidebar Top Line Component
 * Simple component that adds a styled Image to the sidebar.
 */

import * as React from "react";

// Components
import Icon from "../Icon/Icon";

// Stylesheets
import "./SideBarImage.scss";

// Images
import Lens from "~Assets/images/icons/lupe.svg";

interface SideBarImageProps {
  className?: string;
  width?: number;
  height?: number;
  src: string;
  setPopoutImage?: (evt: React.MouseEvent<HTMLImageElement>, image: string) => void
}

export default class SideBarImage extends React.Component<SideBarImageProps> {
  style = {
    width: this.props.width ? this.props.width : "undefined",
    height: this.props.height ? this.props.height : "undefined",
    cursor: this.props.setPopoutImage ? "pointer" : "default"
  };

  render() {
    return (
      <div
        className={`sidebarImage ${ this.props.className || "" }`}
      >
        {this.props.setPopoutImage &&
          <Icon
            className="icon lens"
            src={ Lens }
            width={ 28 }
            height={ 28 }
          />
        }
        <img
          style={this.style}
          src={this.props.src}
          onClick={(evt) => {
            if(this.props.setPopoutImage)
              this.props.setPopoutImage(evt, this.props.src);
          }}/>
      </div>
    );
  }
}
