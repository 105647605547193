import * as React from "react";

// Stylesheets
import "./StartStopButton.scss";

//images
import ArrowIcon from "../../assets/images/icons/back_inactive.svg";
import PressedArrowIcon from "../../assets/images/icons/back_active.svg";

type StateProps = {
  pressed: boolean;
};

type StartStopButtonProps = {
  className?: string;
  onStart: React.ReactEventHandler;
  onStop: React.ReactEventHandler;
};

/**
 * Button component that fires an event when it is pressed and one when it is released.
 * This component supports Mouse, Keyboard and Touch input.
 */
export default class StartStopButton extends React.Component<StartStopButtonProps, StateProps> {

  constructor(props: StartStopButtonProps) {
    super(props);

    this.state = {
      pressed: false,
    };
  }

  private onClick(start: boolean, evt: React.SyntheticEvent): void {
    if(start) {
      this.props.onStart(evt);
      this.setState({pressed: true});
    } else {
      this.props.onStop(evt);
      this.setState({pressed: false});
    }
  }

  render(): JSX.Element {
    return (
      <div
        className={this.props.className}
        onMouseDown={(evt) => this.onClick(true, evt)}
        onTouchStart={(evt) => this.onClick(true, evt)}
        onKeyDown={(evt) => this.onClick(true, evt)}
        onMouseUp={(evt) => this.onClick(false, evt)}
        onTouchEnd={(evt) => this.onClick(false, evt)}
        onKeyUp={(evt) => this.onClick(false, evt)}
        onMouseLeave={(evt) => this.onClick(false, evt)}
      >
        {!this.state.pressed ?
          <img src={ArrowIcon} />
          :
          <img src={PressedArrowIcon} />
        }
      </div>
    );
  }
}
