import { StandardMaterial, Texture } from "@babylonjs/core";
import { Scene } from "@babylonjs/core/scene";


/**
 * Material intended for debugging.
 * Single texture only.
 */
export class SimpleTexturedMaterial {
  private scene: Scene;

  constructor(name: string, scene: Scene, image: string) {
    this.scene = scene;
    this.material = new StandardMaterial(name, scene);
    this.setTexture(image);
  }

  public setTexture(image: string) {
    const texture = new Texture(image, this.scene);
    texture.hasAlpha = true;
    this.material.diffuseTexture = texture;
    this.material.specularTexture = texture;
    this.material.emissiveTexture = texture;
    this.material.ambientTexture = texture;
  }

  public readonly material: StandardMaterial;
}
