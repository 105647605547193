/**
 * Intro Component
 * Shows the Start Screen with a button to start the Application
 */

import * as React from "react";

import Button from "~Components/Button/Button";
import Icon from "~Components/Icon/Icon";

import IconMarker from "~Assets/images/icons/location_marker.svg";

import "./Intro.scss";

interface IntroProps {
  onToggle?: React.ReactEventHandler;

}

export default class Intro extends React.Component<IntroProps> {

  render() {
    return (
      <div className="intro">
        <h1>NETZlabor<br /><b>Intelligentes Heimladen</b></h1>
        <div className="subline">
          <Icon
            className="icons"
            src={ IconMarker }
            width={ 44 }
            height={ 44 }
          />
          <h3>5 Standorte, Baden-Württemberg</h3>
        </div>
        <Button
          onClick={ this.props.onToggle }
        >
          Virtuelle Tour starten
        </Button>
      </div>
    );
  }
}
