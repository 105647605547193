/**
 * SideBar Content
 * Content which displays the sample Content for one POI.
 */

import * as React from "react";
import { BreakpointContext } from "~src/App";
import { BREAKPOINTS } from "~src/logic/enums/enums";

// Components
import SideBarHeadline from "~Components/SideBarHeadline/SideBarHeadline";
import SideBarTopLine from "~Components/SideBarTopLine/SideBarTopLine";
import SideBarText from "~Components/SideBarText/SideBarText";
import SideBarImage from "~Components/SideBarImage/SideBarImage";
import SideBarTabs from "~Components/SideBarTabs/SideBarTabs";
import SideBarPoi, { SideBarPoiProperties } from "~src/contentComponents/common/SideBarPoi";

// Stylesheets
import "./SideBarPoiEight.scss";

// Images
import HeaderBackground from "~Assets/images/Chaussee/poi_8/Chaussee_Heroshot_08.jpg";
import POIIcon from "~Assets/images/icons/POI_small08.svg";
import SummerActive from "~Assets/images/icons/toggle sommer_active.svg";
import SummerInactive from "~Assets/images/icons/toggle sommer_inactive.svg";
import WinterActive from "~Assets/images/icons/toggle winter_active.svg";
import WinterInactive from "~Assets/images/icons/toggle winter_inactive.svg";
import StaticOperationSummer from "~Assets/images/Chaussee/poi_8/POI_08_statisch_sommer.png";
import StaticOperationWinter from "~Assets/images/Chaussee/poi_8/POI_08_statisch_winter.png";
import DynamicOperation from "~Assets/images/Chaussee/poi_8/POI_08_03.png";

export default class SideBarPoiEight extends SideBarPoi {
  constructor(props: SideBarPoiProperties) {
    super(props);
  }

  render() {
    return (
      <div className={`sideBarPoiEight ${ this.props.className || "" }`} style={{ backgroundImage: `url(${HeaderBackground})` }}>
        <div className="poiContentWrap">
          <div className="poiContent">
            <div className="poiContent__inner">
              <SideBarTopLine>
                Das NETZlabor E-Mobility-Chaussee
              </SideBarTopLine>
              <SideBarHeadline
                icon={POIIcon}
                className='smallMargin'
              >
                Lösungsansatz Zentraler Batteriespeicher: Unterstützung für Spannungsniveau und Gesamtauslastung
              </SideBarHeadline>
              <BreakpointContext.Consumer>
                {value =>
                  <>
                    {value !== BREAKPOINTS.LG && (
                      <SideBarImage
                        src={HeaderBackground}
                      />
                    )}
                  </>
                }
              </BreakpointContext.Consumer>
              <SideBarText
                color="#000"
              >
                Während der Strangregler nur einen Effekt auf das Spannungsniveau erzielen kann, hat der zentrale Batteriespeicher mehr Wirkmöglichkeiten: Durch Einspeisung am Strangende – ähnlich einer EEG-Erzeugungsanlage – kann er sowohl einen positiven Effekt auf das Spannungsniveau ausüben, als auch zusätzlich die Gesamtauslastung des Stromkreises reduzieren. Letzteres wird dann erreicht, wenn der eingespeiste Strom zeitgleich in der Straße verbraucht und die Leistungsbereitstellung dadurch an der Umspannstation reduziert wird. Für den Betrieb werden zwei definierte Modi getestet: zum einen der fahrplanbasierte (statische) Modus und zum anderen der spannungsgeführte (dynamische) Modus.
              </SideBarText>
              <SideBarHeadline
                className='smallMargin'
              >
                Statischer Betrieb
              </SideBarHeadline>
              <SideBarTabs
                src1={StaticOperationSummer}
                src2={StaticOperationWinter}
                icon1Active={SummerActive}
                icon1Inactive={SummerInactive}
                icon2Active={WinterActive}
                icon2Inactive={WinterInactive}
                setPopoutImage={this.props.setPopoutImage}
              />
              <SideBarHeadline
                className='smallMargin'
              >
                Dynamischer Betrieb
              </SideBarHeadline>
              <SideBarImage
                src={DynamicOperation}
                setPopoutImage={this.props.setPopoutImage}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}
