import { Scene, ShaderMaterial } from "@babylonjs/core";

import fragmentShader from "~Shaders/minimapFragment.fx";
import vertexShader from "~Shaders/minimapVertex.fx";

export class MinimapShaderMaterial extends ShaderMaterial {
  constructor(name: string, scene: Scene) {
    super(name, scene, {
      vertexSource: vertexShader,
      fragmentSource: fragmentShader,
      attributes: ["position"],
      uniforms: [
        "worldViewProjection",
        "position",
        "mapOffset",
        "mapSize",
        "sinAlpha",
        "cosAlpha",
        "highlightColor",
        "mapScale",
      ],
      needAlphaBlending: true,
      needAlphaTesting: true,
    });
  }
}
