/**
 * SideBar Content
 * Content which displays the sample Content for one POI.
 */

import * as React from "react";
import { BreakpointContext } from "~src/App";
import { BREAKPOINTS } from "~src/logic/enums/enums";

// Components
import SideBarHeadline from "~Components/SideBarHeadline/SideBarHeadline";
import SideBarTopLine from "~Components/SideBarTopLine/SideBarTopLine";
import SideBarText from "~Components/SideBarText/SideBarText";
import SideBarImage from "~Components/SideBarImage/SideBarImage";
import SideBarPoi, { SideBarPoiProperties } from "~src/contentComponents/common/SideBarPoi";

// Stylesheets
import "./SideBarPoiNine.scss";

// Images
import HeaderBackground from "~Assets/images/Chaussee/poi_9/Chaussee_Heroshot_07.jpg";
import POIIcon from "~Assets/images/icons/POI_small09.svg";
import Chart from "~Assets/images/Chaussee/poi_9/POI_09_01.png";

export default class SideBarPoiNine extends SideBarPoi {
  constructor(props: SideBarPoiProperties) {
    super(props);
  }

  render() {
    return (
      <div className={`sideBarPoiNine ${ this.props.className || "" }`} style={{ backgroundImage: `url(${HeaderBackground})` }}>
        <div className="poiContentWrap">
          <div className="poiContent">
            <div className="poiContent__inner">
              <SideBarTopLine>
                Das NETZlabor E-Mobility-Chaussee
              </SideBarTopLine>
              <SideBarHeadline
                icon={POIIcon}
                className='smallMargin'
              >
                Fazit: Lademanagement bietet das höchste Potenzial zur Integration vieler E-Fahrzeuge in das Verteilnetz
              </SideBarHeadline>
              <BreakpointContext.Consumer>
                {value =>
                  <>
                    {value !== BREAKPOINTS.LG && (
                      <SideBarImage
                        src={HeaderBackground}
                      />
                    )}
                  </>
                }
              </BreakpointContext.Consumer>
              <SideBarText
                color="#000"
              >
                Eine besondere Herausforderung in ländlichen Netzen ist die Gleichzeitigkeit der Elektromobilität. Bei gleichzeitigen Ladevorgängen kann es hier eher zu größeren Spannungsschwankungen kommen, da Stromkreise deutlich länger sind als in urbanen Gebieten. Daher ist es wichtig alle Testphasen hinsichtlich ihrer netztechnischen Wirksamkeit, aber auch Kundenakzeptanz zu bewerten. Das dynamische Lademanagement stellte sich dabei als leistungsfähigster technischer Lösungsansatz heraus. Ohne spürbare Einschränkungen für die E-Pionier*innen könnte die Netze BW damit beinahe doppelt so viele Elektrofahrzeuge in den Stromkreis der Römerstraße aufnehmen. Die E-Mobility-Chaussee kann sowohl für die Netze BW als auch für die Anwender*innen mit einem sehr positiven Fazit abgeschlossen werden. Die Ergebnisse liefern der Netze BW wertvolle Erkenntnisse zur Netzintegration von E-Mobilität in ländliche Netze.
              </SideBarText>
              <SideBarImage
                src={Chart}
                setPopoutImage={this.props.setPopoutImage}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}
