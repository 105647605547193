/**
 * Sidebar List Component
 * List with Icons which contain List Elements
 */

import * as React from "react";
import Icon from "../Icon/Icon";

// Stylesheets
import "./SideBarList.scss";

// Images
import ListIconImage from "../../assets/images/icons/list_icon.svg";

interface SideBarListProps {
  className?: string;
  listElements: Array<ListElements>;
  listIcon?: string;
}

export interface ListElements {
  text: string;
}

export default class SideBarList extends React.Component<SideBarListProps> {
  render() {
    return (
      <div
        className={`sideBarList ${ this.props.className || "" } ${this.props.listIcon || "" }`}
      >
        <ul>
          {this.props.listElements.map((element, index) => (
            <li key={index}>
              {this.props.listIcon === "hook" &&
                <Icon
                  width={20}
                  height={20}
                  src={ListIconImage}
                />
              }
              <p>{element.text}</p>
            </li>
          ))}
        </ul>
      </div>
    );
  }
}
