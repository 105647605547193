/**
 * Step Component
 * Shows a Single Step in the Tutorial Section with fixed Styling
 * Content is Variable based on Parameters and children
 */

import * as React from "react";

// Components
import Button from "../Button/Button";
import { Scrollbars } from "react-custom-scrollbars-2";

//Stylesheets
import "./Step.scss";


type StepProps =   {
  className?: string;
  onNextStep?: React.ReactEventHandler;
  imgSrc: string;
  children: React.ReactNode;
  buttonText?: string;
}

export default class Step extends React.Component<StepProps> {
  render() {
    return (
      <Scrollbars
        autoHeight
        autoHeightMin="100%"
        autoHeightMax="100%"
        autoHide={false}
        className="scrollbarWrap"
      >
        <div className={`step ${this.props.className || ""}`}>
          <div className="step__left">
            { this.props.children }

            <Button
              onClick={ this.props.onNextStep }
            >
              {this.props.buttonText ? this.props.buttonText : "Weiter"}
            </Button>
          </div>
          <div className="step__right">
            <img src={this.props.imgSrc} />
          </div>
        </div>
      </Scrollbars>
    );
  }
}
