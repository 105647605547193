/**
 * SideBar Content
 * Component displaying the sample content for the fourth POI.
 */

import * as React from "react";
import { BreakpointContext } from "~src/App";
import { BREAKPOINTS } from "~src/logic/enums/enums";

// Components
import SideBarHeadline from "~Components/SideBarHeadline/SideBarHeadline";
import SideBarTopLine from "~Components/SideBarTopLine/SideBarTopLine";
import SideBarText from "~Components/SideBarText/SideBarText";
import SideBarImage from "~Components/SideBarImage/SideBarImage";
import SideBarAccordion from "~Components/SideBarAccordion/SideBarAccordion";
import SideBarTable, {TableElements} from "~Components/SideBarTable/SideBarTable";
import SideBarPoi, { SideBarPoiProperties } from "~src/contentComponents/common/SideBarPoi";

// Stylesheets
import "./SideBarPoiOne.scss";
import "~src/logic/scss/Animations.scss";

// Images
import HeaderBackground from "~Assets/images/NIHL/POI_1/NIHL_Heroshot_01.png";
import POIIcon from "~Assets/images/icons/POI_small01.svg";
import ImageOne from "~Assets/images/NIHL/POI_1/POI_1_01.png";
import ImageTwo from "~Assets/images/NIHL/POI_1/POI_1_02.png";
import BMW from "~Assets/images/NIHL/POI_1/POI_1_03.png";
import VW from "~Assets/images/NIHL/POI_1/POI_1_04.png";
import Tesla from "~Assets/images/NIHL/POI_1/POI_1_05.png";

export default class SideBarPoiOne extends SideBarPoi {
  constructor(props: SideBarPoiProperties) {
    super(props);
  }

  render() {
    return (
      <div className={`sideBarPoiOne ${ this.props.className || "" }`} style={{ backgroundImage: `url(${HeaderBackground})` }}>
        <div className="poiContentWrap">
          <div className="poiContent">
            <div className="poiContent__inner">
              <SideBarTopLine>
                Das NETZlabor Intelligentes Heimladen
              </SideBarTopLine>
              <SideBarHeadline
                icon={POIIcon}
                className='smallMargin'
              >
                Das Lademanagement: Entscheidender Faktor für die Integration der E-Mobilität ins Stromnetz
              </SideBarHeadline>
              <BreakpointContext.Consumer>
                {value =>
                  <>
                    {value !== BREAKPOINTS.LG && (
                      <SideBarImage
                        src={HeaderBackground}
                      />
                    )}
                  </>
                }
              </BreakpointContext.Consumer>
              <SideBarText
                color="#000"
              >
                In den NETZlaboren E-Mobility-Allee, -Carré und -Chaussee stellte sich Lademanagement als Erfolgsgarant unter den getesteten technischen Lösungsansätzen heraus.
                <br />Lademanagement im Allgemeinen beschreibt die gezielte Steuerung der Ladeleistung, welche dem E-Fahrzeug zur Verfügung steht. Kommt es zeitweise zu Lastspitzen im Stromnetz können diese durch Lademanagement ausgeglichen werden. Hierbei wird die maximale Ladeleistung zu Zeiten, in denen das Stromnetz stark beansprucht wird, reduziert. Das bedeutet in der Praxis, dass die Batterie temporär langsamer lädt und sich die Ladezeit verlängert. Das Stromnetz wird dadurch entlastet.
              </SideBarText>
              <SideBarImage
                src={ImageOne}
                setPopoutImage={this.props.setPopoutImage}
              />
              <SideBarText
                color="#000"
              >
                Für den Einsatz von Lademanagement ist es wichtig zu testen, wie sich Elektromobilität auf unterschiedliche Netztopologien auswirkt. Auch die Verhältnisse zwischen den in den Stromkreisen installierten Bezugs- und Einspeiseleistungen, wie beispielsweise Photovoltaikanlagen oder Wärmepumpen, gilt es bei dieser Gesamtbetrachtung zu berücksichtigen. Diese Faktoren und die ganz individuellen Ladevorgänge an jedem Standort geben uns Erfahrungswerte über die Wirksamkeit und Ausgestaltung des Lademanagements in unserem Verteilnetz.
              </SideBarText>
              <SideBarImage
                src={ImageTwo}
                setPopoutImage={this.props.setPopoutImage}
              />
              <SideBarAccordion
                headline="BMW i3"
                src={BMW}
              >
                <SideBarTable
                  tableElements={[
                    {left: "Batteriekapazität", right: "38 kWh"},
                    {left: "Alltagsreichweite", right: "ca. 240 km"}
                  ] as Array<TableElements> }
                />
              </SideBarAccordion>
              <SideBarAccordion
                headline="VW e-Golf"
                src={VW}
              >
                <SideBarTable
                  tableElements={[
                    {left: "Batteriekapazität", right: "35,8 kWh"},
                    {left: "Alltagsreichweite", right: "ca. 200 km"}
                  ] as Array<TableElements> }
                />
              </SideBarAccordion>
              <SideBarAccordion
                headline="Tesla Model 3"
                src={Tesla}
              >
                <SideBarTable
                  tableElements={[
                    {left: "Batteriekapazität", right: "75 kWh"},
                    {left: "Alltagsreichweite", right: "ca. 425 km"}
                  ] as Array<TableElements> }
                />
              </SideBarAccordion>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
