import {
  Nullable,
  Scene,
  Vector3,
  Node,
  TransformNode,
  Quaternion,
  Scalar,
  Angle,
} from "@babylonjs/core";
import { UniversalCamera } from "@babylonjs/core/Cameras/universalCamera";
import { CityConfiguration } from "~src/logic/io/json/city_configuration";
import { clamp, toBabylonCS, toVector3 } from "./utility";


/**
 * Available camera types.
 */
export enum CameraType {
    STREET = 0,
    BIRD = 1
}

/**
 * Descriptor for the location and rotation of a node in the scene.
 */
interface Pose {
  position: Vector3,
  rotation: Quaternion,
}

/**
 * Descriptor for the location and rotation of a node in the scene.
 */
 interface PoseWithOptionalComponents {
  position?: Vector3,
  rotation?: Quaternion,
}

/**
 * Data needed to store the current state of a camera.
 */
interface CameraState {
  orientation: Pose,
  fov: number,
  parent: Nullable<Node>,
}

/**
 * Type to store a state for each type of camera.
 */
type PerCameraTypeStates = {
    [CameraType.BIRD]: CameraState,
    [CameraType.STREET]: CameraState,
};

/**
 * Wrapper class around a BabylonJS Camera.
 * It allows switching between the bird and the street camera type.
 * Every type of camera has its individual position, rotation and parent that are stored and
 * restored accordingly when changing the type.
 */
export class MultiCamera {

  /**
   * c'tor
   *
   * @param configuration
   *        Configuration that holds the position and look target for the bird camera.
   * @param scene
   *        The Scene that the created camera will be added to.
   * @param streetCameraParent
   *        The scene node that the street camera will follow.
   */
  public constructor(
    configuration: CityConfiguration,
    scene: Scene,
    streetCameraParent: TransformNode,
  ) {
    this.scene = scene;
    this.scene.addCamera(this.camera);

    this.id = this.camera.id;

    this.initialBirdFOV = Angle.FromDegrees(configuration.birds_eye_view.fov).radians();

    this.cameraStates[CameraType.BIRD].parent = null;
    this.cameraStates[CameraType.STREET].parent = streetCameraParent;

    // Enable rotations with quaternions.
    // See https://doc.babylonjs.com/typedoc/classes/BABYLON.TransformNode#rotationQuaternion
    this.camera.rotationQuaternion = new Quaternion();
    this.camera.parent = this.cameraStates[CameraType.BIRD].parent;
    this.type = CameraType.BIRD;
    this.camera.position = toBabylonCS(toVector3(configuration.birds_eye_view.position));
    this.camera.setTarget(toBabylonCS(toVector3(configuration.birds_eye_view.look_at_position)));
    this.camera.fov = this.initialBirdFOV;
    this.saveCurrentCameraState();
  }

  /**
   * Changes the type of the camera into the given one.
   *
   * @param type Camera type that should be set.
   */
  public activate(type: CameraType): void {
    if (this.type === type) return;

    this.sourceOrientation = this.getCameraWorldPose();
    this.sourceFov = this.cameraStates[this.type].fov;
    this.type = type;

    // Reset any ongoing interpolation.
    this.interpolationProgress = 0.0;

    // Disable movement with touch completly.
    this.camera.touchMoveSensibility = 0;
    // default value was 200000
    // The official documentation of babylonjs states that the speed of rotation is proportional
    // to this value.
    // This is wrong, it is the exact opposite, the slower the faster.
    this.camera.touchAngularSensibility = 10000;

    if (this.type === CameraType.STREET) {
      this.camera.attachControl(this.scene, true);
      this.camera.inputs.remove(this.camera.inputs.attached.keyboard);
    }
    else {
      this.camera.detachControl();
    }
  }

  /**
   * Advances the camera animation by a single step.
   */
  public update(
    width: number,
    height: number,
    easing: (x: number) => number = (x: number) => {
      // Default easing function taken from https://easings.net/
      // https://easings.net/#easeInOutSine
      return -(Math.cos(Math.PI * x) - 1) / 2;
    },
  ): void {
    this.interpolationProgress = clamp(
      this.interpolationProgress + 0.01 * this.scene.getAnimationRatio(),
      0.0,
      1.0
    );

    // Increase the field of view proportional to the ratio between the width and height of the
    // screen.
    // This is a hack to ensure that everything in the scene is visible even if the screen is very
    // narrow.
    // This is not the most accurate way to calculate this, but it works and is good enough for
    // this application.
    this.cameraStates[CameraType.BIRD].fov =
      Math.min(
        // We limit the maximum possible field of view to 3/4th of Pi which is 135°.
        // Angles beyond this value do not look good and beyond Pi the scene is flipped.
        Math.PI * 0.75,
        Math.max(
          // The angle should not be less then the one set by the city configuration.
          this.initialBirdFOV,
          this.initialBirdFOV * (height / width)
        )
      );

    if (this.interpolationProgress < 1.0) {
      this.camera.parent = null;

      const interpolationTarget = this.getTargetPose(this.type);
      const targetFov = this.cameraStates[this.type].fov;

      // Apply the provided easing function but make sure that the result stays within the bounds
      // of [0, 1]
      const easedProgress = clamp(easing(this.interpolationProgress), 0.0, 1.0);

      // Because interpolation of the position is only done when the camera is not in street mode,
      // we have to enforce the local position by resetting the camera to the stored position for
      // the street camera.
      // Otherwise there can be frames where the camera is not where it is supposed to be.
      if (this.type === CameraType.STREET) {
        const pivotNode = new TransformNode("");
        pivotNode.setParent(this.cameraStates[CameraType.STREET].parent);
        pivotNode.setPositionWithLocalVector(
          this.cameraStates[CameraType.STREET].orientation.position
        );
        pivotNode.setParent(null);
        this.camera.position = pivotNode.position.clone();
      }

      if (interpolationTarget.position) {
        this.camera.position = Vector3.Lerp(
          this.sourceOrientation.position,
          interpolationTarget.position,
          easedProgress
        );
      }

      if (interpolationTarget.rotation) {
        this.camera.rotationQuaternion = Quaternion.Slerp(
          this.sourceOrientation.rotation,
          interpolationTarget.rotation,
          easedProgress
        );
      }

      this.camera.fov = Scalar.Lerp(this.sourceFov, targetFov, easedProgress);
    } else {
      // Once the interpolation is done overwrite the stored orientation if an alternative rotation
      // was given as a target.
      if (this.alternativeTargetRotation) {
        this.cameraStates[this.type].orientation.rotation = this.alternativeTargetRotation.clone();
        this.alternativeTargetRotation = undefined;
      }

      this.camera.parent = this.cameraStates[this.type].parent;
      this.camera.position = this.cameraStates[this.type].orientation.position;
      this.camera.rotationQuaternion = this.cameraStates[this.type].orientation.rotation;
      this.camera.fov = this.cameraStates[this.type].fov;
      this.sourceFov = this.camera.fov;
    }
  }

  /**
   * Checks whether the camera is doing an animation.
   * An animation is for example started by calling setTargetRotation().
   */
  public inAnimation(): boolean {
    return this.interpolationProgress < 1.0;
  }

  /**
   * The current type of the camera.
   */
  public getCurrentCameraType(): CameraType {
    return this.type;
  }

  public getCurrentCameraRotation(): Quaternion {
    return this.camera.rotationQuaternion.clone();
  }

  public getCurrentCameraPosition(): Vector3 {
    return this.camera.position.clone();
  }

  /**
   * Overwrites the rotation of the camera with the given type.
   * If the camera transitions into a new state the transition will be updated to the given
   * rotation.
   *
   * @param type
   *        The rotation of the camera with this type will be overwritten.
   * @param rotation
   *        Rotation that the camera with the given type will have after calling this function.
   */
  public setCameraRotation(type: CameraType, rotation: Quaternion) {
    this.cameraStates[type].orientation.rotation = rotation.clone();
  }

  /**
   * Sets an alternative rotation of the animation target.
   * The next time update() will be called the camera will be rotated accordingly.
   */
  public setTargetRotation(rotation: Quaternion): void {
    this.alternativeTargetRotation = rotation;
    this.interpolationProgress = 0.0;
    this.sourceOrientation = this.getCameraWorldPose();
  }

  /**
   * Stores the current state of the camera for the current camera type.
   */
  private saveCurrentCameraState(): void {
    // Do not store the current state if the interpolation is not finished yet.
    if (this.interpolationProgress < 1.0) return;
    this.cameraStates[this.type] = this.getCameraState();
  }

  /**
   * Acquires the current state of the camera.
   */
  private getCameraState(): CameraState {
    return {
      orientation: {
        position: this.camera.position.clone(),
        rotation: this.camera.rotationQuaternion.clone(),
      },
      fov: this.camera.fov,
      parent: this.camera.parent,
    };
  }

  /**
   * Calculates the current position and rotation in world space.
   */
  private getCameraWorldPose(): Pose {
    this.camera.computeWorldMatrix();
    const worldMatrix = this.camera.getWorldMatrix();
    return {
      position: worldMatrix.getTranslation(),
      rotation: Quaternion.FromRotationMatrix(worldMatrix.getRotationMatrix())
    };
  }

  /**
   * Acquires the stored pose of the specified camera type.
   * The pose can be used as a target for the animation system in update().
   * Note that this is not the current pose of the camera at the moment rather the last known
   * pose of the camera type.
   *
   * @param type
   *        Type of the camera for which the pose should be retrieved.
   *
   * @returns The pose of the camera type in world coordinates.
   *          Contains a positional target if no alternative rotation was set with
   *          setTargetRotation().
   */
  private getTargetPose(type: CameraType): PoseWithOptionalComponents {
    if (this.alternativeTargetRotation) return {
      position: undefined,
      rotation: this.alternativeTargetRotation.clone(),
    };

    const pivotNode = new TransformNode("");

    // Enable rotations with quaternions.
    // See https://doc.babylonjs.com/typedoc/classes/BABYLON.TransformNode#rotationQuaternion
    pivotNode.rotationQuaternion = new Quaternion();

    // Insert the node into the scenegraph with the same parent as the stored camera.
    pivotNode.setParent(this.cameraStates[type].parent);
    // Apply the local position and rotation
    pivotNode.setPositionWithLocalVector(this.cameraStates[type].orientation.position);
    pivotNode.rotationQuaternion = this.cameraStates[type].orientation.rotation;
    // Remove the parent which will recalculate the local position and rotation such that the
    // node remains at its world position and rotation.
    pivotNode.setParent(null);

    // Return the position and rotation which is now equivalent to the world position and rotation.
    return {
      position: pivotNode.position,
      // The quaternion is not null because we just assigned something to it.
      rotation: pivotNode.rotationQuaternion!,
    };
  }

  public id: string;

  private camera: UniversalCamera = new UniversalCamera(
    "InterpolatedMultiModeCamera",
    new Vector3(0, 0, 0),
  );

  private scene: Scene;
  private type: CameraType = CameraType.BIRD;
  private cameraStates: PerCameraTypeStates = {
    [CameraType.BIRD]: {
      orientation: { position: new Vector3(), rotation: new Quaternion() },
      // Default FOV in BabylonJS
      fov: 0.8,
      parent: null
    },
    [CameraType.STREET]: {
      orientation: { position: new Vector3(), rotation: new Quaternion() },
      // Default FOV in BabylonJS
      fov: 0.8,
      parent: null
    },
  };

  // The field of view value given by the city configuration.
  private initialBirdFOV = 0.0;

  private interpolationProgress = 1.0;

  private sourceOrientation: Pose = {
    position: new Vector3(),
    rotation: new Quaternion(),
  };
  private sourceFov = 0.0;

  private alternativeTargetRotation?: Quaternion;
}
