import { Engine } from "@babylonjs/core/Engines/engine";
import { WebGPUEngine } from "@babylonjs/core/Engines/webgpuEngine";

import "@babylonjs/core/Engines/WebGPU/Extensions/engine.uniformBuffer";
import * as React from "react";

import "./Scene3D.scss";

import CityScene from "~src/scenes/cityScene";
import { MapConfig } from "~src/scenes/cityScene";
import { CameraType } from "~src/rendering/multiCamera";
import { CityConfiguration } from "~src/logic/io/json/city_configuration";
import { BREAKPOINTS } from "~src/logic/enums/enums";

export type RenderState = {
  view: CameraType;
  targetedPoiIndex: number;
}

interface Scene3DProps {
  breakpoint: BREAKPOINTS;
  rendering: RenderState;
  onPoiEnter: (index: number) => void;
  onPoiLeave: () => void;
  onPoiClicked: (index: number) => void;
  cityModelUrl: string;
  cityConfiguration: CityConfiguration;
  miniMapConfig: MapConfig;
  setCameraView: (type: CameraType) => void;
}

export class Scene3D extends React.Component<Scene3DProps> {
  private canvasElement: React.RefObject<HTMLCanvasElement>;

  public city?: CityScene;

  constructor(props: Scene3DProps) {
    super(props);
    this.canvasElement = React.createRef();
  }

  async componentDidMount() {
    await this.babylonInit();
  }

  async componentDidUpdate(oldState: Scene3DProps) {
    this.engine?.resize();
    if (oldState.rendering.targetedPoiIndex !== this.props.rendering.targetedPoiIndex) {
      this.city?.setPoiTarget(this.props.rendering.targetedPoiIndex);
    }
  }

  babylonInit = async (): Promise<void> => {

    if (!this.canvasElement.current) return;

    const engineType =
        location.search.split("engine=")[1]?.split("&")[0] || "webgl";
    // Get the canvas element
    const canvas = document.getElementById("renderCanvas") as HTMLCanvasElement;
    // Generate the BABYLON 3D engine
    if (engineType === "webgpu") {
      const webGPUSupported = await WebGPUEngine.IsSupportedAsync;
      if (webGPUSupported) {
        const webgpu = this.engine = new WebGPUEngine(canvas, {
          adaptToDeviceRatio: true,
          antialiasing: true,
        });
        await webgpu.initAsync();
        this.engine = webgpu;
      } else {
        this.engine = new Engine(canvas, true);
      }
    } else {
      this.engine = new Engine(canvas, true);
    }

    // Create the scene
    this.city = new CityScene(this.props.cityConfiguration);
    this.city.create(
      this.engine,
      (type: CameraType) => {
        this.props.setCameraView(type);
      },
      this.props.cityModelUrl,
      this.props.miniMapConfig,
    );
    this.city.subscribePoiEnterEvent(this.props.onPoiEnter);
    this.city.subscribePoiLeaveEvent(this.props.onPoiLeave);
    this.city.subscribePoiClickedEvent(this.props.onPoiClicked);

    // Register a render loop to repeatedly render the scene
    this.engine.runRenderLoop(() => {
      if (!this.engine || !this.city) return;
      this.city.setCameraType(this.props.rendering.view);
      this.city.render(
        this.engine?.getRenderWidth(),
        this.engine?.getRenderHeight(),
        this.props.breakpoint,
      );
    });

    // Watch for browser/canvas resize events
    window.addEventListener("resize", () => {
      this.engine?.resize();
    });

  };

  render() {
    return <canvas id="renderCanvas" ref={this.canvasElement} />;
  }

  engine? : Engine;
}
