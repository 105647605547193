import {
  Camera,
  CreatePlane,
  Engine,
  HemisphericLight,
  Material,
  Mesh,
  Scene,
  Node,
  UniversalCamera,
  Vector3,
  Vector2,
  Color3,
} from "@babylonjs/core";
import { BREAKPOINTS } from "~src/logic/enums/enums";
import { MinimapShaderMaterial } from "./minimapShaderMaterial";
import { SimpleTexturedMaterial } from "./simpleTexturedMaterial";

// Descriptor containing the values that are needed to place a mesh on the screen.
// The UI is two dimensional and its elements always have a fixed aspect ratio.
interface UiTransform {
    // Width of the element as a percentage of the total screen width.
    width: number;
    // Ratio used to calculate the height of the element based on its width.
    aspectRatio: number;
    // Offset in pixels the element should be distanced from the left border of the screen.
    left: number;
    // Offset in pixels the element should be distanced from the bottom border of the screen.
    bottom: number;
}

// Each element of the user interface is a mesh that is positioned somewhere on the screen.
interface UiElement {
    position: UiTransform;
    minWidth: number;
    mesh: Mesh;
}

// Minimum width of minimap in pixels.
const MINIMAP_MIN_WIDTH = 250;

/**
 * Helper function to quickly create an element of the user interface.
 *
 * @param name Name of the elements mesh.
 * @param material Material that the created mesh should have.
 * @param transformation 2D location and size on screen.
 * @param minWidth Minimum width in pixels the element can be on screen.
 * @param parent Optional parent node that the created UiElement can be attached to.
 * @returns The created ui element.
 */
function createUiElement(
  name: string,
  material: Material,
  transformation: UiTransform,
  minWidth: number,
  parent?: Node,
): UiElement {
  const element: UiElement = {
    position: transformation,
    mesh: CreatePlane(
      name, { size: 1, sideOrientation: Mesh.DOUBLESIDE }
    ),
    minWidth: minWidth,
  };
  element.mesh.material = material;
  if (parent) {
    element.mesh.parent = parent;
  }
  return element;
}

/**
 * Updates the position and size of the element based on the screen width.
 *
 * @param element Element where the transformation of its mesh is updated.
 * @param orthographicCamera Orthographic camera that used to render the user interface.
 * @param screenWidth Current width of the screen used to calculated the new size of the element.
 * @param zLayer UI Elements with a lower value will occlude elements with a higher value.
 * @param clockwiseRotation Angle in degrees the ui element will be rotated clockwise.
 */
function updateUiElement(
  element: UiElement,
  orthographicCamera: Camera,
  screenWidth: number,
  zLayer: number,
  clockwiseRotation = 0.0,
): void {
  if (!orthographicCamera.orthoLeft || !orthographicCamera.orthoBottom) return;

  // The width is a percentage of the total screen width and as such is multiplied with the
  // screen width to get the new width of the element.
  const elementWidth = Math.max(element.minWidth, screenWidth * element.position.width);
  const elementHeight = elementWidth / element.position.aspectRatio;
  element.mesh.rotation = new Vector3(0, 0, -clockwiseRotation);
  element.mesh.position = new Vector3(
    // x and y both have to be shifted by half the width/height of the element because
    // the center of the screen is the origin of the coordinate system.
    orthographicCamera.orthoLeft + element.position.left + elementWidth / 2,
    orthographicCamera.orthoBottom + element.position.bottom + elementHeight / 2,
    // Calculate a number that is always inside of the z-range but not minZ or maxZ.
    // If the z value is minZ or maxZ the element will not be visible.
    // We could just set it to 1.0 because the default values of minZ and maxZ are 0.1 and
    // 10000.0 but if it would change at some point in time it wouldn't be correct anymore.
    (orthographicCamera.minZ + orthographicCamera.maxZ) / 2 + zLayer
  );
  element.mesh.scaling = new Vector3(elementWidth, elementHeight, 1);
}

const PLAYER_HIGHLIGHT_COLOR = new Color3(1.0, 0.6, 0.0);
const POI_HIGHLIGHT_COLOR = new Color3(0.0, 0.0, 1.0);

interface MinimapOverlayElement {
  uiElement: UiElement;
  material: MinimapShaderMaterial;
}

export default class UserInterface {
  constructor(
    engine: Engine,
    miniMapUrl: string,
    miniMapSize: number,
    poiPositions: Vector3[]
  ) {
    this.scene = new Scene(engine);
    this.scene.autoClear = false;

    this.camera = new UniversalCamera("orthographicUiCamera", new Vector3(0));
    this.camera.mode = Camera.ORTHOGRAPHIC_CAMERA;

    // Directional light that illuminates the user interface with a 90° angle of incidence.
    new HemisphericLight("light", new Vector3(0, 0, -1), this.scene);

    this.poiPositions = [];
    for (const poiPosition of poiPositions) {
      this.poiPositions.push(new Vector2(poiPosition.x, poiPosition.z));
    }

    const mapMaterial = new SimpleTexturedMaterial(
      "mapMaterial", this.scene, miniMapUrl
    );

    const miniMapTransform: UiTransform = {
      width: miniMapSize,
      aspectRatio: 1.0,
      bottom: 44.0,
      left: 44.0,
    };

    this.miniMap = createUiElement(
      "minimap",
      mapMaterial.material,
      miniMapTransform,
      MINIMAP_MIN_WIDTH,
      this.camera,
    );


    const createOverlay = (): MinimapOverlayElement => {
      const miniMapOverlayMaterial = new MinimapShaderMaterial("minimapoverlay", this.scene);
      miniMapOverlayMaterial.alpha = 0.5;

      const miniMapOverlay = createUiElement(
        "minimapoverlay",
        miniMapOverlayMaterial,
        miniMapTransform,
        MINIMAP_MIN_WIDTH,
        this.camera,
      );

      return { uiElement: miniMapOverlay, material: miniMapOverlayMaterial };
    };

    this.miniMapOverlays = [];
    this.miniMapOverlays.push(createOverlay());
    for (const _ of poiPositions) {
      this.miniMapOverlays.push(createOverlay());
    }
  }

  /**
   * Render the user interface for the given screen size.
   *
   * @param width Width of the screen in pixels.
   * @param height Height of the screen in pixels.
   * @param position 2D Position of the player in the city.
   * @param mapOffset Coordinates of the bottom left corner of the minimap in world space.
   * @param mapSize Width and height of the minimap in world space.
   * @param mapRotation Rotation of the minimap in world space.
   * @param breakpoint Enum representing the current display size used to hide the minimap on
   *                   mobile screens.
   */
  public render(
    width: number,
    height: number,
    position: Vector2,
    mapOffset: Vector2,
    mapSize: Vector2,
    mapScale: number,
    mapRotation: number,
    breakpoint: BREAKPOINTS,
  ): void {
    this.camera.orthoLeft = - width / 2;
    this.camera.orthoRight = width / 2;
    this.camera.orthoBottom = - height / 2;
    this.camera.orthoTop = height / 2;

    const drawMiniMap = breakpoint !== BREAKPOINTS.SM;

    this.miniMap.mesh.visibility = drawMiniMap ? 1 : 0;
    for (const overlay of this.miniMapOverlays) {
      overlay.uiElement.mesh.visibility = drawMiniMap ? 1 : 0;
    }

    if (drawMiniMap) {
      updateUiElement(this.miniMap, this.camera, width, 100.0);

      for (let i = 1; i < this.miniMapOverlays.length; i++) {
        this.drawOverlay(
          width, mapOffset, mapSize, mapScale, mapRotation,
          this.miniMapOverlays[i], this.poiPositions[i - 1], POI_HIGHLIGHT_COLOR, 99.0
        );
      }

      // The first is the player
      this.drawOverlay(
        width, mapOffset, mapSize, mapScale, mapRotation,
        this.miniMapOverlays[0], position, PLAYER_HIGHLIGHT_COLOR, 1.0
      );
    }

    this.scene.render();
  }

  private drawOverlay(
    width: number,
    mapOffset: Vector2,
    mapSize: Vector2,
    mapScale: number,
    mapRotation: number,
    overlay: MinimapOverlayElement,
    position: Vector2,
    color: Color3,
    zLayer: number
  ) {
    updateUiElement(overlay.uiElement, this.camera, width, zLayer);

    overlay.material.setVector2("position", position);
    overlay.material.setVector2("mapOffset", mapOffset);
    overlay.material.setVector2("mapSize", mapSize);
    overlay.material.setFloat("mapScale", mapScale);

    const angleRad = (mapRotation / 180.0) * Math.PI;
    overlay.material.setFloat("sinAlpha", Math.sin(angleRad));
    overlay.material.setFloat("cosAlpha", Math.cos(angleRad));
    overlay.material.setColor3("highlightColor", color);
  }

  private scene: Scene;
  private camera: Camera;
  private miniMap: UiElement;
  private miniMapOverlays: MinimapOverlayElement[];
  private poiPositions: Vector2[];
}
