/**
 * SideBar Content
 * Content which displays the sample Content for one POI.
 */

import * as React from "react";
import { BreakpointContext } from "~src/App";
import { BREAKPOINTS } from "~src/logic/enums/enums";

// Components
import SideBarHeadline from "~Components/SideBarHeadline/SideBarHeadline";
import SideBarTopLine from "~Components/SideBarTopLine/SideBarTopLine";
import SideBarText from "~Components/SideBarText/SideBarText";
import SideBarAccordion from "~Components/SideBarAccordion/SideBarAccordion";
import SideBarTable, {TableElements} from "~Components/SideBarTable/SideBarTable";
import SideBarPoi, { SideBarPoiProperties } from "~src/contentComponents/common/SideBarPoi";
import SideBarImage from "~Components/SideBarImage/SideBarImage";


// Stylesheets
import "./SideBarPoiTwo.scss";

// Images
import HeaderBackground from "~Assets/images/Carre/POI_2/Carre_Heroshot_02.png";
import POIIcon from "~Assets/images/icons/POI_small02.svg";
import BMW from "~Assets/images/Carre/POI_2/POI_02_01.jpg";
import VW from "~Assets/images/Carre/POI_2/POI_02_02.jpg";

export default class SideBarPoiTwo extends SideBarPoi {
  constructor(props: SideBarPoiProperties) {
    super(props);
  }

  render() {
    return (
      <div className={`sideBarPoiTwo ${ this.props.className || "" }`} style={{ backgroundImage: `url(${HeaderBackground})` }}>
        <div className="poiContentWrap">
          <div className="poiContent">
            <div className="poiContent__inner">
              <SideBarTopLine>
                Das NETZlabor E-Mobility-Carré
              </SideBarTopLine>
              <SideBarHeadline
                icon={POIIcon}
                className='smallMargin'
              >
                Dynamik in der Tiefgarage
              </SideBarHeadline>
              <BreakpointContext.Consumer>
                {value =>
                  <>
                    {value !== BREAKPOINTS.LG && (
                      <SideBarImage
                        src={HeaderBackground}
                      />
                    )}
                  </>
                }
              </BreakpointContext.Consumer>
              <SideBarText
                color="#000"
              >
                63 Eigentümer*innen. 58 Ladepunkte. 45 E-Autos. 1 Tiefgarage. Für den Praxistest unterziehen die teilnehmenden Haushalte in der Tiefgarage der Wohnanlage “Pura Vida” das lokale Stromnetz einem besonderen “Härtetest”. Dafür stellt die Netze BW den Teilnehmer*innen für die Dauer des Projekts insgesamt 45 E-Fahrzeuge (VW e-Golf und BMW i3) und eine zentral gesteuerte Ladestation mit 58 Ladepunkten zur Verfügung. Zusätzlich werden Batteriespeicher und ein Lademanagementsystem eingesetzt, um zu testen, wie durch diese Lösungen Ladestationen zukünftig optimal in bestehende Mehrfamilienhäuser im Bestand integriert werden können – ohne dass der bestehende Netzanschluss oder das vorgelagerte Stromnetz übermäßig stark belastet werden.
              </SideBarText>

              <SideBarAccordion
                headline="BMW i3"
                src={BMW}
              >
                <SideBarText
                  color="#000"
                >
                  Der BMW i3 hat mit 11 kW für ein Elektroauto eine übliche Ladeleistung. Dennoch ist auch dieses Fahrzeug ein weiterer Großverbraucher und - besonders wenn mehrere Fahrzeuge gleichzeitig laden - eine Herausforderung für das Stromnetz.
                </SideBarText>
                <SideBarTable
                  tableElements={[
                    {left: "Batteriekapazität", right: "33 kWh"},
                    {left: "Ladeleistung", right: "11 kW (AC)"},
                    {left: "Ladeverhalten", right: "dreiphasig"},
                    {left: "Alltagsreichweite", right: "ca. 200 km"},
                  ] as Array<TableElements> }
                />
              </SideBarAccordion>
              <SideBarAccordion
                headline="VW E-Golf"
                src={VW}
              >
                <SideBarText
                  color="#000"
                >
                  Der VW E-Golf lädt zweiphasig mit 7,2 kW. Damit belastet er nur zwei der drei Phasen des Stromnetzes. Laden mehrere E-Autos auf denselben Phasen, kommt es zu einer asymmetrischen Belastung im Stromnetz. Dies ist jedoch nur bis zu einer Schieflast von 4,6 kVA zulässig und stellt das Lademanagement vor eine zusätzliche Herausforderung.
                </SideBarText>
                <SideBarTable
                  tableElements={[
                    {left: "Batteriekapazität", right: "35,8 kWh"},
                    {left: "Ladeleistung", right: "7,2 kW (AC)"},
                    {left: "Ladeverhalten", right: "zweiphasig"},
                    {left: "Alltagsreichweite", right: "ca. 200 km"},
                  ] as Array<TableElements> }
                />
              </SideBarAccordion>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
