/**
 * ContainerOverlay Component
 * Overlay that is displayed on hovering the Selection Container for the Landing Page.
 */

import * as React from "react";
import { Link } from "react-router-dom";

// Components
import Icon from "../../Icon/Icon";

// Stylesheets
import "./ContainerOverlay.scss";

// Images
import TourIcon from "../../../assets/images/landing_page/virtuelle tour.svg";


interface ContainerOverlayProps {
  className?: string;
  url: string;
}

export default class ContainerOverlay extends React.Component<ContainerOverlayProps> {

  constructor(props: ContainerOverlayProps) {
    super(props);
  }

  render() {
    return (
      <Link to={this.props.url}>
        <div  className={`containerOverlay ${ this.props.className || "" }`}>
          <p>Virtuelle Tour starten</p>
          <Icon src={TourIcon} width={32} height={30} />
        </div>
      </Link>
    );
  }
}
