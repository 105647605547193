// Stylesheets
import "normalize.css";
import "./App.scss";

import * as React from "react";
import {
  BrowserRouter,
  Routes,
  Route,
} from "react-router-dom";
import { BREAKPOINTS } from "./logic/enums/enums";

// Components
import LandingPage from "~src/routes/LandingPage";
import Chaussee from "~src/routes/Chaussee";
import Carre from "~src/routes/Carre";
import Nihl from "~src/routes/Nihl";

type StateProps = {
  breakpoint: BREAKPOINTS;
}

/*  Creates a Global Context for Breakpoints.
*   <BreakpointContext.Provider></BreakpointContext.Provider> wraps all routes so every route and its components can use the
*   Breakpoint Context within the <BreakpointContext.Consumer></BreakpointContext.Consumer> tag.
*/
export const BreakpointContext = React.createContext("");

export default class App extends React.Component<Record<string, never>, StateProps> {

  constructor(props: Record<string, never>) {
    super(props);

    this.state = {
      breakpoint: BREAKPOINTS.LG
    };
    this.setBreakpoint = this.setBreakpoint.bind(this);
    window.addEventListener("resize", this.setBreakpoint);
  }

  componentDidMount() {
    // Set Breakpoint on initial mount of App.
    this.setBreakpoint();
  }

  private setBreakpoint(): void {
    if (window.innerWidth <= 767) {
      this.setState({breakpoint: BREAKPOINTS.SM});
    }
    if (767 < window.innerWidth && window.innerWidth <= 992) {
      this.setState({breakpoint: BREAKPOINTS.MD});
    }
    if (992 < window.innerWidth) {
      this.setState({breakpoint: BREAKPOINTS.LG});
    }
  }

  render() {
    return (
      <BreakpointContext.Provider value={this.state.breakpoint}>
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<LandingPage />} />
            <Route path="/Carre" element={<Carre breakpoint={this.state.breakpoint}/>} />
            <Route path="/Chaussee" element={<Chaussee breakpoint={this.state.breakpoint}/>} />
            <Route path="/Nihl" element={<Nihl breakpoint={this.state.breakpoint}/>} />
          </Routes>
        </BrowserRouter>
      </BreakpointContext.Provider>
    );
  }
}
