/**
 * StepContainer Component
 * Styled Wrapper for Steps
 * Handles the triggers for switching Steps
 */

import * as React from "react";
import { BreakpointContext } from "~src/App";
import { BREAKPOINTS } from "~src/logic/enums/enums";

// Components
import Icon from "~Components/Icon/Icon";
import Step from "~Components/Step/Step";

// Stylesheets
import "~src/logic/scss/StepContainer.scss";

// Images
import IconRight from "~Assets/images/icons/right.svg";
import StepImageZero from "~Assets/images/steps/chaussee_step_0.png";
import StepImageOne from "~Assets/images/steps/step_1.png";
import StepImageTwo from "~Assets/images/steps/step_2.png";
import StepImageThree from "~Assets/images/steps/step_3.png";
import StepImageFour from "~Assets/images/steps/step_4.png";
import StepImageFourMobile from "~Assets/images/steps/step_4_mobile.png";
import BaseStepContainer, { StepContainerProps } from "~src/contentComponents/common/BaseStepContainer";

export default class StepContainer extends BaseStepContainer {
  constructor(props: StepContainerProps) {
    super(props);
  }

  render() {
    return (
      <div className={`stepContainer ${ this.props.className || "" }`}>
        <div className="skip" onClick={ this.props.closeContainer }>
          Hilfe überspringen
          <Icon
            src={ IconRight }
            width={ 24 }
            height={ 24 }
          />
        </div>
        <div className="step__inner" ref={ this.stepInner }>
          <div className="step__content" style={this.state}>
            <Step
              onNextStep={() => this.setStep(1)}
              imgSrc={StepImageZero}
            >
              <h2>Willkommen in<br />Kusterdingen-Wankheim</h2>
              <p>Im NETZlabor E-Mobility-Chaussee in Kusterdingen bei Tübingen untersuchen wir 18 Monate lang, welche Auswirkungen die E-Mobilität speziell im ländlichen Raum auf das Stromnetz hat.</p>
              <p>Mehr als 60 % der Verteilnetze im Versorgungsgebiet der Netze BW sind ländlich bzw. sehr ländlich geprägt. Um einen möglichst repräsentativen Standort für ein NETZlabor in dieser Region auszuwählen, werden mehrere Schlüsselfaktoren berücksichtigt: Natürlich soll eine Gemeinde im ländlichen Raum ausgewählt werden. Darüber hinaus ist sowohl die Länge des Stromkreises als auch die Anzahl an Hausanschlüssen ausschlaggebend, um bestimmte Auswirkungen bei Spannung und Kapazität feststellen zu können. Die Wahl fällt schließlich auf die Römerstraße in der Gemeinde Kusterdingen im Landkreis Tübingen.</p>

            </Step>
            <Step
              onNextStep={() => this.setStep(2)}
              imgSrc={StepImageOne}
            >
              <h2>Perspektive wechseln</h2>
              <p>Ändern Sie Ihre Ansicht, indem Sie über den Button unten rechts zwischen der Ich- und der Vogelperspektive wechseln.</p>
            </Step>
            <Step
              onNextStep={() => this.setStep(3)}
              imgSrc={StepImageTwo}
            >
              <h2>Points of Interest</h2>
              <p>Auf dem Weg durch das NETZlabor werden Ihnen Zahlen-Fähnchen begegnen – die sogenannten Points Of Interest (kurz POI). Mit einem Klick auf diese, erhalten Sie spannende Details und wichtige Informationen zum NETZlabor.</p>
            </Step>
            <BreakpointContext.Consumer>
              {value =>
                <>
                  {value === BREAKPOINTS.LG && (
                    <Step
                      onNextStep={() => this.setStep(4)}
                      imgSrc={StepImageThree}
                    >
                      <h2>Richtungen</h2>
                      <p>Mit gedrückter linker Maustaste ist es Ihnen möglich, sich rundum im NETZlabor umzusehen.</p>
                    </Step>
                  )}
                  <Step
                    onNextStep={this.props.closeContainer}
                    imgSrc={value === BREAKPOINTS.LG ? StepImageFour : StepImageFourMobile}
                    buttonText = {"Los geht's!"}
                  >
                    <h2>Navigation</h2>
                    <p>Sie befinden sich auf einer geführten Tour durch die NETZlabore bei der Sie vor und zurück navigieren können. Nutzen Sie dafür die Pfeiltasten und springen Sie von POI zu POI.</p>
                  </Step>
                </>
              }
            </BreakpointContext.Consumer>
          </div>
        </div>
        { this.getNavigation() }
      </div>
    );
  }
}
