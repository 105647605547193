/**
 * SideBar Content
 * Content which displays the sample Content for one POI.
 */

import * as React from "react";
import { BreakpointContext } from "~src/App";
import { BREAKPOINTS } from "~src/logic/enums/enums";

// Components
import SideBarHeadline from "~Components/SideBarHeadline/SideBarHeadline";
import SideBarTopLine from "~Components/SideBarTopLine/SideBarTopLine";
import SideBarText from "~Components/SideBarText/SideBarText";
import SideBarPoi, { SideBarPoiProperties } from "~src/contentComponents/common/SideBarPoi";
import SideBarImage from "~Components/SideBarImage/SideBarImage";

// Stylesheets
import "./SideBarPoiFour.scss";

// Images
import HeaderBackground from "~Assets/images/Carre/POI_4/Carre_Heroshot_04.png";
import POIIcon from "~Assets/images/icons/POI_small04.svg";

export default class SideBarPoiFour extends SideBarPoi {
  constructor(props: SideBarPoiProperties) {
    super(props);
  }

  render() {
    return (
      <div className={`sideBarPoiFour ${ this.props.className || "" }`} style={{ backgroundImage: `url(${HeaderBackground})` }}>
        <div className="poiContentWrap">
          <div className="poiContent">
            <div className="poiContent__inner">
              <SideBarTopLine>
                Das NETZlabor E-Mobility-Carré
              </SideBarTopLine>
              <SideBarHeadline
                icon={POIIcon}
                className='smallMargin'
              >
                Die Elektroverteilung
              </SideBarHeadline>
              <BreakpointContext.Consumer>
                {value =>
                  <>
                    {value !== BREAKPOINTS.LG && (
                      <SideBarImage
                        src={HeaderBackground}
                      />
                    )}
                  </>
                }
              </BreakpointContext.Consumer>
              <SideBarText
                color="#000"
              >
                Die Elektroverteilung bildet das Herzstück der Stromversorgung der Anlage. In dieser wird die Energie, welche über den separaten Netzanschluss bezogen wird, an die Ladeinfrastruktur und die Batteriespeicher verteilt. Ebenso sind in der Elektroverteilung Leitungsschutzschalter und weitere Schutztechnik enthalten, um einen sicheren Betrieb der Anlage zu gewährleisten. Die Elektroverteilung erfüllt im Grunde die gleiche Funktion eines Sicherungskastens in einer Wohnung – ist aufgrund der höheren Leistungsklasse jedoch etwas größer.
              </SideBarText>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
