import React from "react";

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface ISideBarPoi{}

export interface SideBarPoiProperties {
  className?: string;
  setPopoutImage?: (evt: React.MouseEvent<HTMLImageElement>, image: string) => void
}

export default class SideBarPoi
  extends React.Component<SideBarPoiProperties>
  implements ISideBarPoi
{
  constructor(props: SideBarPoiProperties) {
    super(props);
  }
}
