import React from "react";

interface IntroProps {
    onToggle?: React.ReactEventHandler;
  }

export default class BaseIntro extends React.Component<IntroProps> {
  constructor(props: IntroProps) {
    super(props);
  }
}
