/**
 * Image Popout Component
 * Wrapper for an overlay image which is triggered on click on sidebar images
*/

import * as React from "react";
import Icon from "../Icon/Icon";

// StyleSheets
import "./ImagePopout.scss";

// Images
import Close from "../../assets/images/icons/close.svg";

type ImagePopoutProps = {
  image?: string;
  closePopout: React.ReactEventHandler;
}

export default class ImagePopout extends React.Component<ImagePopoutProps> {
  render() {
    return (
      <div
        className="imagePopout"
      >
        <div className="imagePopout__image">
          <div className="imagePopout__close" onClick={this.props.closePopout}>
            <Icon
              className="icons"
              src={ Close }
              width={ 44 }
              height={ 44 }
            />
          </div>
          <img src={ this.props.image } />
        </div>
      </div>
    );
  }
}
