/**
 * SideBar Content
 * Component displaying the sample content for the fourth POI.
 */

import * as React from "react";
import { BreakpointContext } from "~src/App";
import { BREAKPOINTS } from "~src/logic/enums/enums";

// Components
import SideBarHeadline from "~Components/SideBarHeadline/SideBarHeadline";
import SideBarTopLine from "~Components/SideBarTopLine/SideBarTopLine";
import SideBarText from "~Components/SideBarText/SideBarText";
import SideBarImage from "~Components/SideBarImage/SideBarImage";
import SideBarPoi, { SideBarPoiProperties } from "~src/contentComponents/common/SideBarPoi";

// Stylesheets
import "./SideBarPoiFive.scss";

// Images
import HeaderBackground from "~Assets/images/NIHL/POI_5/NIHL_Heroshot_04.png";
import POIIcon from "~Assets/images/icons/POI_small05.svg";
import Image from "~Assets/images/NIHL/POI_5/POI_4_01.png";

export default class SideBarPoiFive extends SideBarPoi {
  constructor(props: SideBarPoiProperties) {
    super(props);
  }

  render() {
    return (
      <div className={`sideBarPoiFive ${ this.props.className || "" }`} style={{ backgroundImage: `url(${HeaderBackground})` }}>
        <div className="poiContentWrap">
          <div className="poiContent">
            <div className="poiContent__inner">
              <SideBarTopLine>
                Das NETZlabor Intelligentes Heimladen
              </SideBarTopLine>
              <SideBarHeadline
                icon={POIIcon}
                className='smallMargin'
              >
                Zahlen. Daten. Fakten. Der Alltag mit Elektromobilität und intelligentem Lademanagement
              </SideBarHeadline>
              <BreakpointContext.Consumer>
                {value =>
                  <>
                    {value !== BREAKPOINTS.LG && (
                      <SideBarImage
                        src={HeaderBackground}
                      />
                    )}
                  </>
                }
              </BreakpointContext.Consumer>
              <SideBarText
                color="#000"
              >
                Insgesamt wurden 380.000 km von den 37 teilnehmenden Haushalten elektrisch zurückgelegt. Die knapp 4000 Ladevorgänge, welche hauptsächlich zwischen 18 und 22 Uhr stattfinden, dauern durschnittlich 2,7 Stunden. Dabei werden etwa 18,5 kWh nachgeladen, wobei die E-Pionier*innen ihr E-Fahrzeug typischerweise für Strecken zur Arbeit, für Hobbys, Einkäufe oder (Tages-)Ausflüge und Urlaubsreisen nutzen. Durch Unterschiede im Nutzungsverhalten und den eigenen Gewohnheiten beim Fahren und Laden kommen gleichzeitige Ladevorgänge eher selten vor. In über 90 % der Zeit lädt maximal ein E-Fahrzeug. Doch das Stromnetz muss auch für den Fall einer höheren Gleichzeitigkeit von Ladevorgängen gerüstet sein.
              </SideBarText>
              <SideBarImage
                src={Image}
                setPopoutImage={this.props.setPopoutImage}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}
