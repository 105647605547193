/**
 * SideBar Content
 * Content which displays the sample Content for one POI.
 */

import * as React from "react";
import { BreakpointContext } from "~src/App";
import { BREAKPOINTS } from "~src/logic/enums/enums";

// Components
import SideBarHeadline from "~Components/SideBarHeadline/SideBarHeadline";
import SideBarTopLine from "~Components/SideBarTopLine/SideBarTopLine";
import SideBarText from "~Components/SideBarText/SideBarText";
import SideBarImage from "~Components/SideBarImage/SideBarImage";
import SideBarPoi, { SideBarPoiProperties } from "~src/contentComponents/common/SideBarPoi";

// Stylesheets
import "./SideBarPoiTwo.scss";

// Images
import HeaderBackground from "~Assets/images/Chaussee/poi_2/Chaussee_Heroshot_02.jpg";
import POIIcon from "~Assets/images/icons/POI_small02.svg";
import Substation from "~Assets/images/Chaussee/poi_2/POI_02_01.jpg";

export default class SideBarPoiTwo extends SideBarPoi {
  constructor(props: SideBarPoiProperties) {
    super(props);
  }

  render() {
    return (
      <div className={`sideBarPoiTwo ${ this.props.className || "" }`} style={{ backgroundImage: `url(${HeaderBackground})` }}>
        <div className="poiContentWrap">
          <div className="poiContent">
            <div className="poiContent__inner">
              <SideBarTopLine>
                Das NETZlabor E-Mobility-Chaussee
              </SideBarTopLine>
              <SideBarHeadline
                icon={POIIcon}
                className='smallMargin'
              >
                Die Umspannstation
              </SideBarHeadline>
              <BreakpointContext.Consumer>
                {value =>
                  <>
                    {value !== BREAKPOINTS.LG && (
                      <SideBarImage
                        src={HeaderBackground}
                      />
                    )}
                  </>
                }
              </BreakpointContext.Consumer>
              <SideBarText
                color="#000"
              >
                Von der Umspannstation aus wird die E-Mobility-Chaussee mit Strom versorgt. Der Transformator in der Umspannstation ist dabei die Schnittstelle zwischen dem regionalen und dem lokalen Verteilnetz.
                <br /><br />
                Die an diesem Punkt gemessenen Werte bilden die Grundlage zur Beurteilung der Auslastung des Niederspannungsnetzes und der Auswirkungen von Ladeinfrastruktur.
              </SideBarText>

              <SideBarImage
                src={Substation}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}
