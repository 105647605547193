// Stylesheets
import "./Carre.scss";
import "~src/logic/scss/Animations.scss";

import * as React from "react";

// Components
import Intro from "~CarreContent/Intro/Intro";
import StepContainer from "~CarreContent/StepContainer/StepContainer";

import cityModel from "~Assets/glb/Tamm - Ludwigsburg - Netzlabor E-Mobility Carré.glb";
import cityConfig from "~Assets/configs/Carre.json";
import miniMap from "~Assets/images/minimaps/Carree_mini map.svg";
import miniMapConfig from "~Assets/configs/carre_ludwigsburg_mapconfig.json";

//images
import backgroundOverlay from "~Assets/images/Tamm.jpg";

//Content Components
import POIList from "~CarreContent/PoiList/PoiList";
import SideBarPoiOne from "~CarreContent/SideBarPoiOne/SideBarPoiOne";
import SideBarPoiTwo from "~CarreContent/SideBarPoiTwo/SideBarPoiTwo";
import SideBarPoiThree from "~CarreContent/SideBarPoiThree/SideBarPoiThree";
import SideBarPoiFour from "~CarreContent/SideBarPoiFour/SideBarPoiFour";
import SideBarPoiFive from "~CarreContent/SideBarPoiFive/SideBarPoiFive";
import SideBarPoiSix from "~CarreContent/SideBarPoiSix/SideBarPoiSix";
import SideBarPoiSeven from "~CarreContent/SideBarPoiSeven/SideBarPoiSeven";
import SideBarPoiEight from "~CarreContent/SideBarPoiEight/SideBarPoiEight";
import SideBarPoiNine from "~CarreContent/SideBarPoiNine/SideBarPoiNine";
import SideBarPoiTen from "~CarreContent/SideBarPoiTen/SideBarPoiTen";
import { toVector2 } from "~src/rendering/utility";
import BaseRoute, { Properties } from "~src/routes/BaseRoute";

export default class Carre extends BaseRoute {
  constructor(props: Properties) {
    const renderPoiList = (): JSX.Element =>
      <POIList onPoiSelect={this.goToPoi.bind(this)}/>;

    super(
      props,
      renderPoiList,
      [
        SideBarPoiOne,
        SideBarPoiTwo,
        SideBarPoiThree,
        SideBarPoiFour,
        SideBarPoiFive,
        SideBarPoiSix,
        SideBarPoiSeven,
        SideBarPoiEight,
        SideBarPoiNine,
        SideBarPoiTen,
      ],
      "pageCarre",
      cityModel,
      cityConfig,
      {
        miniMapUrl: miniMap,
        bottomLeft: toVector2(miniMapConfig.bottomLeft),
        bottomRight: toVector2(miniMapConfig.bottomRight),
        topRight: toVector2(miniMapConfig.topRight),
        angle: miniMapConfig.angle,
        scale: miniMapConfig.scale,
      },
      backgroundOverlay,
      Intro,
      StepContainer,
    );
  }
}
