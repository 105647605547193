/**
 * SideBar Content
 * Content which displays the sample Content for one POI.
 */

import * as React from "react";
import { BreakpointContext } from "~src/App";
import { BREAKPOINTS } from "~src/logic/enums/enums";

// Components
import SideBarHeadline from "~Components/SideBarHeadline/SideBarHeadline";
import SideBarTopLine from "~Components/SideBarTopLine/SideBarTopLine";
import SideBarText from "~Components/SideBarText/SideBarText";
import SideBarImage from "~Components/SideBarImage/SideBarImage";
import SideBarPoi, { SideBarPoiProperties } from "~src/contentComponents/common/SideBarPoi";

// Stylesheets
import "./SideBarPoiTen.scss";

// Images
import HeaderBackground from "~Assets/images/Carre/POI_10/Carre_Heroshot_10.png";
import POIIcon from "~Assets/images/icons/POI_small10.svg";
import ImageOne from "~Assets/images/Carre/POI_10/POI_10_01.png";
import ImageTwo from "~Assets/images/Carre/POI_10/POI_10_02.png";

export default class SideBarPoiTen extends SideBarPoi {
  constructor(props: SideBarPoiProperties) {
    super(props);
  }

  render() {
    return (
      <div className={`sideBarPoiTen ${ this.props.className || "" }`} style={{ backgroundImage: `url(${HeaderBackground})` }}>
        <div className="poiContentWrap">
          <div className="poiContent">
            <div className="poiContent__inner">
              <SideBarTopLine>
                Das NETZlabor E-Mobility-Carré
              </SideBarTopLine>
              <SideBarHeadline
                icon={POIIcon}
                className='smallMargin'
              >
                Fazit: Lademanagement als Erfolgsgarant
              </SideBarHeadline>
              <BreakpointContext.Consumer>
                {value =>
                  <>
                    {value !== BREAKPOINTS.LG && (
                      <SideBarImage
                        src={HeaderBackground}
                      />
                    )}
                  </>
                }
              </BreakpointContext.Consumer>
              <SideBarText
                color="#000"
              >
                Aus den Testszenarien des NETZlabors und den daraus abgeleiteten Erkenntnissen lassen sich folgende Punkte zusammenfassen:
                <br /><br />
                Durch das Lademanagement kann die benötigte Anschlussleistung zum Laden der E-Fahrzeuge und somit die Belastung im Stromnetz erheblich reduziert werden (Grafik 1). Der Batteriespeicher eignet sich ebenfalls zur Glättung von Leistungsspitzen, ist jedoch weniger effektiv als ein Lademanagementsystem. Der separate Netzanschluss ist für das Ladeverhalten der E-Pionier*innen im E-Mobility-Carré überdimensioniert. Bei Einsatz eines Lademanagementsystems ist ein separater Netzanschluss in den meisten Fällen überflüssig und wird nur in Ausnahmefällen benötigt. Diese Einschätzung ist natürlich abhängig davon, wie viele Ladepunkte in einer Tiefgarage installiert sind.
                <br /><br />
                Aus einer Umfrage mit den Projektteilnehmer*innen des NETZlabors E-Mobility-Carré lässt sich ableiten, dass sich die Mehrheit der E-Pionier*innen in ihrem Nutzungsverhalten durch Eingriffe des Lademanagements nicht eingeschränkt fühlt (Grafik 2).
              </SideBarText>
              <SideBarImage
                src={ImageOne}
                setPopoutImage={this.props.setPopoutImage}
              />
              <SideBarImage
                src={ImageTwo}
                setPopoutImage={this.props.setPopoutImage}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}
