/**
 * PoiList Content
 * Component showing the POI List in the sidebar.
 * Will be shown when the Menu Icon in the header is clicked.
 */

import * as React from "react";

// Components
import Icon from "~Components/Icon/Icon";
import SideBarHeadline from "~Components/SideBarHeadline/SideBarHeadline";
import SideBarTopLine from "~Components/SideBarTopLine/SideBarTopLine";

// Stylesheets
import "./PoiList.scss";

// Images
import POILarge from "~Assets/images/icons/POI large.svg";
import IconRight from "~Assets/images/icons/right_orange.svg";


interface PoiListProps {
  className?: string;
  onPoiSelect(index: number): void;
}

export default class PoiList extends React.Component<PoiListProps> {
  constructor(props: PoiListProps) {
    super(props);
  }

  render() {
    return (
      <div className={`poiList ${ this.props.className || "" }`}>
        <div className="poiList__header">
          <SideBarTopLine>
            Interessante Punkte im Überblick
          </SideBarTopLine>
          <SideBarHeadline
            icon={POILarge}
          >
            Entdecken Sie das NETZlabor<br />Intelligentes Heimladen
          </SideBarHeadline>
        </div>
        <div className="poiList__content">
          <div className="poiList__entry" onClick={() => this.props.onPoiSelect(0)}>
            <div className="poiList__number">1</div>
            <div className="poiList__name">Das Lademanagement</div>
            <Icon
              className="poiList__icon"
              src={IconRight}
              width={24}
              height={24}
            />
          </div>
          <div className="poiList__entry" onClick={() => this.props.onPoiSelect(1)}>
            <div className="poiList__number">2</div>
            <div className="poiList__name">Die Umspannstation</div>
            <Icon
              className="poiList__icon"
              src={IconRight}
              width={24}
              height={24}
            />
          </div>
          <div className="poiList__entry" onClick={() => this.props.onPoiSelect(2)}>
            <div className="poiList__number">3</div>
            <div className="poiList__name">Die Nutzung der Flexibilität von Ladevorgängen</div>
            <Icon
              className="poiList__icon"
              src={IconRight}
              width={24}
              height={24}
            />
          </div>
          <div className="poiList__entry" onClick={() => this.props.onPoiSelect(3)}>
            <div className="poiList__number">4</div>
            <div className="poiList__name">Vom Testszenario zum Standard</div>
            <Icon
              className="poiList__icon"
              src={IconRight}
              width={24}
              height={24}
            />
          </div>
          <div className="poiList__entry" onClick={() => this.props.onPoiSelect(4)}>
            <div className="poiList__number">5</div>
            <div className="poiList__name">Der Alltag mit Elektromobilität</div>
            <Icon
              className="poiList__icon"
              src={IconRight}
              width={24}
              height={24}
            />
          </div>
          <div className="poiList__entry" onClick={() => this.props.onPoiSelect(5)}>
            <div className="poiList__number">6</div>
            <div className="poiList__name">Fazit</div>
            <Icon
              className="poiList__icon"
              src={IconRight}
              width={24}
              height={24}
            />
          </div>
        </div>
      </div>
    );
  }
}
