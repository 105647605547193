/**
 * SideBar Content
 * Content which displays the sample Content for one POI.
 */

import * as React from "react";
import { BreakpointContext } from "~src/App";
import { BREAKPOINTS } from "~src/logic/enums/enums";

// Components
import SideBarHeadline from "~Components/SideBarHeadline/SideBarHeadline";
import SideBarTopLine from "~Components/SideBarTopLine/SideBarTopLine";
import SideBarText from "~Components/SideBarText/SideBarText";
import SideBarImage from "~Components/SideBarImage/SideBarImage";
import SideBarList, {ListElements} from "~Components/SideBarList/SideBarList";
import SideBarPoi, { SideBarPoiProperties } from "~src/contentComponents/common/SideBarPoi";

// Stylesheets
import "./SideBarPoiFive.scss";

// Images
import HeaderBackground from "~Assets/images/Chaussee/poi_5/Chaussee_Heroshot_05.jpg";
import POIIcon from "~Assets/images/icons/POI_small05.svg";
import Batteriespeicher from "~Assets/images/Chaussee/poi_5/POI_05_01.gif";
import Leistungsdiagramm from "~Assets/images/Chaussee/poi_5/POI_05_02.png";

export default class SideBarPoiFive extends SideBarPoi {
  constructor(props: SideBarPoiProperties) {
    super(props);
  }

  render() {
    return (
      <div className={`sideBarPoiFive ${ this.props.className || "" }`} style={{ backgroundImage: `url(${HeaderBackground})` }}>
        <div className="poiContentWrap">
          <div className="poiContent">
            <div className="poiContent__inner">
              <SideBarTopLine>
                Das NETZlabor E-Mobility-Chaussee
              </SideBarTopLine>
              <SideBarHeadline
                icon={POIIcon}
                className='smallMargin'
              >
                Wie autark lebt es sich in der E-Mobility-Chaussee?
              </SideBarHeadline>
              <BreakpointContext.Consumer>
                {value =>
                  <>
                    {value !== BREAKPOINTS.LG && (
                      <SideBarImage
                        src={HeaderBackground}
                      />
                    )}
                  </>
                }
              </BreakpointContext.Consumer>
              <SideBarText
                color="#000"
              >
                Während des gesamten Testzeitraums wurde ein Haushalt, welcher bereits über eine eigene Photovoltaikanlage mit 4,6 kWp verfügte, zusätzlich zum E-Fahrzeug mit einem dezentralen Batteriespeicher ausgestattet. Diese Speichereinheit besitzt eine Kapazität von 19 kWh und wird mit dem Ziel eingesetzt, die Belastungen am Hausanschluss zu verringern und den Eigenverbrauch des von der Photovoltaikanlage erzeugten Stroms zu erhöhen. So soll es auch in den Abendstunden noch möglich sein, das E-Fahrzeug mit PV-Strom zu beladen. Während der Sommermonate und länger anhaltenden “Schönwetter-Perioden” kann ein Autarkiegrad von bis zu 80 % erreicht werden. Dieser fällt jedoch in den Wintermonaten aufgrund der geringeren Sonnenstunden und des allgemein oft schlechteren Wetters auf ein so geringes Niveau, dass immer von einer Nutzung des öffentlichen Stromnetzes für die Versorgung des Hausanschlusses auszugehen ist.
              </SideBarText>
              <SideBarHeadline
              >
                Dezentraler<br />Batteriespeicher
              </SideBarHeadline>
              <SideBarImage
                src={Batteriespeicher}
                setPopoutImage={this.props.setPopoutImage}
              />
              <SideBarList
                listElements={
                  [
                    {text: "Die E-Fahrzeuge werden meist erst in den Abendstunden geladen, während die PV-Anlage tagsüber Strom erzeugt"},
                    {text: "Der Batteriespeicher dient zur Überbrückung, indem er den tagsüber erzeugten Strom der PV-Anlage zur Beladung des E-Fahrzeugs in den Abendstunden nutzbar macht"},
                    {text: "Durch die Optimierung des Eigenverbrauchs ist es möglich, die Gesamtbezugsleistung aus dem öffentlichen Stromnetz effektiv auf <5 kW zu begrenzen"},
                    {text: "Belastungsspitzen im Stromnetz, welche durch E-Fahrzeuge hervorgerufen werden, können in diesen verbrauchsoptimierten Haushalten begrenzt werden"}
                  ] as Array<ListElements>
                }

              />
              <SideBarImage
                src={Leistungsdiagramm}
                setPopoutImage={this.props.setPopoutImage}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}
