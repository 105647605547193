/**
 * SideBar Content
 * Component displaying the sample content for the second POI.
 */

import * as React from "react";
import { BreakpointContext } from "~src/App";
import { BREAKPOINTS } from "~src/logic/enums/enums";

// Components
import SideBarHeadline from "~Components/SideBarHeadline/SideBarHeadline";
import SideBarTopLine from "~Components/SideBarTopLine/SideBarTopLine";
import SideBarText from "~Components/SideBarText/SideBarText";
import SideBarTabs from "~Components/SideBarTabs/SideBarTabs";
import SideBarPoi, { SideBarPoiProperties } from "~src/contentComponents/common/SideBarPoi";
import SideBarImage from "~Components/SideBarImage/SideBarImage";

// Stylesheets
import "./SideBarPoiThree.scss";

// Images
import HeaderBackground from "~Assets/images/NIHL/POI_3/NIHL_Heroshot_02.png";
import POIIcon from "~Assets/images/icons/POI_small03.svg";
import WithChargeManagement from "~Assets/images/NIHL/POI_3/POI_2_01.png";
import WithoutChargeManagement from "~Assets/images/NIHL/POI_3/POI_2_02.png";

export default class SideBarPoiThree extends SideBarPoi {
  constructor(props: SideBarPoiProperties) {
    super(props);
  }

  render() {
    return (
      <div className={`sideBarPoiThree ${ this.props.className || "" }`} style={{ backgroundImage: `url(${HeaderBackground})` }}>
        <div className="poiContentWrap">
          <div className="poiContent">
            <div className="poiContent__inner">
              <SideBarTopLine>
                Das NETZlabor Intelligentes Heimladen
              </SideBarTopLine>
              <SideBarHeadline
                icon={POIIcon}
                className='smallMargin'
              >
                Die gezielte Nutzung der Flexibilität von Ladevorgängen für intelligentes Lademanagement
              </SideBarHeadline>
              <BreakpointContext.Consumer>
                {value =>
                  <>
                    {value !== BREAKPOINTS.LG && (
                      <SideBarImage
                        src={HeaderBackground}
                      />
                    )}
                  </>
                }
              </BreakpointContext.Consumer>
              <SideBarText
                color="#000"
              >
                Die meisten Ladevorgänge finden abends zwischen 18 und 22 Uhr statt. Parallel dazu ist der Leistungsbezug im Stromnetz, verursacht durch übliche Haushaltsverbraucher wie beispielsweise Backofen oder Herd, in diesem Zeitraum besonders hoch. Um kritische Belastungen zu diesen “Spitzenzeiten” zu vermeiden kann ein netzdienliches Lademanagement eingesetzt werden. Hierbei wird während eines “kritischen” Zeitraums gezielt die Ladeleistung des E-Autos reduziert wodurch sich der Ladevorgang folglich verlängert. Ist die Netzbelastung zu einer späteren Uhrzeit wieder geringer,  kann das E-Auto erneut mit voller Leistung geladen werden.
                <br /><br />
                Während der Testphasen konnten so knapp 61 % der nachgeladenen Energie aus dem Zeitraum sehr hoher Netzauslastung in Zeiten mit niedrigerer Netzauslastung verschoben werden. Dies wird u.a. durch die lange Standzeit der E-Fahrzeuge über Nacht ermöglicht, die trotz der leicht verzögerten Ladevorgänge dennoch bis zum nächsten Morgen immer vollgeladen werden können.
                <br /><br />
                Mittels Einsatz von Lademanagement kann die Aufnahmefähigkeit von Elektrofahrzeugen ins Stromnetz deutlich erhöht werden.

              </SideBarText>
              <SideBarTabs
                src1={WithChargeManagement}
                src2={WithoutChargeManagement}
                leftText="Ohne Lademanagement"
                rightText="Mit Lademanagement"
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}
