/**
 * SideBar Content
 * Content which displays the sample Content for one POI.
 */

import * as React from "react";
import { BreakpointContext } from "~src/App";
import { BREAKPOINTS } from "~src/logic/enums/enums";

// Components
import SideBarHeadline from "~Components/SideBarHeadline/SideBarHeadline";
import SideBarTopLine from "~Components/SideBarTopLine/SideBarTopLine";
import SideBarText from "~Components/SideBarText/SideBarText";
import SideBarImage from "~Components/SideBarImage/SideBarImage";
import SideBarPoi, { SideBarPoiProperties } from "~src/contentComponents/common/SideBarPoi";

// Stylesheets
import "./SideBarPoiFour.scss";

// Images
import HeaderBackground from "~Assets/images/Chaussee/poi_4/Chaussee_Heroshot_04.jpg";
import POIIcon from "~Assets/images/icons/POI_small04.svg";
import Image from "~Assets/images/Chaussee/poi_4/POI_04_01.png";

export default class SideBarPoiFour extends SideBarPoi {
  constructor(props: SideBarPoiProperties) {
    super(props);
  }

  render() {
    return (
      <div className={`sideBarPoiFour ${ this.props.className || "" }`} style={{ backgroundImage: `url(${HeaderBackground})` }}>
        <div className="poiContentWrap">
          <div className="poiContent">
            <div className="poiContent__inner">
              <SideBarTopLine>
                Das NETZlabor E-Mobility-Chaussee
              </SideBarTopLine>
              <SideBarHeadline
                icon={POIIcon}
                className='smallMargin'
              >
                Lösungsansatz Strangregler: <br />Schaltstelle fürs Spannungsniveau
              </SideBarHeadline>
              <BreakpointContext.Consumer>
                {value =>
                  <>
                    {value !== BREAKPOINTS.LG && (
                      <SideBarImage
                        src={HeaderBackground}
                      />
                    )}
                  </>
                }
              </BreakpointContext.Consumer>
              <SideBarText
                color="#000"
              >
                Der Strangregler ist ein technisches Betriebsmittel, welches auf das Spannungsniveau in einem einzelnen Stromkreis reagiert und dieses bei Bedarf anhebt oder absenkt. Dieser Lösungsansatz kann eine kostengünstige Alternative zu einem kompletten Netzausbau sein. Insbesondere in ländlichen Netzen, in denen die Spannung häufiger ein Problem darstellt als die eigentliche Auslastung des Stromkabels. Er ist daher vor allem für abgelegene Stromnetze oder Stichleitungen ohne weitere Umschaltmöglichkeiten geeignet, um die Kapazitäten des Stromnetzes bei auftretenden Spannungsschwankungen besser auszunutzen.
                <br /><br />
                Der Strangregler ist im Grunde eine technische Erweiterung eines Kabelverteilerschranks. Durch eine zusätzliche Messung des Spannungsniveaus reagiert der Strangregler auf auftretende Schwankungen. Falls die Spannung zu niedrig oder zu hoch ist, erfolgt eine Anhebung oder Senkung des Spannungsniveaus im weiteren Kabelverlauf.
                <br /><br />
                Im Diagramm ist zu sehen, dass der Strangregler während des gesamten Betriebszeitraums (blaue Fläche) das Spannungsniveau zuverlässig anhebt.
              </SideBarText>
              <SideBarImage
                src={Image}
                setPopoutImage={this.props.setPopoutImage}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}
