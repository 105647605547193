/**
 * PoiList Content
 * Content which displays the POI List which is used in the Sidebar.
 * Content will be shown when clicked on the Menu Icon in the Header.
 */

import * as React from "react";

// Components
import Icon from "~Components/Icon/Icon";
import SideBarHeadline from "~Components/SideBarHeadline/SideBarHeadline";
import SideBarTopLine from "~Components/SideBarTopLine/SideBarTopLine";

// Stylesheets
import "./PoiList.scss";

// Images
import POILarge from "~Assets/images/icons/POI large.svg";
import IconRight from "~Assets/images/icons/right_orange.svg";




interface PoiListProps {
  className?: string;
  onPoiSelect(index: number): void;
}

export default class PoiList extends React.Component<PoiListProps> {
  constructor(props: PoiListProps) {
    super(props);
  }

  render() {
    return (
      <div className={`poiList ${ this.props.className || "" }`}>
        <div className="poiList__header">
          <SideBarTopLine>
            Interessante Punkte im Überblick
          </SideBarTopLine>
          <SideBarHeadline
            icon={POILarge}
          >
            Entdecken Sie das NETZlabor <br />E-Mobility-Carré
          </SideBarHeadline>
        </div>
        <div className="poiList__content">
          <div className="poiList__entry" onClick={() => this.props.onPoiSelect(0)}>
            <div className="poiList__number">1</div>
            <div className="poiList__name">16 Monate unter Strom</div>
            <Icon
              className="poiList__icon"
              src={IconRight}
              width={24}
              height={24}
            />
          </div>
          <div className="poiList__entry" onClick={() => this.props.onPoiSelect(1)}>
            <div className="poiList__number">2</div>
            <div className="poiList__name">Dynamik in der Tiefgarage</div>
            <Icon
              className="poiList__icon"
              src={IconRight}
              width={24}
              height={24}
            />
          </div>
          <div className="poiList__entry" onClick={() => this.props.onPoiSelect(2)}>
            <div className="poiList__number">3</div>
            <div className="poiList__name">Das freie Laden</div>
            <Icon
              className="poiList__icon"
              src={IconRight}
              width={24}
              height={24}
            />
          </div>
          <div className="poiList__entry" onClick={() => this.props.onPoiSelect(3)}>
            <div className="poiList__number">4</div>
            <div className="poiList__name">Die Elektroverteilung</div>
            <Icon
              className="poiList__icon"
              src={IconRight}
              width={24}
              height={24}
            />
          </div>
          <div className="poiList__entry" onClick={() => this.props.onPoiSelect(4)}>
            <div className="poiList__number">5</div>
            <div className="poiList__name">Ladeinfrastruktur mit intelligentem Lademanagement</div>
            <Icon
              className="poiList__icon"
              src={IconRight}
              width={24}
              height={24}
            />
          </div>
          <div className="poiList__entry" onClick={() => this.props.onPoiSelect(5)}>
            <div className="poiList__number">6</div>
            <div className="poiList__name">Der Batteriespeicher</div>
            <Icon
              className="poiList__icon"
              src={IconRight}
              width={24}
              height={24}
            />
          </div>
          <div className="poiList__entry" onClick={() => this.props.onPoiSelect(6)}>
            <div className="poiList__number">7</div>
            <div className="poiList__name">Der Messwandlerschrank</div>
            <Icon
              className="poiList__icon"
              src={IconRight}
              width={24}
              height={24}
            />
          </div>
          <div className="poiList__entry" onClick={() => this.props.onPoiSelect(7)}>
            <div className="poiList__number">8</div>
            <div className="poiList__name">Der separate Netzanschluss</div>
            <Icon
              className="poiList__icon"
              src={IconRight}
              width={24}
              height={24}
            />
          </div>
          <div className="poiList__entry" onClick={() => this.props.onPoiSelect(8)}>
            <div className="poiList__number">9</div>
            <div className="poiList__name">Die Umspannstation</div>
            <Icon
              className="poiList__icon"
              src={IconRight}
              width={24}
              height={24}
            />
          </div>
          <div className="poiList__entry" onClick={() => this.props.onPoiSelect(9)}>
            <div className="poiList__number">10</div>
            <div className="poiList__name">Fazit</div>
            <Icon
              className="poiList__icon"
              src={IconRight}
              width={24}
              height={24}
            />
          </div>
        </div>
      </div>
    );
  }
}
