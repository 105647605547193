/**
 * BackgroundOverlay Component
 * Wrapper with Background Image to hide Canvas in the Beginning
*/

import * as React from "react";

import "./BackgroundOverlay.scss";

type BackgroundOverlayProps = {
  backgroundImage?: string;
  children: React.ReactNode;
}

export default class BackgroundOverlay extends React.Component<BackgroundOverlayProps> {

  componentDidMount(): void {
    document.body.classList.add("overlay_open");
  }

  componentWillUnmount(): void {
    document.body.classList.remove("overlay_open");
  }

  render() {
    return (
      <div
        className="backgroundOverlay"
        style={{ backgroundImage: `url(${this.props.backgroundImage})` }}
      >
        { this.props.children }
      </div>
    );
  }
}
