/**
 * SideBar Content
 * Content which displays the sample Content for one POI.
 */

import * as React from "react";
import { BreakpointContext } from "~src/App";
import { BREAKPOINTS } from "~src/logic/enums/enums";

// Components
import SideBarHeadline from "~Components/SideBarHeadline/SideBarHeadline";
import SideBarTopLine from "~Components/SideBarTopLine/SideBarTopLine";
import SideBarText from "~Components/SideBarText/SideBarText";
import SideBarImage from "~Components/SideBarImage/SideBarImage";
import SideBarAccordion from "~Components/SideBarAccordion/SideBarAccordion";
import SideBarList, {ListElements} from "~Components/SideBarList/SideBarList";
import SideBarPoi, { SideBarPoiProperties } from "~src/contentComponents/common/SideBarPoi";

// Stylesheets
import "./SideBarPoiOne.scss";
import "~src/logic/scss/Animations.scss";

// Images
import HeaderBackground from "~Assets/images/Chaussee/poi_1/Chaussee_Heroshot_01.jpg";
import POIIcon from "~Assets/images/icons/POI_small01.svg";
import ImageOne from "~Assets/images/Chaussee/poi_1/POI_1_01.png";
import Nissan from "~Assets/images/Chaussee/poi_1/POI_1_02.jpg";
import Renault from "~Assets/images/Chaussee/poi_1/POI_1_03.jpg";

export default class SideBarPoiOne extends SideBarPoi {
  constructor(props: SideBarPoiProperties) {
    super(props);
  }

  render() {
    return (
      <div className={`sideBarPoiOne ${ this.props.className || "" }`} style={{ backgroundImage: `url(${HeaderBackground})` }}>
        <div className="poiContentWrap">
          <div className="poiContent">
            <div className="poiContent__inner">
              <SideBarTopLine>
                Das NETZlabor E-Mobility-Chaussee
              </SideBarTopLine>
              <SideBarHeadline
                icon={POIIcon}
                className='smallMargin'
              >
                Spannung auf dem Land
              </SideBarHeadline>
              <BreakpointContext.Consumer>
                {value =>
                  <>
                    {value !== BREAKPOINTS.LG && (
                      <SideBarImage
                        src={HeaderBackground}
                      />
                    )}
                  </>
                }
              </BreakpointContext.Consumer>
              <SideBarText
                color="#000"
              >
                Natürlich gewachsene Ortsgrenzen, längere Distanzen zwischen den Anschlüssen und eine höhere Gesamtzahl der Anschlusskund*innen, die über einen Stromkreis versorgt werden: die Strukturen des Verteilnetzes im ländlichen Raum unterscheiden sich wesentlich von denen städtischer oder vorstädtischer Regionen. Die genannten Faktoren erhöhen vor allem das Risiko von größeren Spannungsschwankungen, welches durch hohe Leistungen, wie sie ladende E-Autos benötigen, nun noch verstärkt wird. Das macht den Ausbau und Betrieb ländlicher Verteilnetze verglichen mit städtischen Netzen zu einem komplexeren und aufwändigeren Unterfangen.
                <br /><br />
                Mit ihrem typisch ländlichen Niederspannungsnetz mit 850 Meter langem Stromkreis ist die Römerstraße in Kusterdingen der perfekte Ort für den Feldtest der Netze BW.
                <br /><br />
                Gemeinsam mit den Projektteilnehmer*innen – den E-Pionier*innen – wird in insgesamt 16 Testphasen untersucht, wie sich das Laden der Elektrofahrzeuge auf das lokale Stromnetz auswirkt und welche technischen Lösungsansätze zur Netzoptimierung zur Verfügung stehen. Denn die Netzintegration der Elektromobilität kann nur gelingen, wenn beide Komponenten – Mensch und Technik – miteinander in Einklang gebracht werden. So müssen Lösungsansätze sowohl netzdienlich als auch gut integrierbar in den Alltag mit Elektromobiltät sein.
                <br /><br />
                Für die Dauer des Projekts erhalten die E-Pionier*innen je ein E-Fahrzeug sowie die zugehörige Ladestation (Wallbox).
              </SideBarText>
              <SideBarImage
                src={ImageOne}
                setPopoutImage={this.props.setPopoutImage}
              />
              <SideBarAccordion
                headline="Nissan Leaf"
                src={Nissan}
              >
                <SideBarList
                  listIcon={"hook"}
                  listElements={[
                    {text: "Batteriekapazität: 40 kWh"},
                    {text: "Ladezeit 0 -100 %: 8,5 h (AC)"},
                    {text: "Ladeleistung: 4,6 kW (AC)"},
                    {text: "Ladeverhalten: einphasig"},
                    {text: "Alltagsreichweite: ca. 220 km"}
                  ] as Array<ListElements> }
                />
              </SideBarAccordion>
              <SideBarAccordion
                headline="Renault ZOE"
                src={Renault}
              >
                <SideBarList
                  listIcon={"hook"}
                  listElements={[
                    {text: "Batteriekapazität: 41 kWh"},
                    {text: "Ladezeit 0 -100 %: 2,15 h (AC)"},
                    {text: "Ladeleistung: 22 kW (AC)"},
                    {text: "Ladeverhalten: dreiphasig"},
                    {text: "Alltagsreichweite: ca. 250 km"}
                  ] as Array<ListElements> }
                />
              </SideBarAccordion>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
