/**
 * SideBar Content
 * Component displaying the sample content for the fifth POI.
 */

import * as React from "react";
import { BreakpointContext } from "~src/App";
import { BREAKPOINTS } from "~src/logic/enums/enums";

// Components
import SideBarHeadline from "~Components/SideBarHeadline/SideBarHeadline";
import SideBarTopLine from "~Components/SideBarTopLine/SideBarTopLine";
import SideBarText from "~Components/SideBarText/SideBarText";
import SideBarImage from "~Components/SideBarImage/SideBarImage";
import SideBarPoi, { SideBarPoiProperties } from "~src/contentComponents/common/SideBarPoi";

// Stylesheets
import "./SideBarPoiTwo.scss";

// Images
import HeaderBackground from "~Assets/images/NIHL/POI_2/NIHL_Heroshot_05.png";
import POIIcon from "~Assets/images/icons/POI_small02.svg";
import ImageOne from "~Assets/images/NIHL/POI_2/POI_5_01.png";

export default class SideBarPoiTwo extends SideBarPoi {
  constructor(props: SideBarPoiProperties) {
    super(props);
  }

  render() {
    return (
      <div className={`sideBarPoiTwo ${ this.props.className || "" }`} style={{ backgroundImage: `url(${HeaderBackground})` }}>
        <div className="poiContentWrap">
          <div className="poiContent">
            <div className="poiContent__inner">
              <SideBarTopLine>
                Das NETZlabor Intelligentes Heimladen
              </SideBarTopLine>
              <SideBarHeadline
                icon={POIIcon}
                className='smallMargin'
              >
                Die Umspannstation
              </SideBarHeadline>
              <BreakpointContext.Consumer>
                {value =>
                  <>
                    {value !== BREAKPOINTS.LG && (
                      <SideBarImage
                        src={HeaderBackground}
                      />
                    )}
                  </>
                }
              </BreakpointContext.Consumer>
              <SideBarText
                color="#000"
              >
                Von der Umspannstation aus wird das NETZlabor Intelligentes Heimladen mit Strom versorgt. Der Transformator in der Umspannstation ist dabei die Schnittstelle zwischen dem „regionalen“ und dem „lokalen“ Verteilnetz. Insbesondere wenn viele E-Fahrzeuge gleichzeitig laden, können Lastspitzen entstehen und das Stromnetz ist daduch stärker ausgelastet. Eine netzdienliche Steuerung von Ladevorgängen bietet eine wirkungsvolle Lösung um bestehende Kapazitäten besser auszunutzen und mehr E-Fahrzeuge in das bestehende Stromnetz zu integrieren.
              </SideBarText>
              <SideBarImage
                src={ImageOne}
                setPopoutImage={this.props.setPopoutImage}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}
