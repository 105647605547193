/**
 * Sidebar Table Component
 * Table with Left and Right Text.
 * Left text is highlighted.
 */

import * as React from "react";

// Stylesheets
import "./SideBarTable.scss";

interface SideBarTableProps {
  className?: string;
  tableElements: Array<TableElements>;
}

export interface TableElements {
  left: string;
  right: string;
}

export default class SideBarTable extends React.Component<SideBarTableProps> {
  render() {
    return (
      <div
        className={`sideBarTable ${ this.props.className || "" }`}
      >
        <div className="table">
          {this.props.tableElements.map((element, index) => (
            <div key={index} className="row">
              <div className="cell left">{element.left}</div>
              <div className="cell right">{element.right}</div>
            </div>
          ))}
        </div>
      </div>
    );
  }
}
