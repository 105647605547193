/**
 * Sidebar Top Line Component
 * Simple component to add a top line to sidebar with styling.
 */

import * as React from "react";

// Stylesheets
import "./SideBarInfoBox.scss";

// Images
import InfoBoxIcon from "../../assets/images/icons/info_icon_box.svg";

interface SideBarInfoBoxProps {
  className?: string;
  children: React.ReactNode;
}

export default class SideBarInfoBox extends React.Component<SideBarInfoBoxProps> {

  render() {
    return (
      <div
        className={`sidebarInfoBox ${ this.props.className || "" }`}
      >
        <img src={InfoBoxIcon} />
        <p>{this.props.children}</p>
      </div>
    );
  }
}
