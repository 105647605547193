/**
 * Toggle Button Component
 * Button with two states (true and false) which can be toggled.
 * Each toggle state fires its own event.
 */

import * as React from "react";

import "./ToggleButton.scss";

 type ToggleButtonProps = {
   className?: string;
   iconOnActive: string;
   iconOnInactive: string;
   iconOffActive: string;
   iconOffInactive: string;
   activeState?: boolean;
   onOnState: React.ReactEventHandler;
   onOffState: React.ReactEventHandler;
 }

export default class ToggleButton extends React.Component<ToggleButtonProps> {

  constructor(props: ToggleButtonProps) {
    super(props);
  }

  clickHandler(event: React.SyntheticEvent, toggleState: boolean): void {
    if(toggleState !== this.props.activeState) {
      if(toggleState) this.props.onOnState(event);
      else this.props.onOffState(event);
      this.setState({toggleState: toggleState});
    }
  }

  render() {
    // Ignore the toggleState if an activeState is given, it serves as an override.
    return (
      <div
        className={`toggleButton ${ this.props.className || "" }`}
      >
        <div className="onButton" onClick={
          (event: React.MouseEvent) => this.clickHandler(event, true)
        }>
          {
            this.props.activeState ?
              <img src={this.props.iconOnActive} />
              :
              <img src={this.props.iconOnInactive} />
          }
        </div>
        <div className="offButton" onClick={
          (event: React.MouseEvent) => this.clickHandler(event, false)
        }>
          {
            this.props.activeState ?
              <img src={this.props.iconOffInactive} />
              :
              <img src={this.props.iconOffActive} />
          }
        </div>
      </div>
    );
  }
}
